import { useState } from "react";
import { useParams } from "react-router-dom";
import TypePageView from "./TypePageView";
import useCustomEffect from "../../../hooks/useCustomEffect";
import { useRouteContext } from "../../../context/RouteContext";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";
import useEntityTranslations from "../../../hooks/useEntityTranslations";

function TypePage() {
  const callEvaApi = useEvaApi();
  const routeContext = useRouteContext();
  const { type } = useParams();
  const [submitError, setSubmitError] = useState(null);
  const [typeData, setTypeData] = useState({
    name: "",
    order: 0,
    translations: [],
  });

  const translationsController = useEntityTranslations(
    typeData.translations,
    onTranslationsChange
  );

  /**
   * initialize the component
   */
  useCustomEffect(() => {
    if (type) {
      fetchTypeData();
    }
  });

  /**
   * This method will fetch the calamity type data from the API
   */
  async function fetchTypeData() {
    await callEvaApi(
      new EvaApiCall(`calamity_types/${type}`)
        .setLoadingGroup("calamities_type_page_fetch")
        .setOnSuccess((response) => {
          setTypeData({
            name: response.data.data.name,
            order: response.data.data.order,
            translations: response.data.data.translations.map(
              (translation) => ({
                language_id: translation.language,
                label: translation.label,
              })
            ),
          });
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will update the name on input change
   * @param {event} e
   */
  function onNameChange(e) {
    setTypeData({ ...typeData, name: e.target.value });
  }

  /**
   * This method will update the order on input change
   * @param {event} e
   */
  function onOrderChange(e) {
    setTypeData({ ...typeData, order: e.target.value });
  }

  /**
   * This method will handle the translations change
   * @param {array} value
   */
  function onTranslationsChange(value) {
    setTypeData((current) => ({
      ...current,
      translations: value,
    }));
  }

  /**
   * This method will handle the onCancel functionality
   */
  function onCancel() {
    routeContext.back();
  }

  /**
   * This method will submit the form to the EVA API and handles its response
   */
  async function onSubmit() {
    await callEvaApi(
      new EvaApiCall(type ? `calamity_types/${type}` : "calamity_types")
        .setLoadingGroup("calamities_type_page_submit")
        .setMethod(type ? "PUT" : "POST")
        .setData(typeData)
        .setErrorState(setSubmitError)
        .setRedirectOnSuccess(true)
    );
  }

  return (
    <TypePageView
      typeData={typeData}
      translationsController={translationsController}
      onNameChange={onNameChange}
      onOrderChange={onOrderChange}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitError={submitError}
    />
  );
}

export default TypePage;
