import SelectedFormView from "./SelectedFormView";

function SelectedForm({
  relations,
  flowData,
  setFlowData,
  submitError,
  setSubmitError,
}) {
  const selectedStepIndex = flowData.steps.findIndex((step) => step.active);
  const selectedStep = flowData.steps[selectedStepIndex];
  const selectedConnectionIndex = flowData.connections.findIndex(
    (c) => c.active
  );
  const selectedConnection = flowData.connections[selectedConnectionIndex];

  /**
   * This method will handle the force show change event
   * @param {boolean} value
   */
  function onForceShowChange(value) {
    setFlowData((prev) => ({
      ...prev,
      steps: prev.steps.map((step, index) => {
        if (index === selectedStepIndex) {
          return {
            ...step,
            force_show: value,
          };
        }
        return step;
      }),
    }));
  }

  /**
   * This method will handle the on close event
   */
  function onClose() {
    if (selectedStep) {
      setFlowData((prev) => ({
        ...prev,
        steps: prev.steps.map((step) => ({
          ...step,
          active: false,
        })),
      }));
    }

    if (selectedConnection) {
      setFlowData((prev) => ({
        ...prev,
        connections: prev.connections.map((connection) => ({
          ...connection,
          active: false,
        })),
      }));
    }
  }

  /**
   * This method will handle the on delete event
   */
  function onDelete() {
    setSubmitError(null);

    if (selectedStep) {
      setFlowData((prev) => ({
        ...prev,
        steps: prev.steps.filter((step) => step.id !== selectedStep.id),
        connections: prev.connections.filter(
          (c) =>
            c.from_step_id !== selectedStep.id &&
            c.to_step_id !== selectedStep.id
        ),
      }));
    }

    if (selectedConnection) {
      setFlowData((prev) => ({
        ...prev,
        connections: prev.connections.filter(
          (c) => c.id !== selectedConnection.id
        ),
      }));
    }
  }

  return (
    <SelectedFormView
      relations={relations}
      selectedStep={selectedStep}
      selectedStepIndex={selectedStepIndex}
      selectedConnection={selectedConnection}
      selectedConnectionIndex={selectedConnectionIndex}
      setFlowData={setFlowData}
      onClose={onClose}
      onDelete={onDelete}
      onForceShowChange={onForceShowChange}
      submitError={submitError}
      setSubmitError={setSubmitError}
    />
  );
}

export default SelectedForm;
