class DataTableConfig {
  constructor(key) {
    this.key = key;
    this.header = null;
    this.headerHtml = null;

    this.limits = [10, 25, 50, 100];
    this.limit = 10;

    this.orderBy = null;
    this.orderDirection = "desc";

    this.fetchRequest = "";
    this.fetchHeaders = null;
    this.fetchParams = null;

    this.columns = [];
    this.rowActions = [];
    this.headerActions = [];

    this.rowActionsLoadingGroups = (row) => [];
  }

  /**
   * @param {string} header
   * @returns {this}
   */
  setHeader(header) {
    this.header = header;
    return this;
  }

  /**
   * @param {any} headerHtml
   * @returns {this}
   */
  setHeaderHtml(headerHtml) {
    this.headerHtml = headerHtml;
    return this;
  }

  /**
   * @param {int[]} limits
   * @returns {this}
   */
  setLimits(limits) {
    this.limits = limits;
    return this;
  }

  /**
   * @param {int} limit
   * @returns {this}
   */
  setLimit(limit) {
    this.limit = limit;
    return this;
  }

  /**
   * @param {string} orderBy
   * @returns {this}
   */
  setOrderBy(orderBy) {
    this.orderBy = orderBy;
    return this;
  }

  /**
   * @param {string} orderDirection asc or desc
   * @returns
   */
  setOrderDirection(orderDirection) {
    this.orderDirection = orderDirection;
    return this;
  }

  /**
   * @param {string} fetchRequest
   * @returns {this}
   */
  setFetchRequest(fetchRequest) {
    this.fetchRequest = fetchRequest;
    return this;
  }

  /**
   * @param {object} fetchHeaders
   * @returns {this}
   */
  setFetchHeaders(fetchHeaders) {
    this.fetchHeaders = fetchHeaders;
    return this;
  }

  /**
   * @param {object} fetchParams
   * @returns
   */
  setFetchParams(fetchParams) {
    this.fetchParams = fetchParams;
    return this;
  }

  /**
   * @param {ColumnConfig[]} columns
   * @returns {this}
   */
  setColumns(columns) {
    this.columns = columns;
    return this;
  }

  /**
   * @param {RowActionConfig[]} rowActions
   * @returns {this}
   */
  setRowActions(rowActions) {
    this.rowActions = rowActions;
    return this;
  }

  /**
   * Sets the header actions of the datatable.
   * @param {HeaderActionConfig[]} headerActions
   * @returns {this}
   */
  setHeaderActions(headerActions) {
    this.headerActions = headerActions;
    return this;
  }

  /**
   * Set the groups on which all row actions will be loading
   * @param {function(row, dataTable) : string[]} rowActionsLoadingGroups
   */
  setRowActionsLoadingGroups(rowActionsLoadingGroups) {
    this.rowActionsLoadingGroups = rowActionsLoadingGroups;
    return this;
  }

  /**
   * Returns the unique key for the datatable
   * @returns {string}
   */
  getDataTableKey() {
    return this.key.includes("datatable") ? this.key : this.key + "_datatable";
  }

  /**
   * Returns the key for the datatable fetch
   * @returns {string}
   */
  getFetchKey() {
    return `${this.getDataTableKey()}_fetch`;
  }

  /**
   * Returns the key for the datatable settings
   */
  getSettingsKey() {
    return `${this.getDataTableKey()}_settings`;
  }
}

export default DataTableConfig;
