import styled from "styled-components";

export const StyledCalamityIndicator = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacing.normal};
  align-items: center;
  padding-inline: ${(props) => props.theme.spacing.large};
  padding-block: ${(props) => props.theme.spacing.normal};
  border-block: 1px solid ${(props) => props.theme.colors.accent.normal};
  color: ${(props) => props.theme.colors.accent.normal};
  background-color: ${(props) => props.theme.colors.primary.darkest};
`;

export const StyledCalamityIndicatorIcon = styled.span`
  animation: flash 1s infinite;
  @keyframes flash {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
