import { useState } from "react";
import useEvaApi, { EvaApiCall } from "../../hooks/useEvaApi";
import OutlookPageView from "./OutlookPageView";
import useCustomEffect from "../../hooks/useCustomEffect";
import { useAuthContext } from "../../context/AuthContext";
import { useLanguageContext } from "../../context/LanguageContext";
import { useThemeContext } from "../../context/ThemeContext";

function OutlookPage() {
  const callEvaApi = useEvaApi();
  const authContext = useAuthContext();
  const themeContext = useThemeContext();
  const { updateLanguage, languages } = useLanguageContext();
  const [visitorTypes, setVisitorTypes] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [appointmentData, setAppointmentData] = useState({
    location_id: authContext.auth.location ?? authContext.auth.user.company.locations[0].id,
    attendees: [],
    status: "expected",
    sendInvitation: false,
    expected_date: null,
    expected_time: null,
  });
  const [submitError, setSubmitError] = useState(null);
  const checkedAttendeesCount = appointmentData.attendees?.filter(attendee => attendee.checked).length || 0;

  /**
   * initialize the component
   */
  useCustomEffect(() => {
    fetchVisitorTypes();
    fetchEmployees();
    fetchOutlookData();

    // Add an event listener to listen for messages from the parent add-in
    window.addEventListener("message", messageHandler);

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("message", messageHandler);
    }
  }, []);

  /**
   * This method will request the data from the Outlook add-in application
   */
  function fetchOutlookData() {
    window.parent.postMessage({ type: "callRequestData" }, "*");
  }

  /**
   * This method will handle the message from the parent add-in application
   * Verifies the origin of the message and the type of the message
   * @param {MessageEvent} event 
   */
  function messageHandler(event) {
    // Check if the message is a request data message
    if (event.data.type === "appointmentData") {
      const payloadData = event.data.payload;

      // Create the attendees array
      const attendees = payloadData.emails.map(email => ({
        email: email,
        visitor_type_id: null, 
        employee_id: null,
        checked: false,
      }));
      
      // Update the appointment data
      setAppointmentData(prev => ({
        ...prev,
        attendees: attendees,
        expected_date: payloadData.date,
        expected_time: payloadData.time,
      }));

      // Update the language
      const language = languages.find(language =>  `${language.code}`.toLowerCase() === `${payloadData?.language}`.toLowerCase());
      if (language) {
        updateLanguage(language?.id);
      }

      // Update the theme
      if (payloadData.darkmode) {
        themeContext.onThemeChange("dark");
      } else {
        themeContext.onThemeChange("light");
      }
    }
  };

  /**
   * This method will fetch the visitor types data from the API
   */
  async function fetchVisitorTypes() {
    await callEvaApi(
      new EvaApiCall("visitor_types")
        .setLoadingGroup("outlook_page_fetch")
        .setParams({
          with_visitor_type_sign_in_flow_data: true,
          with_flow_step_field_field_data: true,
          with_field_options_data: true,
          with_flow_step_fields_data: true,
          with_flow_steps_data: true,
        })
        .setOnSuccess((response) => {
          setVisitorTypes(response.data.data.records);
        })
    );
  }

  /**
   * This method will fetch the employees data from the API
   */
  async function fetchEmployees() {
    await callEvaApi(
      new EvaApiCall("employees")
        .setLoadingGroup("outlook_page_fetch")
        .setOnSuccess((response) => {
          setEmployees(response.data.data.records);
        })
    );
  }

  /**
   * This method will update the visitor type change
   * @param {any} value
   * @param {int} index
   */
  function onVisitorTypeChange(value, index) {
    setAttendeeData(prev => ({ visitor_type_id: value }), index);
  }

  /**
   * This method will update the location id on input change
   * @param {int} value
   */
  function onLocationIdChange(value) {
    setAppointmentData(prev => ({ ...prev, location_id: value }));
  }

  /**
   * This method will update the checked state of the attendee change
   * @param {int} index
   */
  function onCheckboxChange(index) {
    setAttendeeData(prev => ({
      checked: !prev.checked,
    }), index);
  }

  /**
   * This method will update the checked state of the invitation change
   */
  function onInvitationChange() {
    setAppointmentData((prev) => ({
      ...prev,
      sendInvitation: !prev.sendInvitation,
    }));
  }

  /**
   * This method will update the employee id on input change
   * @param {int} value
   * @param {int} index
   */
  function onEmployeeChange(value, index) {
    setAttendeeData(prev => ({ employee_id: value }), index);
  }

  /**
   * This method will update the field value on input change
   * @param {Object} newData 
   * @param {int} index 
   */
  function onFieldChange(newData, index) {
    setAttendeeData(prev => newData, index);
  }

  /**
   * Retrieve steps for the selected visitor type
   * @param {int} visitorTypeId
   */
  function getStepsForVisitorType(visitorTypeId) {
    const visitorType = visitorTypes.find((visitorType) => visitorType.id === visitorTypeId);
    return visitorType?.sign_in_flow?.steps || [];
  }  

  /**
   * Copy the value of a specific field to all attendees with the same field
   * @param {Object} stepField
   * @param {String} value
   */
  function onCopyField(stepField, value) {
    const fieldId = `field_${stepField.field.id}`;
  
    const updatedAttendees = appointmentData.attendees.map((attendee) => {
      return {
        ...attendee,
        [fieldId]: value,
      };
    });
  
    setAppointmentData({
      ...appointmentData,
      attendees: updatedAttendees,
    });
  };  

  /**
   * This method will set the attendee data
   * @param {*} newData 
   * @param {*} index 
   */
  function setAttendeeData(newData, index) {
    setAppointmentData(prev => {
      const updatedAttendees = [...prev.attendees];
      updatedAttendees[index] = {
        ...updatedAttendees[index],
        ...newData(prev),
      };
      return {
        ...prev,
        attendees: updatedAttendees,
      };
    });    
  }

  /**
   * This method will submit the form to the EVA API and handles its response
   */
  async function onSubmit() {
    // Only submit attendees that are checked
    const attendees = appointmentData.attendees.filter((attendee) => attendee.checked);

    // For each attendee, save the visitor data
    for (let i = 0; i < attendees.length; i++) {
      await callEvaApi(
        new EvaApiCall("visitors")
          .setLoadingGroup("outlook_page_save")
          .setMethod("POST")
          .setData({ 
            ...attendees[i], 
            returning: false, 
            location_id: appointmentData.location_id, 
            status: appointmentData.status, 
            send_invitation: appointmentData.sendInvitation,
            expected_date: appointmentData.expected_date,
            expected_time: appointmentData.expected_time,
          })
          .setErrorState(setSubmitError).setAlertError(true)
      );
    }
  }

  return <OutlookPageView 
    visitorTypes={visitorTypes}
    employees={employees}
    checkedAttendeesCount={checkedAttendeesCount}
    fetchOutlookData={fetchOutlookData}
    onVisitorTypeChange={onVisitorTypeChange}
    onLocationIdChange={onLocationIdChange}
    onCheckboxChange={onCheckboxChange}
    onInvitationChange={onInvitationChange}
    onEmployeeChange={onEmployeeChange}
    onFieldChange={onFieldChange}
    onCopyField={onCopyField}
    appointmentData={appointmentData}
    getStepsForVisitorType={getStepsForVisitorType}
    onSubmit={onSubmit}
    submitError={submitError}
  />;
}

export default OutlookPage;
