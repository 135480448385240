import { useTheme } from "styled-components";
import { StyledInput } from "../../../styles/InputStyles";
import { useLanguageContext } from "../../../context/LanguageContext";
import InputWrapper from "../../../components/input/wrapper/InputWrapper";
import OverlayLoader from "../../../components/loader/OverlayLoader/OverlayLoader";
import FormContainer from "../../../components/formContainer/FormContainer";
import EVAApiHelper from "../../../helpers/EVAApiHelper";
import { StyledFlexColumn } from "../../../styles/LayoutStyles";
import EntityTranslationsContainer from "../../../components/entityTranslationsContainer/EntityTranslationsContainer";

function TypePageView({
  typeData,
  translationsController,
  onNameChange,
  onOrderChange,
  onCancel,
  onSubmit,
  submitError,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  return (
    <StyledFlexColumn style={{ gap: theme.spacing.large }}>
      <OverlayLoader groups="calamities_type_page_fetch">
        <FormContainer
          onCancel={onCancel}
          onSubmit={onSubmit}
          submitError={submitError}
          submitLoadingGroup={"calamities_type_page_submit"}
        >
          <InputWrapper
            label={translate("eva.main.validation.attributes.name")}
            error={EVAApiHelper.getResponseError("name", submitError)}
            margin={`0px 0px ${theme.spacing.normal}`}
            required
          >
            <StyledInput
              type="text"
              placeholder={translate("eva.main.validation.attributes.name")}
              value={typeData.name}
              onChange={onNameChange}
            />
          </InputWrapper>

          <InputWrapper
            label={translate("eva.main.validation.attributes.order")}
            error={EVAApiHelper.getResponseError("order", submitError)}
            required={true}
          >
            <StyledInput
              type="number"
              placeholder={translate("eva.main.validation.attributes.order")}
              value={typeData.order}
              onChange={onOrderChange}
            />
          </InputWrapper>
        </FormContainer>
      </OverlayLoader>

      <OverlayLoader groups="calamities_type_page_fetch">
        <EntityTranslationsContainer controller={translationsController}>
          <InputWrapper
            label={translate("eva.main.validation.attributes.label")}
            error={translationsController.getTranslationError(
              "label",
              submitError
            )}
          >
            <StyledInput
              type="text"
              placeholder={translate("eva.main.validation.attributes.label")}
              value={translationsController.getValue("label")}
              onChange={(e) =>
                translationsController.onTranslationChange(
                  e.target.value,
                  "label"
                )
              }
            />
          </InputWrapper>
        </EntityTranslationsContainer>
      </OverlayLoader>
    </StyledFlexColumn>
  );
}

export default TypePageView;
