import useCustomEffect from "../../../../../../hooks/useCustomEffect";
import FieldsStepView from "./FieldsStepView";

function FieldsStep({
  step,
  errors,
  visitorData,
  setVisitorData,
  setFetchedEntities,
}) {
  /**
   * UseEffect for initializing
   */
  useCustomEffect(() => {
    addFetchedEntities();
  });

  /**
   * This method will add the fetched fields to the fetched entities
   */
  function addFetchedEntities() {
    setFetchedEntities((prev) => {
      const newFields = [...prev.fields];
      step.fields.forEach((row) => {
        row.forEach((stepField) => {
          if (!newFields.find((field) => field.id === stepField.field.id)) {
            newFields.push(stepField.field);
          }
        });
      });

      return {
        ...prev,
        fields: newFields,
      };
    });
  }

  /**
   * This method will handle the field change event
   * @param {object} stepField
   * @param {any} value
   */
  function onFieldChange(stepField, value) {
    setVisitorData({
      ...visitorData,
      [`field_${stepField.field.id}`]: value,
    });
  }

  return (
    <FieldsStepView
      step={step}
      errors={errors}
      visitorData={visitorData}
      onFieldChange={onFieldChange}
    />
  );
}

export default FieldsStep;
