import styled from "styled-components";

export const StyledMessage = styled.div`
  background-color: ${(props) => props.theme.colors.primary.dark};
  padding: ${(props) => props.theme.spacing.normal};
  border-radius: ${(props) => props.theme.radius.normal};
  border: 1px solid ${(props) => props.theme.colors.primary.lightest};
`;

export const StyledHeader = styled.p`
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
