import { useMemo } from "react";
import ConnectionView from "./ConnectionView";

function Connection({
  id,
  fromConnectorId,
  toConnectorId,
  connections,
  zoomPanController,
  onClick,
  active,
  refs,
  children,
}) {
  const fromElement = refs[fromConnectorId];
  const toElement = refs[toConnectorId];
  const shared = useMemo(
    () =>
      connections.find(
        (c) =>
          c.toConnectorId === fromConnectorId &&
          c.fromConnectorId === toConnectorId
      ),
    [connections, fromConnectorId, toConnectorId]
  );

  const position = getPosition();
  const arrow = getArrow(position);

  /**
   * This method will return the position of the svg.
   * @returns {object}
   */
  function getPosition() {
    if (!fromElement || !toElement) {
      return null;
    }

    const fromRect = getNormalizedRect(fromElement);
    const toRect = getNormalizedRect(toElement);

    const padding = 10;

    const minX = Math.min(fromRect.x, toRect.x);
    const minY = Math.min(fromRect.y, toRect.y);
    const maxX = Math.max(fromRect.x + fromRect.width, toRect.x + toRect.width);
    const maxY = Math.max(
      fromRect.y + fromRect.height,
      toRect.y + toRect.height
    );

    return {
      x: minX - padding,
      y: minY - padding,
      width: maxX - minX + padding * 2,
      height: maxY - minY + padding * 2,
    };
  }

  /**
   * This method will return the normalized rect of the element.
   * @param {any} element
   * @returns {object}
   */
  function getNormalizedRect(element) {
    if (!element || !zoomPanController.editorContentRef.current) {
      return null;
    }

    const rect = element.getBoundingClientRect();
    const editorRect =
      zoomPanController.editorContentRef.current.getBoundingClientRect();
    const scale = zoomPanController.data.scale;

    return {
      x: (rect.x - editorRect.x) / scale,
      y: (rect.y - editorRect.y) / scale,
      width: rect.width / scale,
      height: rect.height / scale,
    };
  }

  /**
   * This method will return the path of the line including a filled arrowhead
   * @param {object} svgPosition
   * @returns {string}
   */
  function getArrow(svgPosition) {
    if (!fromElement || !toElement || !svgPosition) {
      return null;
    }

    const fromRect = getNormalizedRect(fromElement);
    const toRect = getNormalizedRect(toElement);

    let fromX = fromRect.x + fromRect.width / 2 - svgPosition.x;
    let fromY = fromRect.y + fromRect.height / 2 - svgPosition.y;
    let toX = toRect.x + toRect.width / 2 - svgPosition.x;
    let toY = toRect.y + toRect.height / 2 - svgPosition.y;

    const angle = Math.atan2(toY - fromY, toX - fromX);

    const spacing = 2.5;
    fromX += (fromRect.width / 2 + spacing) * Math.cos(angle);
    fromY += (fromRect.height / 2 + spacing) * Math.sin(angle);
    toX -= (toRect.width / 2 + spacing) * Math.cos(angle);
    toY -= (toRect.height / 2 + spacing) * Math.sin(angle);

    // If the connection is shared, we want to start the arrow from the middle of the line
    if (shared) {
      fromX = (fromX + toX) / 2;
      fromY = (fromY + toY) / 2;
    }

    const arrowAngle = Math.PI / 6; // 30 degrees
    const arrowLength = 10;

    const arrowX1 = toX - arrowLength * Math.cos(angle + arrowAngle);
    const arrowY1 = toY - arrowLength * Math.sin(angle + arrowAngle);
    const arrowX2 = toX - arrowLength * Math.cos(angle - arrowAngle);
    const arrowY2 = toY - arrowLength * Math.sin(angle - arrowAngle);

    return {
      center: {
        x: (fromX + toX) / 2,
        y: (fromY + toY) / 2,
      },
      path: `
        M${fromX} ${fromY} 
        L${toX} ${toY} 
        M${toX} ${toY} 
        L${arrowX1} ${arrowY1} 
        L${arrowX2} ${arrowY2} 
        Z
      `,
    };
  }

  return (
    <ConnectionView
      id={id}
      position={position}
      arrow={arrow}
      onClick={onClick}
      active={active}
    >
      {children}
    </ConnectionView>
  );
}

export default Connection;
