import InputWrapper from "../../../../../../components/input/wrapper/InputWrapper";
import Select from "../../../../../../components/select/main/Select";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import EVAApiHelper from "../../../../../../helpers/EVAApiHelper";

function HouserulesFormView({
  relations,
  selectedStep,
  selectedStepIndex,
  onHouserulesChange,
  submitError,
}) {
  const { translate } = useLanguageContext();

  return (
    <InputWrapper
      label={translate("eva.main.validation.attributes.steps.*.file_id")}
      required
      error={EVAApiHelper.getResponseError(
        `steps.${selectedStepIndex}.file_id`,
        submitError
      )}
    >
      <Select
        value={selectedStep?.file_id}
        options={relations?.files
          ?.filter((file) => file.type === "houserule")
          ?.map((file) => ({ value: file.id, label: file.name }))}
        onChange={onHouserulesChange}
      />
    </InputWrapper>
  );
}

export default HouserulesFormView;
