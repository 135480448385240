import FormContainer from "../../../components/formContainer/FormContainer";
import InputWrapper from "../../../components/input/wrapper/InputWrapper";
import OverlayLoader from "../../../components/loader/OverlayLoader/OverlayLoader";
import { useLanguageContext } from "../../../context/LanguageContext";
import EVAApiHelper from "../../../helpers/EVAApiHelper";
import { StyledInput } from "../../../styles/InputStyles";
import TinyMessageEditor from "../../../components/tinyEditor/message/TinyMessageEditor";
import EntityTranslationContainer from "../../../components/entityTranslationsContainer/EntityTranslationsContainer";
import { useTheme } from "styled-components";
import { StyledFlexColumn } from "../../../styles/LayoutStyles";

function MessageTemplatePageView({
  messageTemplateData,
  messageController,
  translationsController,
  onNameChange,
  onCancel,
  onSubmit,
  submitError,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  return (
    <>
      <OverlayLoader groups="message_template_page_fetch">
        <FormContainer
          onCancel={onCancel}
          onSubmit={onSubmit}
          submitError={submitError}
          submitLoadingGroup={"message_template_page_submit"}
        >
          <InputWrapper
            label={translate("eva.main.validation.attributes.name")}
            error={EVAApiHelper.getResponseError("name", submitError)}
            required
          >
            <StyledInput
              type="text"
              placeholder={translate("eva.main.validation.attributes.name")}
              value={messageTemplateData.name}
              onChange={onNameChange}
            />
          </InputWrapper>
        </FormContainer>
      </OverlayLoader>

      <OverlayLoader groups="message_template_page_fetch">
        <EntityTranslationContainer
          controller={translationsController}
          style={{ marginTop: theme.spacing.large }}
        >
          <StyledFlexColumn>
            <InputWrapper
              label={translate("eva.main.validation.attributes.subject")}
              required
              error={translationsController.getTranslationError(
                "subject",
                submitError
              )}
            >
              <StyledInput
                type="text"
                placeholder={translate(
                  "eva.main.validation.attributes.subject"
                )}
                value={translationsController.getValue("subject")}
                onChange={(e) =>
                  translationsController.onTranslationChange(
                    e.target.value,
                    "subject"
                  )
                }
              />
            </InputWrapper>

            <InputWrapper
              label={translate("eva.main.validation.attributes.message")}
              required
              error={translationsController.getTranslationError(
                "message",
                submitError
              )}
            >
              <TinyMessageEditor controller={messageController} />
            </InputWrapper>
          </StyledFlexColumn>
        </EntityTranslationContainer>
      </OverlayLoader>
    </>
  );
}

export default MessageTemplatePageView;
