import { StyledArrow, StyledArrowCenter } from "./ConnectionStyles";

function ConnectionView({ id, position, arrow, active, onClick, children }) {
  return (
    position && (
      <div
        style={{
          position: "absolute",
          pointerEvents: "none",
          left: position.x,
          top: position.y,
          width: position.width,
          height: position.height,
        }}
      >
        <svg
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          {arrow && (
            <StyledArrow
              id={id}
              active={active}
              d={arrow.path}
              onClick={onClick}
            />
          )}
        </svg>

        {arrow && (
          <StyledArrowCenter
            style={{
              left: arrow.center.x,
              top: arrow.center.y,
            }}
          >
            {children}
          </StyledArrowCenter>
        )}
      </div>
    )
  );
}

export default ConnectionView;
