import MessagesStepView from "./MessagesStepView";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import { useAuthContext } from "../../../../../../context/AuthContext";
import { useFrontofficeContext } from "../../../../../../context/FrontofficeContext";
import useEvaApi, { EvaApiCall } from "../../../../../../hooks/useEvaApi";
import useCustomEffect from "../../../../../../hooks/useCustomEffect";
import { QRCodeSVG } from "qrcode.react";
import { renderToString } from "react-dom/server";

function MessagesStep({
  step,
  visitor,
  visitorType,
  visitorData,
  fetchedEntities,
  setFetchedEntities,
}) {
  const callEvaApi = useEvaApi();
  const authContext = useAuthContext();
  const { translateEntity, translate, language } = useLanguageContext();
  const { terminals, terminal } = useFrontofficeContext();

  // Get the messages for the step
  const messages = step.messages
    .filter((message) => message.type === "screen")
    .map((message) => getMessageContent(message));

  /**
   * UseEffect for initializing
   */
  useCustomEffect(() => {
    fetchNeededRelations();
  });

  /**
   * This method will return the content for the message
   * @param {object} message
   * @returns {string}
   */
  function getMessageContent(message) {
    let content = translateEntity(message.message_template, "message");
    if (!content) return null;

    const replacements = getMessageReplacements();
    Object.keys(replacements).forEach((key) => {
      content = content.replace(`[${key}]`, replacements[key]);
    });

    return content;
  }

  /**
   * This method will return the replacements for the message
   * @returns {object}
   */
  function getMessageReplacements() {
    return {
      ...getMessageVisitorReplacements(),
      ...getMessageFieldReplacements(),
    };
  }

  /**
   * This method will return the visitor replacements for the message
   * @returns {object}
   */
  function getMessageVisitorReplacements() {
    return {
      vis_company_name: authContext.auth.user?.company?.name,
      vis_location_name: authContext.auth.user?.company?.locations?.find(
        (loc) => loc.id === authContext.auth.location
      )?.name,
      vis_visitor_type_name: translateEntity(visitorType, "title"),
      vis_language_label: language?.label,
      vis_sign_in_terminal_name:
        terminals.find(
          (t) => parseInt(t.id) === parseInt(visitor?.sign_in_terminal)
        )?.name ?? terminal?.name,
      vis_employee_name: fetchedEntities.employees.find(
        (emp) => parseInt(emp.id) === parseInt(visitorData.employee_id)
      )?.name,
      vis_email: visitor?.email,
      vis_registration_code: visitor?.registration_code,
      vis_registration_code_qr: visitor?.registration_code
        ? renderToString(<QRCodeSVG value={visitor?.registration_code} />)
        : visitor?.registration_code,
      vis_returning: visitor?.returning
        ? translate(`eva.main.general.${visitor.returning ? "yes" : "no"}`)
        : visitor?.returning,
      vis_expected_date: visitor?.expected_date,
      vis_expected_end_date: visitor?.expected_end_date,
      vis_expected_time: visitor?.expected_time,
      vis_signed_in_at: visitor?.signed_in_at,
      vis_signed_out_at: visitor?.signed_out_at,
      vis_status: visitor?.status
        ? translate(`eva.main.visitor_statuses.${visitor.status}`)
        : visitor?.status,
    };
  }

  /**
   * This method will return the field replacements for the message
   * @returns {object}
   */
  function getMessageFieldReplacements() {
    return Object.keys(visitorData)
      .filter((key) => key.startsWith("field_"))
      .reduce((acc, key) => {
        const fieldId = key.split("_")[1];
        const field = fetchedEntities.fields.find(
          (field) => parseInt(field.id) === parseInt(fieldId)
        );
        if (!field) {
          return acc;
        }

        const replacementKey = `field_${field.key}`;
        let value = null;

        switch (field.type) {
          case "boolean":
            value = translate(
              `eva.main.general.${visitorData[key] ? "yes" : "no"}`
            );
            break;
          case "select":
            value = translateEntity(
              field.options.find((option) => option.id === visitorData[key]),
              "label"
            );
            break;
          default:
            value = visitorData[key];
            break;
        }

        acc[replacementKey] = value;
        return acc;
      }, {});
  }

  /**
   * This method will fetch the needed relations
   */
  async function fetchNeededRelations() {
    fetchNeededFields();
    fetchNeededEmployee();
  }

  /**
   * This method will fetch the needed fields
   */
  async function fetchNeededFields() {
    const neededFieldIds = Object.keys(visitorData)
      .filter((key) => key.startsWith("field_"))
      .map((key) => key.split("_")[1])
      .filter(
        (id) =>
          !fetchedEntities.fields.find(
            (field) => parseInt(field.id) === parseInt(id)
          )
      );

    if (neededFieldIds.length > 0) {
      await callEvaApi(
        new EvaApiCall("fields")
          .setLoadingGroup("visitor_type_page_fetch")
          .setParams({
            with_field_options_data: true,
            where: [
              {
                column: "id",
                values: [{ operator: "in", value: neededFieldIds }],
              },
            ],
          })
          .setAlertError(false)
          .setAlertSuccess(false)
          .setOnSuccess((response) => {
            setFetchedEntities((prev) => ({
              ...prev,
              fields: [...prev.fields, ...response.data.data.records],
            }));
          })
      );
    }
  }

  /**
   * This method will fetch the needed employee
   */
  async function fetchNeededEmployee() {
    const employee = fetchedEntities.employees.find(
      (emp) => parseInt(emp.id) === parseInt(visitorData.employee_id)
    );

    if (!employee) {
      await callEvaApi(
        new EvaApiCall("employees")
          .setLoadingGroup("visitor_type_page_fetch")
          .setParams({
            where: [
              {
                column: "id",
                values: [visitorData.employee_id],
              },
            ],
          })
          .setAlertError(false)
          .setAlertSuccess(false)
          .setOnSuccess((response) => {
            setFetchedEntities((prev) => ({
              ...prev,
              employees: [...prev.employees, ...response.data.data.records],
            }));
          })
      );
    }
  }

  return <MessagesStepView messages={messages} />;
}

export default MessagesStep;
