import { StyledFlexColumn } from "../../../../../../styles/LayoutStyles";

function MessagesStepView({ messages }) {
  return (
    <StyledFlexColumn>
      {messages.map((message, index) => (
        <div key={index} dangerouslySetInnerHTML={{ __html: message }} />
      ))}
    </StyledFlexColumn>
  );
}

export default MessagesStepView;
