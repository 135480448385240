import { useTheme } from "styled-components";
import { useFrontofficeContext } from "../../../../../context/FrontofficeContext";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import Layout from "../../../../../components/layout/Layout";
import { StyledFlexColumn } from "../../../../../styles/LayoutStyles";
import InputWrapper from "../../../../../components/input/wrapper/InputWrapper";
import { StyledInput } from "../../../../../styles/InputStyles";
import { StyledSignoutButton } from "./ManualSignoutPageStyles";
import { StyledAltButton } from "../../../../../styles/ButtonStyles";
import HiddenLoader from "../../../../../components/loader/HiddenLoader/HiddenLoader";

function ManualSignOutPageView({ search, selectedVisitor, onSearchChange }) {
  const theme = useTheme();
  const { updatePage, terminal, getVisitorIdentifierString } =
    useFrontofficeContext();
  const { translate } = useLanguageContext();

  return (
    <Layout
      layoutData={terminal?.layout}
      content={
        <StyledFlexColumn>
          <HiddenLoader groups={"sign_out_fetch"}>
            <StyledFlexColumn
              style={{
                marginBottom: theme.spacing.large,
              }}
            >
              <InputWrapper
                required
                label={translate(
                  "eva.frontoffice.pages.manual_sign_out.fields.visitor"
                )}
                error={
                  !selectedVisitor &&
                  search.length > 0 &&
                  translate(
                    "eva.frontoffice.pages.manual_sign_out.errors.visitor.not_found_unique"
                  )
                }
              >
                <StyledInput value={search} onChange={onSearchChange} />
              </InputWrapper>

              {selectedVisitor && (
                <StyledSignoutButton
                  onClick={() =>
                    updatePage("visitor_type", {
                      visitor: selectedVisitor,
                      signIn: false,
                    })
                  }
                >
                  {translate(
                    "eva.frontoffice.pages.manual_sign_out.fields.sign_out"
                  ) + ": "}

                  <span>{getVisitorIdentifierString(selectedVisitor)}</span>
                </StyledSignoutButton>
              )}
            </StyledFlexColumn>
          </HiddenLoader>

          <StyledAltButton onClick={() => updatePage("start")}>
            {translate("eva.frontoffice.general.back")}
          </StyledAltButton>
        </StyledFlexColumn>
      }
    />
  );
}

export default ManualSignOutPageView;
