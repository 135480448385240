import OutlookPage from "../../pages/outlook/OutlookPage";
import { useOutlookRouteValidator } from "../validators/OutlookRouteValidator";
import { RouteConfig } from "./RouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useOutlookRouteConfig() {
  return new RouteConfig()
    .setPath("/outlook")
    .setValidator(useOutlookRouteValidator())
    .setPage(<OutlookPage />)
}

export default useOutlookRouteConfig;