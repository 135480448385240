import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  StyledAltIconButton,
  StyledTextButton,
} from "../../styles/ButtonStyles";
import {
  StyledEditor,
  StyledEditorBackground,
  StyledEditorContent,
  StyledEditorControls,
  StyledEditorZoomIndicator,
} from "./ZoomPanEditorStyles";
import {
  faExpand,
  faLock,
  faRefresh,
  faUnlock,
} from "@fortawesome/free-solid-svg-icons";
import { useLanguageContext } from "../../context/LanguageContext";
import { StyledFlexColumn, StyledFlexRow } from "../../styles/LayoutStyles";
import { useTheme } from "styled-components";

function ZoomPanEditorView({ controller, customControls, children }) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  return (
    <StyledEditor
      onWheel={controller.onScroll}
      onMouseDown={controller.onMouseDown}
      ref={controller.editorRef}
    >
      <StyledEditorBackground
        ref={controller.editorBackgroundRef}
        position={controller.data.position}
        scale={controller.data.scale}
      />

      <StyledEditorContent
        position={controller.data.position}
        scale={controller.data.scale}
        ref={controller.editorContentRef}
      >
        {children}
      </StyledEditorContent>

      <StyledFlexColumn
        style={{
          position: "absolute",
          top: theme.spacing.normal,
          right: theme.spacing.normal,
          alignItems: "flex-end",
        }}
      >
        <StyledEditorControls>
          <StyledFlexRow style={{ flexWrap: "nowrap" }}>
            <StyledEditorZoomIndicator disabled={controller.data.scaleDisabled}>
              {Math.round(controller.data.scale * 100)}%
              <StyledTextButton
                onClick={controller.resetZoom}
                title={translate("eva.main.general.reset")}
              >
                <FontAwesomeIcon icon={faRefresh} size="lg" />
              </StyledTextButton>
            </StyledEditorZoomIndicator>

            <StyledAltIconButton
              onClick={controller.centerContent}
              title={translate("eva.main.general.center")}
            >
              <FontAwesomeIcon icon={faExpand} size="lg" />
            </StyledAltIconButton>

            <StyledAltIconButton
              onClick={controller.onLockClick}
              title={translate(
                `eva.main.general.${controller.data.locked ? "unlock" : "lock"}`
              )}
            >
              <FontAwesomeIcon
                icon={controller.data.locked ? faLock : faUnlock}
                size="lg"
              />
            </StyledAltIconButton>
          </StyledFlexRow>
        </StyledEditorControls>

        {customControls && (
          <StyledEditorControls>{customControls}</StyledEditorControls>
        )}
      </StyledFlexColumn>
    </StyledEditor>
  );
}

export default ZoomPanEditorView;
