import styled from "styled-components";

export const StyledConnector = styled.div`
  position: absolute;
  width: 11px;
  height: 11px;
  background-color: ${(props) =>
    props.active
      ? props.theme.colors.accent.normal
      : props.theme.colors.on.primary};
  opacity: ${(props) => (props.disabled ? 0.2 : 1)};
  pointer-events: ${(props) =>
    props.onClick && !props.disabled ? "auto" : "none"};

  ${(props) => {
    switch (props.position) {
      case "top":
        return `
        top: 0;
        left: 50%;
        transform: translate(-50%, calc(-100%));
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
      `;
      case "bottom":
        return `
        bottom: 0;
        left: 50%;
        transform: translate(-50%, calc(100%));
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
      `;
      case "left":
        return `
        top: 50%;
        left: 0;
        transform: translate(calc(-100%), -50%);
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
      `;
      case "right":
        return `
        top: 50%;
        right: 0;
        transform: translate(calc(100%), -50%);
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
      `;
      default:
        return "";
    }
  }}

  &:hover {
    background-color: ${(props) => props.theme.colors.accent.dark};
    cursor: pointer;
  }
`;
