import Moveable from "../../moveable/Moveable";

function NodeView({
  id,
  title,
  position,
  onPositionChange,
  connectors,
  onClick,
  active,
  Element,
  zoomPanController,
}) {
  return (
    <Moveable
      position={position}
      onPositionChange={onPositionChange}
      disabled={!onPositionChange}
      speed={1 / zoomPanController.data.scale}
    >
      <div id={id}>
        <div style={{ position: "relative" }}>
          <Element id={id} active={active} onClick={onClick}>
            <p style={{ pointerEvents: "none" }}>
              <strong>{title}</strong>
            </p>
          </Element>

          {connectors}
        </div>
      </div>
    </Moveable>
  );
}

export default NodeView;
