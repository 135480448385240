import useMiddlewares from "../../middlewares/Middleware";
import useRouteValidator from "./RouteValidator";
import useAuthMiddleware from "../../middlewares/AuthMiddleware";

/**
 * @returns {{ validate: () => boolean|{ to: string, replace: boolean }}}
 */
export function useOutlookRouteValidator() { 
  return useRouteValidator(
    useMiddlewares([
      useAuthMiddleware(), 
    ])
  );
}