import { StyledInputError } from "../../../../components/input/wrapper/InputWrapperStyles";
import Layout from "../../../../components/layout/Layout";
import HiddenLoader from "../../../../components/loader/HiddenLoader/HiddenLoader";
import { useFrontofficeContext } from "../../../../context/FrontofficeContext";
import { useLanguageContext } from "../../../../context/LanguageContext";
import EVAApiHelper from "../../../../helpers/EVAApiHelper";
import { StyledAltButton, StyledButton } from "../../../../styles/ButtonStyles";
import { StyledFlexItem, StyledFlexRow } from "../../../../styles/LayoutStyles";
import { getStep } from "./steps/Steps";
import { StyledVisitorTypePage } from "./VisitorTypePageStyles";

function VisitorTypePageView({
  step,
  errors,
  visitor,
  visitorData,
  visitorType,
  submitted,
  setVisitorData,
  disabledNavItems,
  setDisabledNavItems,
  fetchedEntities,
  setFetchedEntities,
  onNextStep,
  onPreviousStep,
}) {
  const { translate } = useLanguageContext();
  const { updatePage, terminal } = useFrontofficeContext();

  const layout = visitorType?.layout || terminal?.layout;
  const StepComponent = getStep(step?.type);

  return (
    <Layout
      layoutData={layout}
      content={
        <HiddenLoader groups={"visitor_type_page_fetch"} iconSize={"lg"}>
          <StyledVisitorTypePage key={step?.id}>
            {/* Step Component */}
            {StepComponent && (
              <StepComponent
                key={step.id}
                step={step}
                errors={errors}
                visitor={visitor}
                visitorType={visitorType}
                visitorData={visitorData}
                setVisitorData={setVisitorData}
                setDisabledNavItems={setDisabledNavItems}
                fetchedEntities={fetchedEntities}
                setFetchedEntities={setFetchedEntities}
              />
            )}

            <StyledInputError>
              {EVAApiHelper.getResponseError("message", errors)}
            </StyledInputError>

            <HiddenLoader groups={"visitor_type_page_submit"}>
              <StyledFlexRow>
                {/* Cancel Button */}
                {!submitted && (
                  <StyledFlexItem>
                    <StyledAltButton
                      onClick={() => updatePage("start")}
                      disabled={disabledNavItems.includes("cancel")}
                      style={{ width: "100%" }}
                    >
                      {translate("eva.frontoffice.general.cancel")}
                    </StyledAltButton>
                  </StyledFlexItem>
                )}

                {/* Back Button */}
                {!submitted && (
                  <StyledFlexItem>
                    <StyledAltButton
                      onClick={() => onPreviousStep()}
                      disabled={disabledNavItems.includes("previous")}
                      style={{ width: "100%" }}
                    >
                      {translate("eva.frontoffice.general.back")}
                    </StyledAltButton>
                  </StyledFlexItem>
                )}

                {/* Proceed Button */}
                <StyledFlexItem>
                  <StyledButton
                    onClick={() => onNextStep()}
                    disabled={disabledNavItems.includes("next")}
                    style={{ width: "100%" }}
                  >
                    {translate("eva.frontoffice.general.proceed")}
                  </StyledButton>
                </StyledFlexItem>
              </StyledFlexRow>
            </HiddenLoader>
          </StyledVisitorTypePage>
        </HiddenLoader>
      }
    />
  );
}

export default VisitorTypePageView;
