import { useState } from "react";
import { useFrontofficeContext } from "../../../../context/FrontofficeContext";
import useEvaApi, { EvaApiCall } from "../../../../hooks/useEvaApi";
import EVAApiHelper from "../../../../helpers/EVAApiHelper";
import { useLanguageContext } from "../../../../context/LanguageContext";
import RegistrationCodePageView from "./RegistrationCodePageView";
import useCustomEffect from "../../../../hooks/useCustomEffect";
import useScannerListener from "../../../../hooks/useScannerListener";

function RegistrationCodePage() {
  useScannerListener(onQRScan);
  const callEvaApi = useEvaApi();
  const { translate } = useLanguageContext();
  const { terminal } = useFrontofficeContext();
  const { pageParams, updatePage } = useFrontofficeContext();

  const [registrationCode, setRegistrationCode] = useState(
    pageParams.registrationCode ?? ""
  );
  const [error, setError] = useState(null);

  /**
   * UseEffect for checking the registration code
   */
  useCustomEffect(() => {
    if (pageParams.registrationCode) {
      onProceed();
    }
  });

  /**
   * This method will handle the registration code change event
   * @param {event} e
   */
  function onRegistrationCodeChange(e) {
    setRegistrationCode(e.target.value);
  }

  /**
   * This method will handle the QR scan event
   * @param {string} value
   */
  function onQRScan(value) {
    setRegistrationCode(value);
    onProceed(value);
  }

  /**
   * This method will handle the proceed event
   */
  async function onProceed(regCode = null) {
    regCode = regCode ?? registrationCode;

    //Fetch the visitor by the QR code
    await callEvaApi(
      new EvaApiCall("visitors")
        .setLoadingGroup("qr_sign_in_fetch")
        .setErrorState(setError)
        .setParams({
          with_visitor_visitor_type_data: true,
          with_visitor_fields_data: true,
          with_visitor_questions_data: true,
          with_visitor_houserules_data: true,
          limit: 1,
          scheduled_only: true,
          where: [
            {
              column: "registration_code",
              values: [regCode],
            },
            {
              column: "visitor_type_id",
              values: [
                {
                  value: terminal?.visitor_types?.map((vt) => vt.id),
                  operator: "in",
                },
              ],
            },
          ],
        })
        .setOnSuccess((response) => {
          const visitors = response.data.data.records;

          //Show error if the visitor is not found or not unique
          if (visitors.length !== 1) {
            setError(
              EVAApiHelper.createResponseError(
                translate(
                  "eva.frontoffice.pages.registration_code.errors.visitor.not_found"
                )
              )
            );
            return;
          }

          //Get the visitor
          const visitor = visitors[0];

          //Go to the visitor type page to handle sign in / sign out
          updatePage("visitor_type", {
            visitor: visitor,
            signIn: visitor.signed_in_at ? false : true,
            simulated: true,
          });
        })
    );
  }

  return (
    <RegistrationCodePageView
      registrationCode={registrationCode}
      onRegistrationCodeChange={onRegistrationCodeChange}
      onProceed={onProceed}
      error={error}
    />
  );
}

export default RegistrationCodePage;
