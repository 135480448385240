import EmployeeStep from "./employee/EmployeeStep";
import FieldsStep from "./fields/FieldsStep";
import HouserulesStep from "./houserules/HouseruleStep";
import MessagesStep from "./messages/MessagesStep";

export const steps = {
  fields: FieldsStep,
  houserules: HouserulesStep,
  employee: EmployeeStep,
  messages: MessagesStep,
};

export function getStep(type) {
  return steps[type];
}
