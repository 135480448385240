import styled from "styled-components";

export const StyledDashboardContainer = styled.div`
  height: 100vh;
  display: flex;
`;

export const StyledDashboardMainContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
`;

export const StyledDashboardHeader = styled.header`
  height: 80px;
  background-color: ${(props) => props.theme.colors.primary.darkest};
  color: ${(props) => props.theme.colors.on.primary};
  border-bottom: 1px solid ${(props) => props.theme.colors.primary.lightest};
  padding: 0px ${(props) => props.theme.spacing.large};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${(props) => props.theme.spacing.large};
`;

export const StyledDashboardMain = styled.main`
  flex: 1;
  padding: ${(props) => props.theme.spacing.large};
  overflow-y: auto;
  overflow-x: auto;
`;

export const StyledDashboardUserName = styled.p`
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
`;
