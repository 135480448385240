import React, { createContext, useContext, useState } from "react";
import useEvaApi, { EvaApiCall } from "../hooks/useEvaApi";
import useContextDefiner, {
  ContextDefinition,
} from "../hooks/useContextDefiner";
import useCustomEffect from "../hooks/useCustomEffect";
import { useAppContext } from "./AppContext";

const LanguageContext = createContext();

export let STATIC_LANGUAGE = {};

export function LanguageProvider({ children }) {
  const { contexts } = useAppContext();
  const callEvaApi = useEvaApi();
  const [data, setData] = useState({
    translations: {},
    language: null,
    languages: [],
  });
  const defaultLanguage = data.languages.find(
    (record) => record.default === true
  );

  /**
   * UseEffect for fetching the languages when the user changes
   */
  useCustomEffect(() => {
    fetchLanguages();
  }, [
    contexts.authContext?.auth?.user,
    contexts.authContext?.auth?.initialized,
  ]);

  /**
   * UseEffect for fetching the translations when the language changes
   */
  useCustomEffect(() => {
    fetchTranslations();
  }, [data.language]);

  /**
   * This method will update the data and the static language object
   * @param {function(prev)} callback the callback function that will update the data
   */
  function updateData(callback) {
    setData((prev) => {
      const newData = callback(prev);
      STATIC_LANGUAGE = newData;
      return newData;
    });
  }

  /**
   * This method will fetch the languages from the API
   */
  async function fetchLanguages() {
    if (!contexts.authContext?.auth?.initialized) {
      return;
    }

    await callEvaApi(
      new EvaApiCall("languages")
        .setLoadingGroup("APP")
        .setBlockAppRender(true)
        .setOnSuccess((response) => {
          const newLanguages = response.data.data.records;
          updateData((prev) => ({
            ...prev,
            languages: newLanguages,
            language:
              newLanguages.find(
                (record) =>
                  parseInt(record.id) ===
                  parseInt(localStorage.getItem("language_id"))
              ) || newLanguages.find((record) => record.default === true),
          }));
        })
        .setOnError(() => {
          updateData((prev) => ({
            ...prev,
            languages: [],
            language: null,
          }));
        })
        .setAlertError(false)
        .setAlertSuccess(false)
    );
  }

  /**
   * This method will fetch the translations from the API
   */
  async function fetchTranslations() {
    if (!data.language) {
      return;
    }

    await callEvaApi(
      new EvaApiCall(`translations/${data.language.id}`)
        .setLoadingGroup("APP")
        .setBlockAppRender(true)
        .setOnSuccess((response) => {
          updateData((prev) => ({
            ...prev,
            translations: getTranslationsDictionary(response.data.data.records),
          }));
        })
        .setOnError(() => {
          updateData((prev) => ({
            ...prev,
            translations: {},
          }));
        })
        .setAlertError(false)
        .setAlertSuccess(false)
    );
  }

  /**
   * This method will convert the translations to a dictionary
   * @param {object[]} translations
   * @returns
   */
  function getTranslationsDictionary(translations) {
    const dictionary = {};
    translations.forEach((translation) => {
      dictionary[translation.key] = translation.value;
    });

    return dictionary;
  }

  /**
   * This method will update the language of the context
   * @param {int} languageId
   */
  function updateLanguage(languageId) {
    const newLanguage = data.languages.find(
      (record) => parseInt(record.id) === parseInt(languageId)
    );
    if (newLanguage) {
      localStorage.setItem("language_id", newLanguage.id);
      updateData((prev) => ({
        ...prev,
        language: newLanguage,
      }));
    }
  }

  /**
   * This method will try to find the translation of the given key
   * @param {string} key the key of the translation
   * @param {object[]} replace array of key value objects to replace chars
   * @returns {string|JSX.Element} the translation
   */
  function translate(key, replace = []) {
    //Find the translation
    let translation = data.translations[key];
    if (!translation) {
      return key;
    }

    //Clone the translation
    translation = translation.slice();

    //Check if we need to translate as html
    const asHtml =
      replace.filter((replacement) => typeof replacement.value === "object")
        .length > 0;
    if (asHtml) {
      return translateAsHtml(translation, replace);
    }

    //Translate as text
    replace.forEach((replacement) => {
      translation = translation.replace(
        ":" + replacement.key,
        replacement.value
      );
    });

    //Return the translation as text
    return translation;
  }

  /**
   * This method will return the given translation as html
   * @param {string} translation
   * @param {object[]} replace
   * @returns {JSX.Element}
   */
  function translateAsHtml(translation, replace) {
    const parts = translation.split(/(:\w+)/);

    return (
      <>
        {parts.map((part, index) => {
          const replacement = replace.find(
            (replacement) => `:${replacement.key}` === part
          );
          return (
            <React.Fragment key={index}>
              {replacement?.value ?? part}
            </React.Fragment>
          );
        })}
      </>
    );
  }

  /**
   * This method will try to find the translation of the given key in the entity
   * @param {object} entity
   * @param {string} key
   * @returns {string | null}
   */
  function translateEntity(entity, key) {
    if (!entity || !key) {
      return null;
    }

    if (entity?.use_translations === false) {
      return entity[key];
    } else {
      let entityTranslation = entity?.translations?.find(
        (translation) => translation?.language === data.language?.id
      );

      if (entityTranslation) {
        return entityTranslation[key];
      }
    }

    return null;
  }

  return useContextDefiner(
    new ContextDefinition("languageContext", LanguageContext)
      .setData({
        languages: data.languages,
        language: data.language,
        translations: data.translations,
        defaultLanguage,
        translate,
        translateEntity,
        updateLanguage,
        fetchLanguages,
        fetchTranslations,
      })
      .setChildren(children)
  );
}

export function useLanguageContext() {
  return useContext(LanguageContext);
}
