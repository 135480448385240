import { useTheme } from "styled-components";
import FormContainer from "../../components/formContainer/FormContainer";
import InputWrapper from "../../components/input/wrapper/InputWrapper";
import OverlayLoader from "../../components/loader/OverlayLoader/OverlayLoader";
import Select from "../../components/select/main/Select";
import { useLanguageContext } from "../../context/LanguageContext";
import EVAApiHelper from "../../helpers/EVAApiHelper";
import { StyledInput } from "../../styles/InputStyles";
import LocationFormSelect from "../../components/select/custom/location/form/LocationFormSelect";
import Checkbox from "../../components/input/checkbox/Checkbox";
import { StyledFlexColumn, StyledFlexItem, StyledFlexRow } from "../../styles/LayoutStyles";
import { StyledIconButton } from "../../styles/ButtonStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import OutlookFields from "./fields/OutlookFields";

function OutlookPageView({
  visitorTypes,
  employees,
  checkedAttendeesCount,
  fetchOutlookData,
  onVisitorTypeChange,
  onLocationIdChange,
  onCheckboxChange,
  onInvitationChange,
  onEmployeeChange,
  onFieldChange,
  onCopyField,
  appointmentData,
  getStepsForVisitorType,
  onSubmit,
  submitError,
}) {
  const { translate, translateEntity } = useLanguageContext();
  const theme = useTheme(); 
  
  return (
    <OverlayLoader groups="outlook_page_fetch">
      <FormContainer
        onSubmit={onSubmit}
        submitError={submitError}
        submitLoadingGroup={"outlook_page_save"}
        header={
          <StyledFlexRow>
            <StyledFlexItem>
              <h2>
                {translate("eva.main.general.data")}
              </h2>
            </StyledFlexItem>
            
            <StyledIconButton onClick={fetchOutlookData}>
              <FontAwesomeIcon icon={faRefresh} />
            </StyledIconButton>
          </StyledFlexRow>
        }
      >
        <InputWrapper
          label={translate("eva.main.entities.location")}
          error={EVAApiHelper.getResponseError("location_id", submitError)}
          margin={`0px 0px ${theme.spacing.normal}`}
        >
          <LocationFormSelect
            value={appointmentData.location_id}
            onChange={onLocationIdChange}
            useNullOption={false}
          />
        </InputWrapper>

        <InputWrapper
          label={translate("eva.main.input.types.date")}
          error={EVAApiHelper.getResponseError("date", submitError)}
          margin={`0px 0px ${theme.spacing.normal}`}
        >
          <StyledInput 
            type="text"
            value={appointmentData.expected_date + " " + appointmentData.expected_time}
            disabled
          />
        </InputWrapper>

        <Checkbox 
          label={translate("eva.main.validation.attributes.send_invite_mail")} 
          value={appointmentData.sendInvitation} 
          onChange={onInvitationChange} 
        />

        <StyledFlexColumn style={{ marginTop: theme.spacing.large }}>
          <InputWrapper
            label={translate("eva.main.validation.attributes.visitors") + ":"}
            style={{ marginTop: theme.spacing.normal }}
          />
          
          <hr style={{ marginBottom: theme.spacing.normal }} />

          {/* Display each attendee and their free fields */}
          {appointmentData.attendees.map((attendee, index) => (
          <StyledFlexColumn key={index}>
            <Checkbox label={attendee.email} value={attendee.checked} onChange={() => onCheckboxChange(index)} />

            {attendee.checked && (
              <>
                <InputWrapper
                  label={translate("eva.main.entities.visitor_type")}
                  error={EVAApiHelper.getResponseError("visitor_type_id", submitError)}
                  required
                >
                  <Select 
                    options={visitorTypes.map((visitorType) => ({ value: visitorType.id, label: translateEntity(visitorType, "title")}))}
                    nullable={false}
                    value={attendee.visitor_type_id}
                    onChange={(value) => onVisitorTypeChange(value, index)}
                  />
                </InputWrapper>
                
                {attendee.visitor_type_id && (
                <>
                  {getStepsForVisitorType(attendee.visitor_type_id).some(
                    (step) => step.type === "employee"
                  ) && (
                    <InputWrapper
                      label={translate("eva.main.entities.contact")}
                      error={EVAApiHelper.getResponseError("employee_id", submitError)}
                      required
                    >
                      <Select
                        options={employees.map((employee) => ({ value: employee.id, label: employee.name }))}
                        nullable={false}
                        value={attendee.employee_id}
                        onChange={(value) =>
                          onEmployeeChange(value, index)
                        }
                      />
                    </InputWrapper>
                  )}

                  {getStepsForVisitorType(attendee.visitor_type_id).map(
                    (step, stepIndex) => (
                      <OutlookFields
                        key={stepIndex}
                        step={step}
                        data={attendee}
                        setData={(newData) => onFieldChange(newData, index)}
                        onCopyField={onCopyField}
                        errors={submitError}
                        showCopyButtons={checkedAttendeesCount > 1}
                      />
                    )
                  )}
                </>
                )}
              </>
            )}

            <hr style={{ marginBottom: theme.spacing.normal, marginTop: theme.spacing.normal }} />
          </StyledFlexColumn>
          ))}

        </StyledFlexColumn>

      </FormContainer>  
    </OverlayLoader>
  );
}

export default OutlookPageView;