import styled from "styled-components";

export const StyledNode = styled.div`
  min-width: 200px;
  padding: 10px;
  background-color: ${(props) => props.theme.colors.primary.lightest};
  border-radius: ${(props) => props.theme.radius.normal};
  border: 1px solid
    ${(props) =>
      props.active
        ? props.theme.colors.accent.normal
        : props.theme.colors.on.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: ${(props) => (props.onClick ? "auto" : "none")};

  &:hover {
    border-color: ${(props) => props.theme.colors.accent.dark};
  }
`;
