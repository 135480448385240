import { useTheme } from "styled-components";
import FilePreview from "../../../../../../components/filePreview/FilePreview";
import Checkbox from "../../../../../../components/input/checkbox/Checkbox";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import { StyledFlexColumn } from "../../../../../../styles/LayoutStyles";

function HouserulesStepView({
  step,
  read,
  houseruleData,
  onRead,
  onAcceptChange,
}) {
  const theme = useTheme();
  const { translateEntity, translate } = useLanguageContext();

  return (
    <StyledFlexColumn>
      <FilePreview
        path={translateEntity(step.file, "path")}
        onCloseCallback={onRead}
      >
        <p>
          {translate(
            "eva.frontoffice.pages.visitor_type.steps.houserule.accept_question",
            [
              {
                key: "houserules",
                value: (
                  <span
                    style={{
                      fontWeight: "bold",
                      color: theme["settings.button.color.primary.background"],
                    }}
                  >
                    {translate(
                      "eva.frontoffice.pages.visitor_type.steps.houserule.label"
                    ).toLowerCase()}
                  </span>
                ),
              },
            ]
          )}
        </p>
      </FilePreview>

      <Checkbox
        value={houseruleData}
        disabled={!read}
        label={translate(
          "eva.frontoffice.pages.visitor_type.steps.houserule.accept"
        )}
        onChange={onAcceptChange}
      />
    </StyledFlexColumn>
  );
}

export default HouserulesStepView;
