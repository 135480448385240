import FileHelper from "../../../helpers/FileHelper";
import { StyledImg, StyledScreensaver } from "./ScreensaverStyles";

function ScreensaverView({
  data,
  fadeTime,
  currentScreensaver,
  previousScreensaver,
  onScreensaverClick,
}) {
  return (
    <StyledScreensaver
      disabled={data.disabled || (!currentScreensaver && !previousScreensaver)}
      $fadeTime={fadeTime}
      onClick={onScreensaverClick}
    >
      {previousScreensaver && (
        <StyledImg
          key={previousScreensaver.path}
          src={FileHelper.apiPath(previousScreensaver.path)}
          alt={previousScreensaver.name}
        />
      )}

      {currentScreensaver && (
        <StyledImg
          key={currentScreensaver.path}
          src={FileHelper.apiPath(currentScreensaver.path)}
          alt={currentScreensaver.name}
          $fadeTime={fadeTime}
        />
      )}
    </StyledScreensaver>
  );
}

export default ScreensaverView;
