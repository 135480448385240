import { useTheme } from "styled-components";
import { StyledFlexColumn } from "../../../styles/LayoutStyles";
import { StyledButton } from "../../../styles/ButtonStyles";
import { StyledInput } from "../../../styles/InputStyles.js";
import { useLanguageContext } from "../../../context/LanguageContext";
import InputWrapper from "../../input/wrapper/InputWrapper.js";
import LocationFormSelect from "../../select/custom/location/form/LocationFormSelect.js";
import EVAApiHelper from "../../../helpers/EVAApiHelper.js";
import Select from "../../select/main/Select.js";
import HiddenLoader from "../../loader/HiddenLoader/HiddenLoader.js";
import { StyledInputError } from "../../input/wrapper/InputWrapperStyles.js";
import Modal from "../../modal/Modal.js";

function CalamityActivationFormView({
  onClose,
  calamityData,
  calamity,
  types,
  tools,
  onLocationIdChange,
  onTypeChange,
  onToolChange,
  onCommentChange,
  onSubmit,
  submitError,
}) {
  const theme = useTheme();
  const { translate, translateEntity } = useLanguageContext();

  return (
    <Modal
      title={translate("eva.main.entities.calamity")}
      onClose={onClose}
      loadingGroups="calamity_activation_form_fetch"
    >
      <StyledFlexColumn style={{ marginBottom: theme.spacing.large }}>
        {/* Select the calamity location */}
        <InputWrapper
          label={translate("eva.main.validation.attributes.location_id")}
          error={EVAApiHelper.getResponseError("location_id", submitError)}
          required
        >
          <LocationFormSelect
            value={calamityData.location_id}
            onChange={onLocationIdChange}
            disabled={calamity !== undefined}
            useNullOption={false}
          />
        </InputWrapper>

        {/* Select the calamity type */}
        {types.length > 0 && (
          <InputWrapper
            label={translate("eva.main.validation.attributes.calamity_type_id")}
            error={EVAApiHelper.getResponseError("type", submitError)}
          >
            <Select
              value={calamityData.calamity_type_id}
              options={types
                .sort((a, b) => a.order - b.order)
                .map((type) => {
                  return {
                    value: type.id,
                    label: translateEntity(type, "label"),
                  };
                })}
              sort={false}
              onChange={onTypeChange}
            />
          </InputWrapper>
        )}

        {/* Select the calamity tool */}
        {tools.length > 0 && (
          <InputWrapper
            label={translate("eva.main.validation.attributes.calamity_tool_id")}
            error={EVAApiHelper.getResponseError("tool", submitError)}
          >
            <Select
              value={calamityData.calamity_tool_id}
              options={tools
                .sort((a, b) => a.order - b.order)
                .map((tool) => {
                  return {
                    value: tool.id,
                    label: translateEntity(tool, "label"),
                  };
                })}
              sort={false}
              onChange={onToolChange}
            />
          </InputWrapper>
        )}

        <InputWrapper
          label={translate("eva.main.validation.attributes.comment")}
          error={EVAApiHelper.getResponseError("comment", submitError)}
        >
          <StyledInput
            as={"textarea"}
            placeholder={translate("eva.main.validation.attributes.comment")}
            value={calamityData.comment}
            onChange={onCommentChange}
          />
        </InputWrapper>
      </StyledFlexColumn>

      <StyledFlexColumn>
        <HiddenLoader groups="calamity_activation_form_submit">
          <StyledButton onClick={onSubmit}>
            {translate("eva.main.general.activate")}
          </StyledButton>
        </HiddenLoader>

        <StyledInputError>
          {EVAApiHelper.getResponseError("message", submitError)}
        </StyledInputError>
      </StyledFlexColumn>
    </Modal>
  );
}

export default CalamityActivationFormView;
