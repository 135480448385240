import {
  StyledAltButton,
  StyledTextButton,
} from "../../../../../../styles/ButtonStyles";
import {
  StyledFlexColumn,
  StyledFlexRow,
} from "../../../../../../styles/LayoutStyles";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import Draggable from "../../../../../../components/draggable/Draggable";
import { StyledField, StyledHeader, StyledRow } from "./FieldsFormStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "styled-components";
import Select from "../../../../../../components/select/main/Select";
import MultiSelect from "../../../../../../components/select/main/multi/MultiSelect";
import { StyledInputError } from "../../../../../../components/input/wrapper/InputWrapperStyles";
import EVAApiHelper from "../../../../../../helpers/EVAApiHelper";
import CompactError from "../../../../../../components/error/compact/CompactError";
import InputWrapper from "../../../../../../components/input/wrapper/InputWrapper";

function FieldsFormView({
  relations,
  selectedStep,
  selectedStepIndex,
  onAddRow,
  onDeleteRow,
  fieldOptions,
  onAddField,
  onDeleteField,
  onFieldOptionsChange,
  onDragEnter,
  submitError,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  return (
    <StyledFlexColumn style={{ gap: theme.spacing.large }}>
      {/* Rows */}
      {selectedStep.fields?.map((row, rowIndex) => (
        <Draggable
          key={rowIndex}
          id={`FIELD_${rowIndex}`}
          onTargetEnter={onDragEnter}
        >
          <StyledRow>
            <StyledFlexColumn>
              {/* Row header */}
              <StyledFlexRow
                style={{
                  justifyContent: "space-between",
                  flexWrap: "nowrap",
                }}
              >
                <StyledFlexRow style={{ flexWrap: "nowrap" }}>
                  <StyledHeader>{`${translate("eva.main.general.row")} ${
                    rowIndex + 1
                  }`}</StyledHeader>

                  <CompactError
                    error={EVAApiHelper.getResponseError(
                      `steps.${selectedStepIndex}.fields.${rowIndex}`
                    )}
                  />
                </StyledFlexRow>

                <StyledFlexRow>
                  {/* Row delete button */}
                  <StyledTextButton
                    onClick={() => onDeleteRow(rowIndex)}
                    title={translate("eva.main.entities.states.delete", [
                      {
                        key: "entities",
                        value: translate("eva.main.general.row"),
                      },
                    ])}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </StyledTextButton>

                  {/* Add Field Select */}
                  <Select
                    title={translate("eva.main.entities.states.create", [
                      {
                        key: "entities",
                        value: translate("eva.main.entities.field"),
                      },
                    ])}
                    as={StyledTextButton}
                    hideCaret={true}
                    valueVisual={(value) => <FontAwesomeIcon icon={faPlus} />}
                    onChange={(value) => onAddField(rowIndex, value)}
                    options={relations?.fields?.map((field) => ({
                      value: field.id,
                      label: field.key,
                    }))}
                  />
                </StyledFlexRow>
              </StyledFlexRow>

              <hr />

              {/* Row fields */}
              <StyledFlexColumn>
                {row.map((field, fieldIndex) => {
                  const fieldRecord = relations?.fields?.find(
                    (f) => f.id === field.field_id
                  );

                  return (
                    <Draggable
                      key={`${rowIndex}_${fieldIndex}`}
                      id={`FIELD_${rowIndex},${fieldIndex}`}
                      onTargetEnter={onDragEnter}
                    >
                      <StyledField key={fieldIndex}>
                        <StyledFlexColumn>
                          {/* Field header */}
                          <StyledFlexRow
                            style={{
                              justifyContent: "space-between",
                              flexWrap: "nowrap",
                            }}
                          >
                            <StyledHeader>{fieldRecord?.key}</StyledHeader>

                            <StyledFlexRow>
                              {/* Field delete */}
                              <StyledTextButton
                                onClick={() =>
                                  onDeleteField(rowIndex, fieldIndex)
                                }
                                title={translate(
                                  "eva.main.entities.states.delete",
                                  [
                                    {
                                      key: "entities",
                                      value: translate(
                                        "eva.main.entities.field"
                                      ),
                                    },
                                  ]
                                )}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </StyledTextButton>
                            </StyledFlexRow>
                          </StyledFlexRow>

                          <hr />

                          <InputWrapper
                            error={EVAApiHelper.getResponseErrorsLike(
                              `steps.${selectedStepIndex}.fields.${rowIndex}.${fieldIndex}.`,
                              submitError,
                              translate
                            )}
                          >
                            <MultiSelect
                              value={field.options}
                              onChange={(values) =>
                                onFieldOptionsChange(
                                  rowIndex,
                                  fieldIndex,
                                  values
                                )
                              }
                              options={fieldOptions.map((option) => ({
                                value: option,
                                label: translate(
                                  `eva.main.validation.attributes.steps.*.fields.*.*.${option}`
                                ),
                              }))}
                              placeholder={translate(
                                "eva.main.validation.attributes.options"
                              )}
                            />
                          </InputWrapper>
                        </StyledFlexColumn>
                      </StyledField>
                    </Draggable>
                  );
                })}
              </StyledFlexColumn>
            </StyledFlexColumn>
          </StyledRow>
        </Draggable>
      ))}

      {/* Row add button */}
      <StyledFlexColumn>
        <StyledAltButton onClick={onAddRow}>
          {translate("eva.main.entities.states.create", [
            { key: "entities", value: translate("eva.main.general.row") },
          ])}
        </StyledAltButton>

        <StyledInputError>
          {EVAApiHelper.getResponseError(
            `steps.${selectedStepIndex}.fields`,
            submitError
          )}
        </StyledInputError>
      </StyledFlexColumn>
    </StyledFlexColumn>
  );
}

export default FieldsFormView;
