import ZoomPanEditorView from "./ZoomPanEditorView";

function ZoomPanEditor({ controller, children, customControls }) {
  return (
    <ZoomPanEditorView controller={controller} customControls={customControls}>
      {children}
    </ZoomPanEditorView>
  );
}

export default ZoomPanEditor;
