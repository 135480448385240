import { useLanguageContext } from "../../../../context/LanguageContext";
import { StyledInput } from "../../../../styles/InputStyles";
import Select from "../../../select/main/Select";

function FlowFieldInputView({ flowField, value, onChange }) {
  const { translate, translateEntity } = useLanguageContext();

  switch (flowField.field.type) {
    case "text":
    case "date":
    case "time":
      return (
        <StyledInput
          value={value ?? ""}
          onChange={(e) => onChange(flowField, e.target.value)}
          type={flowField.field.type}
          placeholder={translateEntity(flowField.field, "label")}
        />
      );
    case "select":
    case "boolean":
      return (
        <Select
          value={value}
          nullable={true}
          onChange={(value) => onChange(flowField, value)}
          options={
            flowField.field.type === "boolean"
              ? [
                  { value: "1", label: translate("eva.main.general.yes") },
                  { value: "0", label: translate("eva.main.general.no") },
                ]
              : flowField.field.options.map((option) => ({
                  value: option.id,
                  label: translateEntity(option, "label"),
                }))
          }
        />
      );
    default:
      return null;
  }
}

export default FlowFieldInputView;
