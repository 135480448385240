import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  StyledCheckbox,
  StyledCheckboxContainer,
  StyledLabel,
} from "./CheckboxStyles";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

function CheckboxView({ value, onChange, disabled, label }) {
  return (
    <StyledCheckboxContainer
      disabled={disabled}
      onClick={() => onChange(!value)}
    >
      <StyledCheckbox>
        <FontAwesomeIcon icon={faCheck} style={{ opacity: value ? 1 : 0 }} />
      </StyledCheckbox>

      <StyledLabel>{label}</StyledLabel>
    </StyledCheckboxContainer>
  );
}

export default CheckboxView;
