import { StyledFlexColumn, StyledFlexRow } from "../../../styles/LayoutStyles";
import Chart from "./chart/Chart";
import Form from "./form/Form";
import SelectedForm from "./form/selected/SelectedForm";

function FlowPageView({
  relations,
  flowData,
  setFlowData,
  submitError,
  setSubmitError,
  availableSteps,
}) {
  return (
    <StyledFlexRow
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <Chart
        flowData={flowData}
        setFlowData={setFlowData}
        availableSteps={availableSteps}
        submitError={submitError}
        setSubmitError={setSubmitError}
      />

      <StyledFlexColumn style={{ width: "400px", height: "100%" }}>
        <Form
          flowData={flowData}
          setFlowData={setFlowData}
          submitError={submitError}
          setSubmitError={setSubmitError}
        />

        <SelectedForm
          relations={relations}
          flowData={flowData}
          setFlowData={setFlowData}
          submitError={submitError}
          setSubmitError={setSubmitError}
        />
      </StyledFlexColumn>
    </StyledFlexRow>
  );
}

export default FlowPageView;
