import FormContainer from "../../../../components/formContainer/FormContainer";
import InputWrapper from "../../../../components/input/wrapper/InputWrapper";
import { StyledInputError } from "../../../../components/input/wrapper/InputWrapperStyles";
import OverlayLoader from "../../../../components/loader/OverlayLoader/OverlayLoader";
import { useLanguageContext } from "../../../../context/LanguageContext";
import EVAApiHelper from "../../../../helpers/EVAApiHelper";
import { StyledInput } from "../../../../styles/InputStyles";
import { StyledFlexColumn } from "../../../../styles/LayoutStyles";

function FormView({ flowData, onNameChange, onCancel, onSubmit, submitError }) {
  const { translate } = useLanguageContext();
  const connectionsError = EVAApiHelper.getResponseError(
    "connections",
    submitError
  );
  const stepsError = EVAApiHelper.getResponseError("steps", submitError);

  return (
    <OverlayLoader groups={"flow_page_fetch"}>
      <FormContainer
        onCancel={onCancel}
        onSubmit={onSubmit}
        submitError={submitError}
        submitLoadingGroup={"flow_page_submit"}
      >
        <StyledFlexColumn>
          <InputWrapper
            label={translate("eva.main.validation.attributes.name")}
            required
            error={EVAApiHelper.getResponseError("name", submitError)}
          >
            <StyledInput
              type="text"
              value={flowData.name}
              onChange={onNameChange}
              placeholder={translate("eva.main.validation.attributes.name")}
            />
          </InputWrapper>

          {connectionsError && (
            <StyledInputError>
              {EVAApiHelper.getResponseError("connections", submitError)}
            </StyledInputError>
          )}

          {stepsError && (
            <StyledInputError>
              {EVAApiHelper.getResponseError("steps", submitError)}
            </StyledInputError>
          )}
        </StyledFlexColumn>
      </FormContainer>
    </OverlayLoader>
  );
}

export default FormView;
