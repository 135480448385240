import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import { StyledAltIconButton } from "../../../../../styles/ButtonStyles";
import {
  StyledCard,
  StyledCardBody,
  StyledCardHeader,
} from "../../../../../styles/CardStyles";
import {
  StyledFlexColumn,
  StyledFlexRow,
} from "../../../../../styles/LayoutStyles";
import { faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "styled-components";
import { getSelectedFormConfig } from "./SelectedFormContent";
import OverlayLoader from "../../../../../components/loader/OverlayLoader/OverlayLoader";
import Select from "../../../../../components/select/main/Select";
import InputWrapper from "../../../../../components/input/wrapper/InputWrapper";

function SelectedFormView({
  relations,
  selectedStep,
  selectedStepIndex,
  selectedConnection,
  selectedConnectionIndex,
  setFlowData,
  onDelete,
  onClose,
  onForceShowChange,
  submitError,
  setSubmitError,
}) {
  const { translate } = useLanguageContext();
  const theme = useTheme();
  const selectedFormConfig = getSelectedFormConfig(
    selectedStep,
    selectedConnection
  );
  const Form = selectedFormConfig?.form;
  const removeable = selectedFormConfig?.removeable ?? true;
  const hasForceShowInput = selectedFormConfig?.hasForceShowInput ?? false;

  return (
    (selectedStep || selectedConnection) && (
      <OverlayLoader
        groups={"flow_page_fetch"}
        containerStyle={{ flex: 1, overflow: "hidden" }}
      >
        <StyledCard style={{ height: "100%" }}>
          <StyledFlexColumn style={{ gap: 0, height: "100%" }}>
            <StyledCardHeader>
              <StyledFlexRow
                style={{ justifyContent: "space-between", flexWrap: "nowrap" }}
              >
                <h2>
                  {selectedStep &&
                    translate(`eva.main.flow.step.types.${selectedStep.type}`)}
                  {selectedConnection &&
                    translate("eva.main.entities.flow_connection")}
                </h2>

                <StyledFlexRow
                  style={{ flexWrap: "nowrap", gap: theme.spacing.small }}
                >
                  {removeable && (
                    <StyledAltIconButton
                      onClick={onDelete}
                      title={translate("eva.main.entities.states.delete", [
                        { key: "entities", value: "" },
                      ])}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </StyledAltIconButton>
                  )}

                  <StyledAltIconButton
                    onClick={onClose}
                    title={translate("eva.main.general.close")}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </StyledAltIconButton>
                </StyledFlexRow>
              </StyledFlexRow>
            </StyledCardHeader>

            <StyledCardBody style={{ overflowY: "auto", flex: 1 }}>
              <StyledFlexColumn style={{ gap: theme.spacing.large }}>
                {hasForceShowInput && (
                  <InputWrapper
                    label={translate(
                      "eva.main.validation.attributes.steps.*.force_show"
                    )}
                  >
                    <Select
                      value={selectedStep.force_show ?? false}
                      options={[
                        {
                          value: true,
                          label: translate("eva.main.general.yes"),
                        },
                        {
                          value: false,
                          label: translate("eva.main.general.no"),
                        },
                      ]}
                      onChange={onForceShowChange}
                    />
                  </InputWrapper>
                )}

                {Form && (
                  <Form
                    relations={relations}
                    selectedStep={selectedStep}
                    selectedStepIndex={selectedStepIndex}
                    selectedConnection={selectedConnection}
                    selectedConnectionIndex={selectedConnectionIndex}
                    setFlowData={setFlowData}
                    submitError={submitError}
                    setSubmitError={setSubmitError}
                  />
                )}

                {!Form && !hasForceShowInput && (
                  <p>{translate("eva.main.flow.messages.no_settings")}</p>
                )}
              </StyledFlexColumn>
            </StyledCardBody>
          </StyledFlexColumn>
        </StyledCard>
      </OverlayLoader>
    )
  );
}

export default SelectedFormView;
