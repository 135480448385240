import { useRef } from "react";
import { useDraggableContext } from "../../../../../../context/DraggableContext";
import MessagesFormView from "./MessagesFormView";

function MessagesForm({
  relations,
  selectedStep,
  selectedStepIndex,
  setFlowData,
  submitError,
  setSubmitError,
}) {
  const draggableContext = useDraggableContext();
  const lastDragSwitchTime = useRef(0);
  const receivers = ["visitor", "employee", "employee_extern"];
  const events = [
    "step_entered",
    "visitor_created",
    "visitor_updated",
    "visitor_deleted",
    "visitor_signed_in",
    "visitor_signed_out",
  ];
  const types = ["mail", "screen"];

  /**
   * This method will handle the add message event.
   * @param {int} messageTemplateId
   */
  function onAddMessage(messageTemplateId) {
    setSubmitError(null);
    setFlowData((prev) => ({
      ...prev,
      steps: prev.steps.map((step) => {
        if (step.id === selectedStep.id) {
          const newMessage = {
            message_template_id: messageTemplateId,
            type: "screen",
            receiver: null,
            event: null,
            employee_id: null,
          };

          return {
            ...step,
            messages: step.messages
              ? [...step.messages, newMessage]
              : [newMessage],
          };
        }

        return step;
      }),
    }));
  }

  /**
   * This method will handle the delete message event.
   * @param {int} index
   */
  function onDeleteMessage(index) {
    setSubmitError(null);
    setFlowData((prev) => ({
      ...prev,
      steps: prev.steps.map((step) => {
        if (step.id === selectedStep.id) {
          const messages = [...step.messages];
          messages.splice(index, 1);

          return {
            ...step,
            messages,
          };
        }

        return step;
      }),
    }));
  }

  /**
   * This method will handle the message data change event.
   * @param {int} messageIndex
   * @param {function(object) : object} values
   */
  function onMessageDataChange(messageIndex, values) {
    setFlowData((prev) => ({
      ...prev,
      steps: prev.steps.map((step) => {
        if (step.id === selectedStep.id) {
          const messages = [...step.messages];
          messages[messageIndex] = {
            ...messages[messageIndex],
            ...values(messages[messageIndex]),
          };

          return {
            ...step,
            messages,
          };
        }

        return step;
      }),
    }));
  }

  /**
   * This method will handle the message type change event.
   * @param {int} messageIndex
   * @param {string} value
   */
  function onMessageTypeChange(messageIndex, value) {
    onMessageDataChange(messageIndex, (prev) => ({
      type: value,
      receiver: value === "screen" ? null : prev.receiver,
      event: value === "screen" ? null : prev.event,
      employee_id: value === "screen" ? null : prev.employee_id,
    }));
  }

  /**
   * This method will handle the message receiver change event.
   * @param {int} messageIndex
   * @param {string} value
   */
  function onMessageReceiverChange(messageIndex, value) {
    onMessageDataChange(messageIndex, (prev) => ({
      receiver: value,
      employee_id: value !== "employee_extern" ? null : prev.employee_id,
    }));
  }

  /**
   * This method will handle the message employee change event.
   * @param {int} messageIndex
   * @param {int} value
   */
  function onMessageEmployeeChange(messageIndex, value) {
    onMessageDataChange(messageIndex, (prev) => ({ employee_id: value }));
  }

  /**
   * This method will handle the message event change event.
   * @param {int} messageIndex
   * @param {string} value
   */
  function onMessageEventChange(messageIndex, value) {
    onMessageDataChange(messageIndex, (prev) => ({ event: value }));
  }

  /**
   * This method will handle the drag enter event.
   * @param {string} dragged
   * @param {string} target
   */
  function onDragEnter(dragged, target) {
    //Dont do anything if the last drag switch was less than 100ms ago.
    if (Date.now() - lastDragSwitchTime.current < 100) {
      return;
    }

    setSubmitError(null);

    //Get the coords of the dragged and target elements.
    const draggedIndex = dragged.split("_")[2];
    const targetIndex = target.split("_")[2];

    //Re-insert the dragged element to the target position.
    setFlowData((prev) => ({
      ...prev,
      steps: prev.steps.map((step) => {
        if (step.id === selectedStep.id) {
          const messages = [...step.messages];

          const draggedMessage = messages[draggedIndex];
          messages.splice(draggedIndex, 1);
          messages.splice(targetIndex, 0, draggedMessage);

          return {
            ...step,
            messages,
          };
        }

        return step;
      }),
    }));

    //Make sure to set the dragged id to the target id.
    draggableContext.updateDragged(target);

    //Update the last drag switch time.
    lastDragSwitchTime.current = Date.now();
  }

  return (
    <MessagesFormView
      relations={relations}
      selectedStep={selectedStep}
      selectedStepIndex={selectedStepIndex}
      receivers={receivers}
      events={events}
      types={types}
      onAddMessage={onAddMessage}
      onDeleteMessage={onDeleteMessage}
      onMessageTypeChange={onMessageTypeChange}
      onMessageReceiverChange={onMessageReceiverChange}
      onMessageEmployeeChange={onMessageEmployeeChange}
      onMessageEventChange={onMessageEventChange}
      onDragEnter={onDragEnter}
      submitError={submitError}
    />
  );
}

export default MessagesForm;
