import { useState } from "react";
import { useLanguageContext } from "../../../context/LanguageContext";
import { faCheck, faPen, faXmark } from "@fortawesome/free-solid-svg-icons";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";
import DataTableConfig from "../main/config/dataTable/DataTableConfig";
import useBasicColumnConfig from "../main/config/column/default/BasicColumnConfig";
import useTimestampColumnConfig from "../main/config/column/default/TimestampColumnConfig";
import RowActionConfig from "../main/config/rowAction/RowActionConfig";
import DataTable, { useDataTable } from "../main/DataTable";
import Select from "../../select/main/Select";

function AvailablePermissionsDataTable() {
  const callEvaApi = useEvaApi();
  const { translate } = useLanguageContext();
  const [editRow, setEditRow] = useState(null);

  //Create the datatable config
  const config = new DataTableConfig("available_permissions");

  //Set the datatable fetch info
  config.setFetchRequest("available_permissions");
  config.setFetchParams({
    with_available_permission_permission_data: true,
  });

  //Set the datatable order by
  config.setOrderBy("permission.name.translation");
  config.setOrderDirection("asc");

  //set the datatable columns
  config.setColumns([
    useBasicColumnConfig("permission.name.translation")
      .setData((row) =>
        translate(`eva.main.permissions.${row.permission?.name}`)
      )
      .setLockActive(true),
    useBasicColumnConfig("enabled")
      .setData(getValueColumnData)
      .setLockActive(true)
      .setSearchable(false)
      .setOrderable(true),
    useTimestampColumnConfig("updated_at"),
  ]);

  //Set the datatable row actions
  config.setRowActions([
    new RowActionConfig()
      .setIcon(faPen)
      .setCallback(handleEdit)
      .setHidden((row) => row.id === editRow?.id)
      .setTitle(
        translate("eva.main.entities.states.edit", [
          { key: "entities", value: translate("eva.main.entities.parameter") },
        ])
      ),
    new RowActionConfig()
      .setIcon(faXmark)
      .setCallback(handleCancel)
      .setHidden((row) => row.id !== editRow?.id)
      .setTitle(translate("eva.main.general.cancel")),
    new RowActionConfig()
      .setIcon((row) => faCheck)
      .setCallback(handleConfirm)
      .setHidden((row) => row.id !== editRow?.id)
      .setTitle(translate("eva.main.general.save")),
  ]);

  //Set the overall loading groups for the datatable row actions
  config.setRowActionsLoadingGroups(
    (row) => `${config.getDataTableKey()}_update_${row.id}`
  );

  //Create the datatable object
  const dataTable = useDataTable(config);

  /**
   * This method will return the data representation of the value column
   * @param {object} row
   * @returns {string|any}
   */
  function getValueColumnData(row) {
    if (row.id !== editRow?.id) {
      return translate(`eva.main.general.${row.enabled ? "yes" : "no"}`);
    } else {
      return getValueColumnEditData(row);
    }
  }

  /**
   * This method will return the data representation of the value column in edit mode
   * @param {object} row
   * @returns {any}
   */
  function getValueColumnEditData(row) {
    return (
      <Select
        options={[
          { value: true, label: translate("eva.main.general.yes") },
          { value: false, label: translate("eva.main.general.no") },
        ]}
        value={editRow.enabled}
        onChange={(value) => setEditRow({ ...editRow, enabled: value })}
      />
    );
  }

  /**
   * This method will start the edit state of the given row
   * @param {object} row
   */
  function handleEdit(row) {
    setEditRow(row);
  }

  /**
   * This method will cancel the update of the given row
   */
  function handleCancel() {
    setEditRow(null);
  }

  /**
   * This method will send a request to the API for updating the given row
   * @param {object} row
   */
  async function handleConfirm(row) {
    if (editRow.enabled === row.enabled) {
      setEditRow(null);
      return;
    }

    await callEvaApi(
      new EvaApiCall(`available_permissions/${row.id}`)
        .setMethod("PUT")
        .setData({ enabled: editRow.enabled })
        .setLoadingGroup(`${config.getDataTableKey()}_update_${row.id}`)
        .setOnSuccess((response) => {
          row.enabled = response.data.data.enabled;
          setEditRow(null);
        })
    );
  }

  return <DataTable controller={dataTable} />;
}

export default AvailablePermissionsDataTable;
