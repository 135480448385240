import HouserulesFormView from "./HouserulesFormView";

function HouserulesForm({
  relations,
  selectedStep,
  selectedStepIndex,
  setFlowData,
  submitError,
}) {
  /**
   * This method will handle the on houserules change event
   * @param {string} fileId
   */
  function onHouserulesChange(fileId) {
    setFlowData((prev) => ({
      ...prev,
      steps: prev.steps.map((step) => {
        if (step.id === selectedStep.id) {
          return {
            ...step,
            file_id: fileId,
          };
        }

        return step;
      }),
    }));
  }

  return (
    <HouserulesFormView
      relations={relations}
      selectedStep={selectedStep}
      selectedStepIndex={selectedStepIndex}
      onHouserulesChange={onHouserulesChange}
      submitError={submitError}
    />
  );
}

export default HouserulesForm;
