import { useTheme } from "styled-components";
import InputWrapper from "../../../../../../components/input/wrapper/InputWrapper";
import Select from "../../../../../../components/select/main/Select";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import {
  StyledAltButton,
  StyledTextButton,
} from "../../../../../../styles/ButtonStyles";
import {
  StyledFlexColumn,
  StyledFlexRow,
} from "../../../../../../styles/LayoutStyles";
import { StyledHeader, StyledMessage } from "./MessagesFormStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Draggable from "../../../../../../components/draggable/Draggable";
import CompactError from "../../../../../../components/error/compact/CompactError";
import EVAApiHelper from "../../../../../../helpers/EVAApiHelper";
import { StyledInputError } from "../../../../../../components/input/wrapper/InputWrapperStyles";

function MessagesFormView({
  relations,
  selectedStep,
  selectedStepIndex,
  receivers,
  events,
  types,
  onAddMessage,
  onDeleteMessage,
  onMessageReceiverChange,
  onMessageEventChange,
  onMessageTypeChange,
  onMessageEmployeeChange,
  onDragEnter,
  submitError,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  return (
    <StyledFlexColumn style={{ gap: theme.spacing.large }}>
      {/* Messages */}
      {selectedStep.messages?.map((message, messageIndex) => {
        const messageTemplate = relations?.message_templates?.find(
          (mt) => mt.id === message.message_template_id
        );

        return (
          <Draggable
            key={messageIndex}
            id={"MESSAGE_" + messageIndex}
            onTargetEnter={onDragEnter}
          >
            <StyledMessage>
              <StyledFlexColumn>
                {/* Message header */}
                <StyledFlexRow
                  style={{
                    justifyContent: "space-between",
                    flexWrap: "nowrap",
                  }}
                >
                  <StyledFlexRow style={{ flexWrap: "nowrap" }}>
                    <StyledHeader>{messageTemplate?.name}</StyledHeader>

                    <CompactError
                      error={EVAApiHelper.getResponseError(
                        `steps.${selectedStepIndex}.messages.${messageIndex}.message_template_id`,
                        submitError
                      )}
                    />
                  </StyledFlexRow>

                  {/* Message delete */}
                  <StyledTextButton
                    onClick={() => onDeleteMessage(messageIndex)}
                    title={translate("eva.main.entities.states.delete", [
                      {
                        key: "entities",
                        value: translate("eva.main.entities.flow_step_message"),
                      },
                    ])}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </StyledTextButton>
                </StyledFlexRow>

                <hr />

                {/* Message type */}
                <InputWrapper
                  label={translate(
                    "eva.main.validation.attributes.steps.*.messages.*.type"
                  )}
                  required
                  error={EVAApiHelper.getResponseError(
                    `steps.${selectedStepIndex}.messages.${messageIndex}.type`,
                    submitError
                  )}
                >
                  <Select
                    value={message.type}
                    onChange={(value) =>
                      onMessageTypeChange(messageIndex, value)
                    }
                    options={types.map((t) => ({
                      value: t,
                      label: translate(
                        `eva.main.flow.step.messages.types.${t}`
                      ),
                    }))}
                  />
                </InputWrapper>

                {/* Message receiver */}
                {message.type !== "screen" && (
                  <InputWrapper
                    label={translate(
                      "eva.main.validation.attributes.steps.*.messages.*.receiver"
                    )}
                    required
                    error={EVAApiHelper.getResponseError(
                      `steps.${selectedStepIndex}.messages.${messageIndex}.receiver`,
                      submitError
                    )}
                  >
                    <Select
                      value={message.receiver}
                      onChange={(value) =>
                        onMessageReceiverChange(messageIndex, value)
                      }
                      options={receivers.map((r) => ({
                        value: r,
                        label: translate(
                          `eva.main.flow.step.messages.receivers.${r}`
                        ),
                      }))}
                    />
                  </InputWrapper>
                )}

                {/* Message employee */}
                {message.receiver === "employee_extern" && (
                  <InputWrapper
                    label={translate(
                      "eva.main.validation.attributes.steps.*.messages.*.employee_id"
                    )}
                    required
                    error={EVAApiHelper.getResponseError(
                      `steps.${selectedStepIndex}.messages.${messageIndex}.employee_id`,
                      submitError
                    )}
                  >
                    <Select
                      value={message.employee_id}
                      onChange={(value) =>
                        onMessageEmployeeChange(messageIndex, value)
                      }
                      options={relations?.employees?.map((employee) => ({
                        value: employee.id,
                        label: employee.name,
                      }))}
                    />
                  </InputWrapper>
                )}

                {/* Message event */}
                {message.type !== "screen" && (
                  <InputWrapper
                    label={translate(
                      "eva.main.validation.attributes.steps.*.messages.*.event"
                    )}
                    required
                    error={EVAApiHelper.getResponseError(
                      `steps.${selectedStepIndex}.messages.${messageIndex}.event`,
                      submitError
                    )}
                  >
                    <Select
                      value={message.event}
                      onChange={(value) =>
                        onMessageEventChange(messageIndex, value)
                      }
                      options={events.map((e) => ({
                        value: e,
                        label: translate(
                          `eva.main.flow.step.messages.events.${e}`
                        ),
                      }))}
                    />
                  </InputWrapper>
                )}
              </StyledFlexColumn>
            </StyledMessage>
          </Draggable>
        );
      })}

      <StyledFlexColumn>
        <Select
          as={StyledAltButton}
          hideCaret={true}
          valueVisual={(val) =>
            translate(`eva.main.entities.states.create`, [
              {
                key: "entities",
                value: translate("eva.main.entities.flow_step_message"),
              },
            ])
          }
          options={relations?.message_templates?.map((mt) => ({
            value: mt.id,
            label: mt.name,
          }))}
          onChange={onAddMessage}
        />

        <StyledInputError>
          {EVAApiHelper.getResponseError(
            `steps.${selectedStepIndex}.messages`,
            submitError
          )}
        </StyledInputError>
      </StyledFlexColumn>
    </StyledFlexColumn>
  );
}

export default MessagesFormView;
