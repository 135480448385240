function ConnectorView({
  id,
  connectorRef,
  position,
  active,
  disabled,
  onClick,
  Element,
}) {
  return (
    <Element
      id={id}
      ref={connectorRef}
      position={position}
      active={active}
      onClick={onClick}
      disabled={disabled}
    />
  );
}

export default ConnectorView;
