import OutlookFieldsView from "./OutlookFieldsView";

function OutlookFields({
  step,
  data = {},
  setData = () => {},
  errors,
  onCopyField,
  showCopyButtons,
}) {
  /**
   * This method will handle the field change event
   * @param {object} stepField
   * @param {any} value
   */
  function onFieldChange(stepField, value) {
    setData({
      ...data,
      [`field_${stepField.field.id}`]: value,
    });
  }

  return (
    <OutlookFieldsView 
      step={step}
      data={data}
      errors={errors}
      onFieldChange={onFieldChange}
      onCopyField={onCopyField}
      showCopyButtons={showCopyButtons}
    />
  );
}

export default OutlookFields;