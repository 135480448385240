import FlowFieldInput from "../../../../../../components/input/flow/field/FlowFieldInput";
import InputWrapper from "../../../../../../components/input/wrapper/InputWrapper";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import EVAApiHelper from "../../../../../../helpers/EVAApiHelper";
import {
  StyledFlexColumn,
  StyledFlexItem,
  StyledFlexRow,
} from "../../../../../../styles/LayoutStyles";

function FieldsStepView({ step, errors, visitorData, onFieldChange }) {
  const { translateEntity } = useLanguageContext();

  return (
    <StyledFlexColumn>
      {step.fields.map((row, index) => (
        <StyledFlexRow key={index}>
          {row.map((stepField) => (
            <StyledFlexItem key={stepField.id}>
              <InputWrapper
                label={translateEntity(stepField.field, "label")}
                required={stepField.required_terminal}
                error={EVAApiHelper.getResponseError(
                  `field_${stepField.field.id}`,
                  errors
                )}
              >
                <FlowFieldInput
                  flowField={stepField}
                  value={visitorData[`field_${stepField.field.id}`]}
                  onChange={onFieldChange}
                />
              </InputWrapper>
            </StyledFlexItem>
          ))}
        </StyledFlexRow>
      ))}
    </StyledFlexColumn>
  );
}

export default FieldsStepView;
