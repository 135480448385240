import { Route, Routes } from "react-router-dom";
import useAnnouncementsRouteConfig from "./configs/announcements/AnnouncementsRouteConfig";
import useAnnouncementCreateRouteConfig from "./configs/announcements/AnnouncementCreateRouteConfig";
import useAnnouncementEditRouteConfig from "./configs/announcements/AnnouncementEditRouteConfig";
import useLoginRouteConfig from "./configs/LoginRouteConfig";
import useFrontofficeRouteConfig from "./configs/FrontofficeRouteConfig";
import useDashboardRouteConfig from "./configs/DashboardRouteConfig";
import useUsersRouteConfig from "./configs/users/UsersRouteConfig";
import useUserCreateRouteConfig from "./configs/users/UserCreateRouteConfig";
import useUserEditRouteConfig from "./configs/users/UserEditRouteConfig";
import useContactsRouteConfig from "./configs/contacts/ContactsRouteConfig";
import useContactCreateRouteConfig from "./configs/contacts/ContactCreateRouteConfig";
import useContactEditRouteConfig from "./configs/contacts/ContactEditRouteConfig";
import useRolesRouteConfig from "./configs/roles/RolesRouteConfig";
import useRoleCreateRouteConfig from "./configs/roles/RoleCreateRouteConfig";
import useRoleEditRouteConfig from "./configs/roles/RoleEditRouteConfig";
import useAuditTrailsRouteConfig from "./configs/AuditTrailsRouteConfig";
import useCompaniesRouteConfig from "./configs/companies/CompaniesRouteConfig";
import useCompanyCreateRouteConfig from "./configs/companies/CompanyCreateRouteConfig";
import useCompanyEditRouteConfig from "./configs/companies/CompanyEditRouteConfig";
import useLocationCreateRouteConfig from "./configs/companies/locations/LocationCreateRouteConfig";
import useLocationEditRouteConfig from "./configs/companies/locations/LocationEditRouteConfig";
import useLanguagesRouteConfig from "./configs/languages/LanguagesRouteConfig";
import useLanguageCreateRouteConfig from "./configs/languages/LanguageCreateConfig";
import useLanguageEditRouteConfig from "./configs/languages/LanguageEditConfig";
import useHelpTextsRouteConfig from "./configs/helpTexts/HelpTextsRouteConfig";
import useHelpTextCreateRouteConfig from "./configs/helpTexts/HelpTextCreateRouteConfig";
import useHelpTextEditRouteConfig from "./configs/helpTexts/HelpTextEditRouteConfig";
import useEmployeesRouteConfig from "./configs/employees/EmployeesRouteConfig";
import useEmployeeCreateRouteConfig from "./configs/employees/EmployeeCreateRouteConfig";
import useEmployeeEditRouteConfig from "./configs/employees/EmployeeEditRouteConfig";
import useDepartmentsRouteConfig from "./configs/departments/DepartmentsRouteConfig";
import useDepartmentCreateRouteConfig from "./configs/departments/DepartmentCreateRouteConfig";
import useDepartmentEditRouteConfig from "./configs/departments/DepartmentEditRouteConfig";
import useFilesRouteConfig from "./configs/files/FilesRouteConfig";
import useFileCreateRouteConfig from "./configs/files/FileCreateRouteConfig";
import useFileEditRouteConfig from "./configs/files/FileEditRouteConfig";
import useParametersRouteConfig from "./configs/ParametersRouteConfig";
import useReleaseNotesRouteConfig from "./configs/ReleaseNotesRouteConfig";
import useDishesRouteConfig from "./configs/canteen/dishes/DishesRouteConfig";
import useDishCreateRouteConfig from "./configs/canteen/dishes/DishCreateRouteConfig";
import useDishEditRouteConfig from "./configs/canteen/dishes/DishEditRouteConfig";
import useOrdersRouteConfig from "./configs/canteen/orders/OrdersRouteConfig";
import useOrderCreateRouteConfig from "./configs/canteen/orders/OrderCreateRouteConfig";
import useOrderEditRouteConfig from "./configs/canteen/orders/OrderEditRouteConfig";
import useOrderPrepareRouteConfig from "./configs/canteen/orders/OrderPrepareRouteConfig";
import useOrdersLogRouteConfig from "./configs/canteen/orders/OrdersLogRouteConfig";
import useFieldsRouteConfig from "./configs/fields/FieldsRouteConfig";
import useFieldCreateRouteConfig from "./configs/fields/FieldCreateRouteConfig";
import useFieldEditRouteConfig from "./configs/fields/FieldEditRouteConfig";
import useFieldOptionCreateRouteConfig from "./configs/fields/options/FieldOptionCreateRouteConfig";
import useFieldOptionEditRouteConfig from "./configs/fields/options/FieldOptionEditRouteConfig";
import useQuestionnairesRouteConfig from "./configs/questionnaires/QuestionnairesRouteConfig";
import useQuestionnaireCreateRouteConfig from "./configs/questionnaires/QuestionnaireCreateRouteConfig";
import useQuestionnaireEditRouteConfig from "./configs/questionnaires/QuestionnaireEditRouteConfig";
import useQuestionCreateRouteConfig from "./configs/questionnaires/questions/QuestionCreateRouteConfig";
import useQuestionEditRouteConfig from "./configs/questionnaires/questions/QuestionEditRouteConfig";
import useQuestionAnswerCreateRouteConfig from "./configs/questionnaires/questions/answers/QuestionAnswerCreateRouteConfig";
import useQuestionAnswerEditRouteConfig from "./configs/questionnaires/questions/answers/QuestionAnswerEditRouteConfig";
import useCompanyLanguagesRouteConfig from "./configs/companyLanguages/CompanyLanguagesRouteConfig";
import useCompanyLanguageCreateRouteConfig from "./configs/companyLanguages/CompanyLanguageCreateRouteConfig";
import useCompanyLanguageEditRouteConfig from "./configs/companyLanguages/CompanyLanguageEditRouteConfig";
import useCalamitiesRouteConfig from "./configs/calamities/CalamitiesRouteConfig";
import useCalamitiesLogRouteConfig from "./configs/calamities/CalamitiesLogRouteConfig";
import useMessageTemplatesRouteConfig from "./configs/message_templates/MessageTemplatesRouteConfig";
import useMessageTemplateCreateRouteConfig from "./configs/message_templates/MessageTemplateCreateRouteConfig";
import useMessageTemplateEditRouteConfig from "./configs/message_templates/MessageTemplateEditRouteConfig";
import useNotFoundRouteConfig from "./configs/NotFoundRouteConfig";
import useDashboardLayoutRouteConfig from "./layouts/configs/DashboardLayoutRouteConfig";
import useCalamityToolCreateRouteConfig from "./configs/calamities/tools/CalamityToolCreateRouteConfig";
import useCalamityToolEditRouteConfig from "./configs/calamities/tools/CalamityToolEditRouteConfig";
import useCalamityTypeCreateRouteConfig from "./configs/calamities/types/CalamityTypeCreateRouteConfig";
import useCalamityTypeEditRouteConfig from "./configs/calamities/types/CalamityTypeEditRouteConfig";
import useIPLocksRouteConfig from "./configs/ip_locks/IPLocksRouteConfig";
import useIPLockCreateRouteConfig from "./configs/ip_locks/IPLockCreateRouteConfig";
import useIPLockEditRouteConfig from "./configs/ip_locks/IPLockEditRouteConfig";
import useLayoutCreateRouteConfig from "./configs/layouts/LayoutCreateRouteConfig";
import useLayoutsRouteConfig from "./configs/layouts/LayoutsRouteConfig";
import useLayoutEditRouteConfig from "./configs/layouts/LayoutEditRouteConfig";
import useTerminalCreateRouteConfig from "./configs/terminals/TerminalCreateRouteConfig";
import useTerminalEditRouteConfig from "./configs/terminals/TerminalEditRouteConfig";
import useTerminalsRouteConfig from "./configs/terminals/TerminalsRouteConfig";
import useThemeSettingsRouteConfig from "./configs/ThemeSettingsRouteConfig";
import useButtonsRouteConfig from "./configs/buttons/ButtonsRouteConfig";
import useButtonCreateRouteConfig from "./configs/buttons/ButtonCreateRouteConfig";
import useButtonEditRouteConfig from "./configs/buttons/ButtonEditRouteConfig";
import useFlowsRouteConfig from "./configs/flows/FlowsRouteConfig";
import useFlowCreateRouteConfig from "./configs/flows/FlowCreateRouteConfig";
import useFlowEditRouteConfig from "./configs/flows/FlowEditRouteConfig";
import useVisitorTypesRouteConfig from "./configs/visitor_types/VisitorTypesRouteConfig";
import useVisitorTypeCreateRouteConfig from "./configs/visitor_types/VisitorTypeCreateRouteConfig";
import useVisitorTypeEditRouteConfig from "./configs/visitor_types/VisitorTypeEditRouteConfig";
import useFieldConditionCreateRouteConfig from "./configs/fields/conditions/FieldConditionCreateRouteConfig";
import useFieldConditionEditRouteConfig from "./configs/fields/conditions/FieldConditionEditRouteConfig";
import useVisitorsRouteConfig from "./configs/visitors/VisitorsRouteConfig";
import useVisitorCreateRouteConfig from "./configs/visitors/VisitorCreateRouteConfig";
import useVisitorEditRouteConfig from "./configs/visitors/VisitorEditRouteConfig";
import useMailSettingsRouteConfig from "./configs/MailSettingsRouteConfig";
import useOutlookRouteConfig from "./configs/OutlookRouteConfig.js";
import useVisitorLogsRouteConfig from "./configs/VisitorLogsRouteConfig";
import useAvailablePermissionsRouteConfig from "./configs/AvailablePermissionsRouteConfig.js";

function AppRoutes() {
  return (
    <Routes>
      {useLoginRouteConfig().getRoute()}

      <Route element={useDashboardLayoutRouteConfig().getRouteElement()}>
        {/* Dashboard */}
        {useDashboardRouteConfig().getRoute()}

        {/* Announcements */}
        {useAnnouncementsRouteConfig().getRoute()}
        {useAnnouncementCreateRouteConfig().getRoute()}
        {useAnnouncementEditRouteConfig().getRoute()}

        {/* Users */}
        {useUsersRouteConfig().getRoute()}
        {useUserCreateRouteConfig().getRoute()}
        {useUserEditRouteConfig().getRoute()}

        {/* Contacts */}
        {useContactsRouteConfig().getRoute()}
        {useContactCreateRouteConfig().getRoute()}
        {useContactEditRouteConfig().getRoute()}

        {/* Terminals */}
        {useTerminalCreateRouteConfig().getRoute()}
        {useTerminalEditRouteConfig().getRoute()}
        {useTerminalsRouteConfig().getRoute()}

        {/* Roles */}
        {useRolesRouteConfig().getRoute()}
        {useRoleCreateRouteConfig().getRoute()}
        {useRoleEditRouteConfig().getRoute()}

        {/* Audit trails */}
        {useAuditTrailsRouteConfig().getRoute()}

        {/* Companies */}
        {useCompaniesRouteConfig().getRoute()}
        {useCompanyCreateRouteConfig().getRoute()}
        {useCompanyEditRouteConfig().getRoute()}
        {useLocationCreateRouteConfig().getRoute()}
        {useLocationEditRouteConfig().getRoute()}

        {/* Languages */}
        {useLanguagesRouteConfig().getRoute()}
        {useLanguageCreateRouteConfig().getRoute()}
        {useLanguageEditRouteConfig().getRoute()}

        {/* Help texts */}
        {useHelpTextsRouteConfig().getRoute()}
        {useHelpTextCreateRouteConfig().getRoute()}
        {useHelpTextEditRouteConfig().getRoute()}

        {/* Employees */}
        {useEmployeesRouteConfig().getRoute()}
        {useEmployeeCreateRouteConfig().getRoute()}
        {useEmployeeEditRouteConfig().getRoute()}

        {/* Departments */}
        {useDepartmentsRouteConfig().getRoute()}
        {useDepartmentCreateRouteConfig().getRoute()}
        {useDepartmentEditRouteConfig().getRoute()}

        {/* Calamities */}
        {useCalamitiesRouteConfig().getRoute()}
        {useCalamitiesLogRouteConfig().getRoute()}

        {/* Calamities - Tools */}
        {useCalamityToolCreateRouteConfig().getRoute()}
        {useCalamityToolEditRouteConfig().getRoute()}

        {/* Calamities - Types */}
        {useCalamityTypeCreateRouteConfig().getRoute()}
        {useCalamityTypeEditRouteConfig().getRoute()}

        {/* Buttons */}
        {useButtonsRouteConfig().getRoute()}
        {useButtonCreateRouteConfig().getRoute()}
        {useButtonEditRouteConfig().getRoute()}

        {/* Visitor Types */}
        {useVisitorTypesRouteConfig().getRoute()}
        {useVisitorTypeCreateRouteConfig().getRoute()}
        {useVisitorTypeEditRouteConfig().getRoute()}

        {/* Files */}
        {useFilesRouteConfig().getRoute()}
        {useFileCreateRouteConfig().getRoute()}
        {useFileEditRouteConfig().getRoute()}

        {/* IPLocks */}
        {useIPLocksRouteConfig().getRoute()}
        {useIPLockCreateRouteConfig().getRoute()}
        {useIPLockEditRouteConfig().getRoute()}

        {/* Parameters */}
        {useParametersRouteConfig().getRoute()}

        {/* Available permissions */}
        {useAvailablePermissionsRouteConfig().getRoute()}

        {/* Release notes */}
        {useReleaseNotesRouteConfig().getRoute()}

        {/* Dishes */}
        {useDishesRouteConfig().getRoute()}
        {useDishCreateRouteConfig().getRoute()}
        {useDishEditRouteConfig().getRoute()}

        {/* Orders */}
        {useOrdersRouteConfig().getRoute()}
        {useOrderCreateRouteConfig().getRoute()}
        {useOrderEditRouteConfig().getRoute()}
        {useOrderPrepareRouteConfig().getRoute()}
        {useOrdersLogRouteConfig().getRoute()}

        {/* Fields */}
        {useFieldsRouteConfig().getRoute()}
        {useFieldCreateRouteConfig().getRoute()}
        {useFieldEditRouteConfig().getRoute()}
        {useFieldOptionCreateRouteConfig().getRoute()}
        {useFieldOptionEditRouteConfig().getRoute()}
        {useFieldConditionCreateRouteConfig().getRoute()}
        {useFieldConditionEditRouteConfig().getRoute()}

        {/* Questionnaires */}
        {useQuestionnairesRouteConfig().getRoute()}
        {useQuestionnaireCreateRouteConfig().getRoute()}
        {useQuestionnaireEditRouteConfig().getRoute()}
        {useQuestionCreateRouteConfig().getRoute()}
        {useQuestionEditRouteConfig().getRoute()}
        {useQuestionAnswerCreateRouteConfig().getRoute()}
        {useQuestionAnswerEditRouteConfig().getRoute()}

        {/* Company languages */}
        {useCompanyLanguagesRouteConfig().getRoute()}
        {useCompanyLanguageCreateRouteConfig().getRoute()}
        {useCompanyLanguageEditRouteConfig().getRoute()}

        {/* Message templates */}
        {useMessageTemplatesRouteConfig().getRoute()}
        {useMessageTemplateCreateRouteConfig().getRoute()}
        {useMessageTemplateEditRouteConfig().getRoute()}

        {/* Layouts */}
        {useLayoutsRouteConfig().getRoute()}
        {useLayoutCreateRouteConfig().getRoute()}
        {useLayoutEditRouteConfig().getRoute()}

        {/* Theme settings */}
        {useThemeSettingsRouteConfig().getRoute()}

        {/* Mail settings */}
        {useMailSettingsRouteConfig().getRoute()}

        {/* Flows */}
        {useFlowsRouteConfig().getRoute()}
        {useFlowCreateRouteConfig().getRoute()}
        {useFlowEditRouteConfig().getRoute()}

        {/* Visitors */}
        {useVisitorsRouteConfig().getRoute()}
        {useVisitorCreateRouteConfig().getRoute()}
        {useVisitorEditRouteConfig().getRoute()}

        {/* Visitors log */}
        {useVisitorLogsRouteConfig().getRoute()}
      </Route>

      {/* Frontoffice */}
      {useFrontofficeRouteConfig().getRoute()}

      {/* Outlook */}
      {useOutlookRouteConfig().getRoute()}

      {/* Page not found */}
      {useNotFoundRouteConfig().getRoute()}
    </Routes>
  );
}

export default AppRoutes;
