import FlowChartEditor from "../../../../components/flowChartEditor/FlowChartEditor";
import { useLanguageContext } from "../../../../context/LanguageContext";
import { StyledAltButton } from "../../../../styles/ButtonStyles";
import Select from "../../../../components/select/main/Select";
import OverlayLoader from "../../../../components/loader/OverlayLoader/OverlayLoader";

function ChartView({
  flowChartData,
  availableSteps,
  onChartNodesChange,
  onChartConnectionsChange,
  onStepAdd,
}) {
  const { translate } = useLanguageContext();

  return (
    <OverlayLoader
      groups={"flow_page_fetch"}
      iconSize={"xl"}
      containerStyle={{ flex: 1 }}
    >
      <FlowChartEditor
        nodes={flowChartData.nodes}
        onNodesChange={onChartNodesChange}
        connections={flowChartData.connections}
        onConnectionsChange={onChartConnectionsChange}
        customControls={
          <Select
            as={StyledAltButton}
            hideCaret={true}
            valueVisual={(val) =>
              translate("eva.main.entities.states.create", [
                {
                  key: "entities",
                  value: translate("eva.main.entities.flow_step"),
                },
              ])
            }
            options={availableSteps.map((type) => ({
              value: type,
              label: translate(`eva.main.flow.step.types.${type}`),
            }))}
            onChange={onStepAdd}
          />
        }
      />
    </OverlayLoader>
  );
}

export default ChartView;
