import styled from "styled-components";
import { StyledIconButton } from "../../../styles/ButtonStyles";
import { MultiStyle } from "../../../context/ThemeContext";

export const StyledCheckboxContainer = styled.span`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.normal};
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? 0.33 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "initial")};

  & > * {
    cursor: pointer;
    pointer-events: ${(props) => (props.disabled ? "none" : "initial")};
  }
`;

export const StyledCheckbox = styled(StyledIconButton)`
  ${(props) =>
    MultiStyle.new(props, "background-color")
      .setBack(`rgba(${props.theme.colors.primary.lightestRGB}, 0.33)`)
      .setFront(props.theme["settings.input.color.primary.background"])
      .get()}
  ${(props) =>
    MultiStyle.new(props, "color")
      .setBack(props.theme.colors.on.primary)
      .setFront(props.theme["settings.input.color.primary.text"] ?? "inherit")
      .get()}
  ${(props) =>
    MultiStyle.new(props, "border-color")
      .setBack(props.theme.colors.primary.lightest)
      .setFront(props.theme["settings.input.color.primary.border"])
      .get()}

  border-width: 1px;
  border-style: solid;

  &:hover {
    ${(props) =>
      MultiStyle.new(props, "background-color")
        .setBack(`rgba(${props.theme.colors.primary.lightestRGB}, 0.33)`)
        .setFront(props.theme["settings.input.color.primary.background"])
        .get()}
    ${(props) =>
      MultiStyle.new(props, "color")
        .setBack(props.theme.colors.accent.dark)
        .setFront(props.theme["settings.input.color.primary.text"] ?? "inherit")
        .get()}
      ${(props) =>
      MultiStyle.new(props, "border-color")
        .setBack(props.theme.colors.accent.dark)
        .setFront(props.theme["settings.input.color.primary.border"])
        .get()}
  }
`;

export const StyledLabel = styled.label`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
