import { useState } from "react";
import DashboardNavView from "./DashboardNavView";
import DashboardNavGroupConfig from "./DashboardNavGroupConfig";
import { useLanguageContext } from "../../../context/LanguageContext";
import DashboardNavButtonConfig from "./DashboardNavButtonConfig";
import useDashboardRouteConfig from "../../../routes/configs/DashboardRouteConfig";
import useVisitorsRouteConfig from "../../../routes/configs/visitors/VisitorsRouteConfig";
import useVisitorLogsRouteConfig from "../../../routes/configs/VisitorLogsRouteConfig";
import useCompaniesRouteConfig from "../../../routes/configs/companies/CompaniesRouteConfig";
import {
  faAddressBook,
  faBook,
  faBorderNone,
  faBriefcase,
  faBuilding,
  faBullhorn,
  faBurger,
  faCity,
  faCodeBranch,
  faCodeCompare,
  faDesktop,
  faEnvelope,
  faFileInvoiceDollar,
  faFlag,
  faGear,
  faHome,
  faList,
  faMessage,
  faPalette,
  faPhotoFilm,
  faQuestion,
  faShieldHalved,
  faTags,
  faTowerBroadcast,
  faUnlock,
  faUpRightFromSquare,
  faUsers,
  faUserTag,
} from "@fortawesome/free-solid-svg-icons";
import useAnnouncementsRouteConfig from "../../../routes/configs/announcements/AnnouncementsRouteConfig";
import useLanguagesRouteConfig from "../../../routes/configs/languages/LanguagesRouteConfig";
import useUsersRouteConfig from "../../../routes/configs/users/UsersRouteConfig";
import useRolesRouteConfig from "../../../routes/configs/roles/RolesRouteConfig";
import useEmployeesRouteConfig from "../../../routes/configs/employees/EmployeesRouteConfig";
import useTerminalsRouteConfig from "../../../routes/configs/terminals/TerminalsRouteConfig";
import useFieldsRouteConfig from "../../../routes/configs/fields/FieldsRouteConfig";
import useQuestionnairesRouteConfig from "../../../routes/configs/questionnaires/QuestionnairesRouteConfig";
import useMessageTemplatesRouteConfig from "../../../routes/configs/message_templates/MessageTemplatesRouteConfig";
import useCalamitiesRouteConfig from "../../../routes/configs/calamities/CalamitiesRouteConfig";
import useCalamitiesLogRouteConfig from "../../../routes/configs/calamities/CalamitiesLogRouteConfig";
import useParametersRouteConfig from "../../../routes/configs/ParametersRouteConfig";
import useIPLocksRouteConfig from "../../../routes/configs/ip_locks/IPLocksRouteConfig";
import useFilesRouteConfig from "../../../routes/configs/files/FilesRouteConfig";
import useMailSettingsRouteConfig from "../../../routes/configs/MailSettingsRouteConfig";
import useButtonsRouteConfig from "../../../routes/configs/buttons/ButtonsRouteConfig";
import useVisitorTypesRouteConfig from "../../../routes/configs/visitor_types/VisitorTypesRouteConfig";
import useContactsRouteConfig from "../../../routes/configs/contacts/ContactsRouteConfig";
import useDepartmentsRouteConfig from "../../../routes/configs/departments/DepartmentsRouteConfig";
import useFlowsRouteConfig from "../../../routes/configs/flows/FlowsRouteConfig";
import useLayoutsRouteConfig from "../../../routes/configs/layouts/LayoutsRouteConfig";
import useThemeSettingsRouteConfig from "../../../routes/configs/ThemeSettingsRouteConfig";
import useCompanyLanguagesRouteConfig from "../../../routes/configs/companyLanguages/CompanyLanguagesRouteConfig";
import useAuditTrailsRouteConfig from "../../../routes/configs/AuditTrailsRouteConfig";
import useHelpTextsRouteConfig from "../../../routes/configs/helpTexts/HelpTextsRouteConfig";
import useReleaseNotesRouteConfig from "../../../routes/configs/ReleaseNotesRouteConfig";
import useDishesRouteConfig from "../../../routes/configs/canteen/dishes/DishesRouteConfig";
import useOrdersRouteConfig from "../../../routes/configs/canteen/orders/OrdersRouteConfig";
import useOrdersLogRouteConfig from "../../../routes/configs/canteen/orders/OrdersLogRouteConfig";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../context/AuthContext";
import useAvailablePermissionsRouteConfig from "../../../routes/configs/AvailablePermissionsRouteConfig";

function DashboardNav() {
  const navigate = useNavigate();
  const { translate } = useLanguageContext();
  const [openFullNav, setOpenFullNav] = useState(false);
  const authContext = useAuthContext();
  const companyType = authContext.auth?.user?.company?.type;
  const [reload, setReload] = useState(false);
  const [folded, setFolded] = useState(
    localStorage.getItem("dashboard_nav_folded") === "true"
  );

  const dashboardNavConfig = [
    // Dashboard group
    new DashboardNavGroupConfig()
      .setUniqueKey("dashboard")
      .setLabel(translate("eva.main.general.dashboard"))
      .setButtonConfigs([
        // Dashboard
        new DashboardNavButtonConfig()
          .setRouteConfig(useDashboardRouteConfig())
          .setIcon(faHome)
          .setOnlyActiveOnExactPath(true),
      ]),

    // Superadmin group
    new DashboardNavGroupConfig()
      .setUniqueKey("superadmin")
      .setLabel(translate("eva.main.general.superadmin"))
      .setButtonConfigs([
        // Companies
        new DashboardNavButtonConfig()
          .setRouteConfig(useCompaniesRouteConfig())
          .setIcon(faBuilding)
          .setHidden(companyType !== "superadmin"),

        // Announcements
        new DashboardNavButtonConfig()
          .setRouteConfig(useAnnouncementsRouteConfig())
          .setIcon(faBullhorn)
          .setHidden(companyType !== "superadmin"),

        // Languages
        new DashboardNavButtonConfig()
          .setRouteConfig(useLanguagesRouteConfig())
          .setIcon(faFlag)
          .setHidden(companyType !== "superadmin"),

        // Help Texts
        new DashboardNavButtonConfig()
          .setRouteConfig(useHelpTextsRouteConfig())
          .setIcon(faQuestion)
          .setHidden(companyType !== "superadmin"),
      ]),

    // Visitors group
    new DashboardNavGroupConfig()
      .setUniqueKey("visitors")
      .setLabel(translate("eva.main.entities.visitors"))
      .setButtonConfigs([
        // Visitors
        new DashboardNavButtonConfig()
          .setRouteConfig(useVisitorsRouteConfig())
          .setIcon(faUsers)
          .setHidden(companyType === "superadmin"),

        // Visitor Logs
        new DashboardNavButtonConfig()
          .setRouteConfig(useVisitorLogsRouteConfig())
          .setIcon(faList)
          .setHidden(companyType === "superadmin"),

        // Visitor Types
        new DashboardNavButtonConfig()
          .setRouteConfig(useVisitorTypesRouteConfig())
          .setIcon(faUserTag)
          .setHidden(companyType === "superadmin"),
      ]),

    // Employees group
    new DashboardNavGroupConfig()
      .setUniqueKey("employees")
      .setLabel(translate("eva.main.entities.employees"))
      .setButtonConfigs([
        // Employees
        new DashboardNavButtonConfig()
          .setRouteConfig(useEmployeesRouteConfig())
          .setIcon(faBriefcase)
          .setHidden(companyType === "superadmin"),

        // Departments
        new DashboardNavButtonConfig()
          .setRouteConfig(useDepartmentsRouteConfig())
          .setIcon(faCity)
          .setHidden(companyType === "superadmin"),
      ]),

    // General group
    new DashboardNavGroupConfig()
      .setUniqueKey("general")
      .setLabel(translate("eva.main.general.general"))
      .setButtonConfigs([
        // Fields
        new DashboardNavButtonConfig()
          .setRouteConfig(useFieldsRouteConfig())
          .setIcon(faTags)
          .setHidden(companyType === "superadmin"),

        // Questionnaires
        new DashboardNavButtonConfig()
          .setRouteConfig(useQuestionnairesRouteConfig())
          .setIcon(faQuestion)
          .setHidden(companyType === "superadmin"),

        // Message Templates
        new DashboardNavButtonConfig()
          .setRouteConfig(useMessageTemplatesRouteConfig())
          .setIcon(faMessage)
          .setHidden(companyType === "superadmin"),

        // Files
        new DashboardNavButtonConfig()
          .setRouteConfig(useFilesRouteConfig())
          .setIcon(faPhotoFilm)
          .setHidden(companyType === "superadmin"),

        // Buttons
        new DashboardNavButtonConfig()
          .setRouteConfig(useButtonsRouteConfig())
          .setIcon(faUpRightFromSquare)
          .setHidden(companyType === "superadmin"),

        // Contacts
        new DashboardNavButtonConfig()
          .setRouteConfig(useContactsRouteConfig())
          .setIcon(faAddressBook)
          .setHidden(companyType === "superadmin"),

        // Flows
        new DashboardNavButtonConfig()
          .setRouteConfig(useFlowsRouteConfig())
          .setIcon(faCodeBranch)
          .setHidden(companyType === "superadmin"),

        // Layouts
        new DashboardNavButtonConfig()
          .setRouteConfig(useLayoutsRouteConfig())
          .setIcon(faBorderNone)
          .setHidden(companyType === "superadmin"),
      ]),

    // Access group
    new DashboardNavGroupConfig()
      .setUniqueKey("access")
      .setLabel(translate("eva.main.general.access"))
      .setButtonConfigs([
        // Users
        new DashboardNavButtonConfig()
          .setRouteConfig(useUsersRouteConfig())
          .setIcon(faUsers),

        // Roles
        new DashboardNavButtonConfig()
          .setRouteConfig(useRolesRouteConfig())
          .setIcon(faUsers)
          .setHidden(companyType === "superadmin"),

        // Available Permissions
        new DashboardNavButtonConfig()
          .setRouteConfig(useAvailablePermissionsRouteConfig())
          .setIcon(faUnlock)
          .setHidden(authContext.auth?.user?.role_type !== "superadmin"),

        // IP Lock
        new DashboardNavButtonConfig()
          .setRouteConfig(useIPLocksRouteConfig())
          .setIcon(faShieldHalved)
          .setHidden(companyType === "superadmin"),

        // Terminals
        new DashboardNavButtonConfig()
          .setRouteConfig(useTerminalsRouteConfig())
          .setIcon(faDesktop)
          .setHidden(companyType === "superadmin"),
      ]),

    // Calamities group
    new DashboardNavGroupConfig()
      .setUniqueKey("calamities")
      .setLabel(translate("eva.main.entities.calamities"))
      .setButtonConfigs([
        // Calamities
        new DashboardNavButtonConfig()
          .setRouteConfig(useCalamitiesRouteConfig())
          .setIcon(faTowerBroadcast)
          .setHidden(companyType === "superadmin")
          .setActivePathExceptions(["log"]),

        // Calamities Log
        new DashboardNavButtonConfig()
          .setRouteConfig(useCalamitiesLogRouteConfig())
          .setIcon(faBook)
          .setHidden(companyType === "superadmin"),
      ]),

    // Canteen group
    new DashboardNavGroupConfig()
      .setUniqueKey("canteen")
      .setLabel(translate("eva.main.general.canteen"))
      .setButtonConfigs([
        // Dishes
        new DashboardNavButtonConfig()
          .setRouteConfig(useDishesRouteConfig())
          .setIcon(faBurger)
          .setHidden(companyType === "superadmin"),

        // Orders
        new DashboardNavButtonConfig()
          .setRouteConfig(useOrdersRouteConfig())
          .setIcon(faFileInvoiceDollar)
          .setActivePathExceptions(["log"])
          .setHidden(companyType === "superadmin"),

        // Orders Log
        new DashboardNavButtonConfig()
          .setRouteConfig(useOrdersLogRouteConfig())
          .setIcon(faList)
          .setHidden(companyType === "superadmin"),
      ]),

    // Configuration group
    new DashboardNavGroupConfig()
      .setUniqueKey("settings")
      .setLabel(translate("eva.main.general.settings"))
      .setButtonConfigs([
        // Parameters
        new DashboardNavButtonConfig()
          .setRouteConfig(useParametersRouteConfig())
          .setIcon(faGear),

        // Theme Settings
        new DashboardNavButtonConfig()
          .setRouteConfig(useThemeSettingsRouteConfig())
          .setIcon(faPalette),

        // Mail Settings
        new DashboardNavButtonConfig()
          .setRouteConfig(useMailSettingsRouteConfig())
          .setIcon(faEnvelope)
          .setHidden(companyType === "superadmin"),

        // Company Languages
        new DashboardNavButtonConfig()
          .setRouteConfig(useCompanyLanguagesRouteConfig())
          .setIcon(faFlag)
          .setHidden(companyType === "superadmin"),
      ]),

    // Other group
    new DashboardNavGroupConfig()
      .setUniqueKey("other")
      .setLabel(translate("eva.main.general.other"))
      .setButtonConfigs([
        // Release Notes
        new DashboardNavButtonConfig()
          .setRouteConfig(useReleaseNotesRouteConfig())
          .setIcon(faCodeCompare),

        // Audit Trails
        new DashboardNavButtonConfig()
          .setRouteConfig(useAuditTrailsRouteConfig())
          .setIcon(faList),
      ]),
  ];

  /**
   * This method will handle the folded change
   * @param {boolean} value
   */
  function onFoldedChange(value) {
    setFolded(value);
    localStorage.setItem("dashboard_nav_folded", value);
  }

  /**
   * This method will navigate to the given path
   * @param {string} path
   */
  function onNavigateTo(path) {
    navigate(path);
    setOpenFullNav(false);
  }

  /**
   * This method will return the active state of the given group
   * @param {DashboardNavGroupConfig} groupConfig
   * @returns {boolean}
   */
  function getNavGroupActive(groupConfig) {
    const storageVisible = localStorage.getItem(
      `dashboard_nav_group_${groupConfig.uniqueKey}_visible`
    );
    return storageVisible !== null ? storageVisible === "true" : true;
  }

  /**
   * This method handles the active change of the given group
   * @param {DashboardNavGroupConfig} groupConfig
   */
  function onNavGroupActiveChange(groupConfig) {
    const active = getNavGroupActive(groupConfig);
    localStorage.setItem(
      `dashboard_nav_group_${groupConfig.uniqueKey}_visible`,
      !active
    );
    setReload(!reload);
  }

  /**
   * This method will handle the click event on the full nav open button
   */
  function onFullNavOpenButtonClick() {
    setOpenFullNav(!openFullNav);
  }

  /**
   * This method will handle the click event on the full nav wrapper
   * @param {event} e
   */
  function onFullNavWrapperClick(e) {
    if (e.target === e.currentTarget) {
      setOpenFullNav(false);
    }
  }

  return (
    <DashboardNavView
      dashboardNavConfig={dashboardNavConfig}
      folded={folded}
      openFullNav={openFullNav}
      setOpenFullNav={setOpenFullNav}
      onFoldedChange={onFoldedChange}
      onNavigateTo={onNavigateTo}
      onFullNavOpenButtonClick={onFullNavOpenButtonClick}
      onFullNavWrapperClick={onFullNavWrapperClick}
      getNavGroupActive={getNavGroupActive}
      onNavGroupActiveChange={onNavGroupActiveChange}
    />
  );
}

export default DashboardNav;
