import ZoomPanEditor from "../zoomPanEditor/ZoomPanEditor";
import Node from "./node/Node";
import Connector from "./connector/Connector";
import Connection from "./connection/Connection";

function FlowChartEditorView({
  nodes,
  connections,
  selectedConnector,
  zoomPanController,
  refs,
  setRefs,
  onConnectorClick,
  onConnectionClick,
  onNodeClick,
  onNodePositionChange,
  cursorRef,
  cursorPosition,
  children,
  customControls,
}) {
  return (
    <ZoomPanEditor
      controller={zoomPanController}
      customControls={customControls}
    >
      <div
        ref={cursorRef}
        style={{
          position: "absolute",
          left: cursorPosition.x,
          top: cursorPosition.y,
          transform: "translate(-50%, -50%)",
          pointerEvents: "none",
        }}
      />

      {selectedConnector && (
        <Connection
          id={`[CONNECTION]-[PREVIEW]`}
          zoomPanController={zoomPanController}
          fromConnectorId={selectedConnector}
          toConnectorId={"[CURSOR]"}
          refs={refs}
          connections={connections}
        />
      )}

      {connections.map((connection) => (
        <Connection
          key={connection.id}
          id={`[CONNECTION]-${connection.id}`}
          zoomPanController={zoomPanController}
          fromConnectorId={connection.fromConnectorId}
          toConnectorId={connection.toConnectorId}
          connections={connections}
          onClick={() => onConnectionClick(connection.id)}
          active={connection.active}
          refs={refs}
        >
          {connection.children}
        </Connection>
      ))}

      {nodes.map((node) => (
        <Node
          key={node.id}
          id={`[NODE]-${node.id}`}
          title={node.title}
          position={node.position}
          active={node.active}
          Element={node.Element}
          zoomPanController={zoomPanController}
          onPositionChange={
            node.moveable === true || node.moveable === undefined
              ? (position) => onNodePositionChange(node.id, position)
              : undefined
          }
          onClick={
            node.clickable === true || node.clickable === undefined
              ? () => onNodeClick(node.id)
              : undefined
          }
          connectors={node.connectors.map((connector) => {
            const connectorId = `${node.id}-[CONNECTOR]-${connector.position}`;
            return (
              <Connector
                key={connectorId}
                id={connectorId}
                position={connector.position}
                onClick={() => onConnectorClick(connectorId)}
                Element={connector.Element}
                setRefs={setRefs}
                connections={connections}
                selectedConnector={selectedConnector}
                node={node}
              />
            );
          })}
        />
      ))}

      {children}
    </ZoomPanEditor>
  );
}

export default FlowChartEditorView;
