import CalamityTableView from "./CalamityTableView";
import { useCalamityContext } from "../../context/CalamityContext";
import useEvaApi, { EvaApiCall } from "../../hooks/useEvaApi";

function CalamityTable() {
  const callEvaApi = useEvaApi();
  const calamityContext = useCalamityContext();

  /**
   * This method will stop and delete the specified calamity
   */
  async function onCalamityStop(calamity) {
    await callEvaApi(
      new EvaApiCall(`calamities/${calamity.id}`)
        .setMethod("DELETE")
        .setLoadingGroup(`calamities_stop_page_${calamity.id}`)
    );

    calamityContext.fetchCalamities();
  }

  return <CalamityTableView onCalamityStop={onCalamityStop} />;
}

export default CalamityTable;
