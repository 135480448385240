import { useState } from "react";
import useBasicColumnConfig from "../../main/config/column/default/BasicColumnConfig";
import DataTableConfig from "../../main/config/dataTable/DataTableConfig";
import DataTable, { useDataTable } from "../../main/DataTable";
import useEvaApi, { EvaApiCall } from "../../../../hooks/useEvaApi";
import ColumnConfig from "../../main/config/column/ColumnConfig";
import { useLanguageContext } from "../../../../context/LanguageContext";
import useCustomEffect from "../../../../hooks/useCustomEffect";

function VisitorLogsDataTable() {
  const callEvaApi = useEvaApi();
  const { translateEntity, translate } = useLanguageContext();
  const [fields, setFields] = useState([]);

  //Create the datatable config
  const config = new DataTableConfig("visitor_logs");

  //Set the datatable fetch info
  config.setFetchRequest("visitor_logs");
  config.setFetchParams({
    with_visitor_log_location_data: true,
    with_visitor_log_visitor_type_data: true,
    with_visitor_log_employee_data: true,
    with_visitor_log_sign_in_terminal_data: true,
  });

  //Set default order
  config.setOrderBy("created_at");
  config.setOrderDirection("desc");

  //Set the datatable columns
  config.setColumns([
    useBasicColumnConfig("location.name").setData(
      (row) => row.location?.name ?? row.location_name
    ),
    useBasicColumnConfig("visitor_type.name").setData((row) =>
      row.visitor_type
        ? translateEntity(row.visitor_type, "title")
        : row.visitor_type_name
    ),
    useBasicColumnConfig("employee.name").setData(
      (row) => row.employee?.name ?? row.employee_name
    ),

    ...fields.map((field) =>
      new ColumnConfig()
        .setKey(`fields.${field.key}`)
        .setLabel(translateEntity(field, "label"))
        .setData((row) => getFieldColumnData(field, row))
        .setSearchable(field.type !== "boolean")
    ),

    useBasicColumnConfig("sign_in_terminal.name").setData(
      (row) => row.sign_in_terminal?.name ?? row.sign_in_terminal_name
    ),
    useBasicColumnConfig("signed_in_at"),
    useBasicColumnConfig("signed_out_at"),
    useBasicColumnConfig("created_at").setUseFromToDateTime(true),
  ]);

  //Create the datatable object
  const dataTable = useDataTable(config);

  /**
   * This effect will fetch the fields from the API
   */
  useCustomEffect(() => {
    fetchFields();
  });

  /**
   * This method will fetch the fields from the API
   */
  async function fetchFields() {
    await callEvaApi(
      new EvaApiCall("fields")
        .setLoadingGroup(config.getFetchKey())
        .setParams({ with_field_options_data: true })
        .setAlertError(true)
        .setAlertSuccess(false)
        .setOnSuccess((response) => {
          setFields(response.data.data.records);
        })
    );
  }

  /**
   * This method will return the column data for the given field and row
   * @param {object} field
   * @param {object} row
   * @returns {any}
   */
  function getFieldColumnData(field, row) {
    // Find the field data for the given field
    const fieldValue = row.fields?.[field.key];
    if (!fieldValue) {
      return "";
    }

    // If the field is a select, return the translation of the selected option
    if (field.type === "select") {
      const option = field.options.find((o) => o.key === fieldValue);
      return option ? translateEntity(option, "label") : "";
    }
    // If the field is a boolean, return the translation of the value
    else if (field.type === "boolean") {
      return fieldValue === "1"
        ? translate("eva.main.general.yes")
        : translate("eva.main.general.no");
    }

    // Otherwise, return the value of the field
    return fieldValue ?? "";
  }

  return <DataTable controller={dataTable} />;
}

export default VisitorLogsDataTable;
