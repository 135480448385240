import { StyledNode } from "./NodeStyles";
import NodeView from "./NodeView";

function Node({
  id,
  title = "node",
  position = { x: 0, y: 0 },
  onPositionChange,
  connectors,
  active = false,
  onClick,
  Element = StyledNode,
  zoomPanController,
}) {
  return (
    <NodeView
      id={id}
      title={title}
      position={position}
      onPositionChange={onPositionChange}
      connectors={connectors}
      onClick={onClick}
      active={active}
      Element={Element}
      zoomPanController={zoomPanController}
    />
  );
}

export default Node;
