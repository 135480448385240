import { useRef, useState } from "react";
import MoveableView from "./MoveableView";

function Moveable({
  children,
  position = { x: 0, y: 0 },
  onPositionChange = (pos) => {},
  disabled = false,
  speed = 1,
}) {
  const [moving, setMoving] = useState(false);
  const startPosition = useRef({ x: 0, y: 0 });
  const mouseStartPosition = useRef({ x: 0, y: 0 });

  /**
   * This method will handle the mouse down event.
   * @param {event} e
   */
  function onMouseDown(e) {
    if (disabled) {
      return;
    }

    e.preventDefault();

    startPosition.current = { x: position.x, y: position.y };
    mouseStartPosition.current = { x: e.clientX, y: e.clientY };
    let isMoving = false;
    let moveThreshold = 10;

    function onMouseMove(e) {
      const movement = {
        x: e.clientX - mouseStartPosition.current.x,
        y: e.clientY - mouseStartPosition.current.y,
      };
      const movementDistance = Math.sqrt(movement.x ** 2 + movement.y ** 2);

      if (!isMoving && movementDistance > moveThreshold) {
        isMoving = true;
        setMoving(true);
      }

      if (isMoving) {
        onPositionChange({
          x: startPosition.current.x + movement.x * speed,
          y: startPosition.current.y + movement.y * speed,
        });
      }
    }

    function onMouseUp() {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
      setMoving(false);
    }

    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);
  }

  return (
    <MoveableView
      position={position}
      disabled={disabled}
      onMouseDown={onMouseDown}
      moving={moving}
    >
      {children}
    </MoveableView>
  );
}

export default Moveable;
