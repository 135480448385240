import { useState } from "react";
import FlowPageView from "./FlowPageView";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";
import useCustomEffect from "../../../hooks/useCustomEffect";
import { useParams } from "react-router-dom";

function FlowPage() {
  const callEvaApi = useEvaApi();
  const { flow } = useParams();
  const [flowData, setFlowData] = useState({
    name: "",
    steps: [
      {
        id: "start",
        type: "start",
        position: { x: 0, y: 0 },
      },
      {
        id: "process_data",
        type: "process_data",
        position: { x: 0, y: 200 },
      },
    ],
    connections: [],
  });
  const [relations, setRelations] = useState({
    fields: [],
    files: [],
    employees: [],
    message_templates: [],
  });
  const [submitError, setSubmitError] = useState(null);
  const availableSteps = ["fields", "houserules", "employee", "messages"];

  /**
   * UseEffect for initializations.
   */
  useCustomEffect(() => {
    fetchFields();
    fetchFiles();
    fetchEmployees();
    fetchMessageTemplates();

    if (flow) {
      fetchFlowData();
    }
  });

  /**
   * This method will fetch the fields.
   */
  async function fetchFields() {
    await callEvaApi(
      new EvaApiCall("fields")
        .setLoadingGroup("flow_page_fetch")
        .setRedirectOnError(true)
        .setOnSuccess((response) => {
          setRelations((prev) => ({
            ...prev,
            fields: response.data.data.records,
          }));
        })
    );
  }

  /**
   * This method will fetch the files.
   */
  async function fetchFiles() {
    await callEvaApi(
      new EvaApiCall("files")
        .setLoadingGroup("flow_page_fetch")
        .setParams({
          where: [{ column: "type", values: ["houserule"] }],
        })
        .setRedirectOnError(true)
        .setOnSuccess((response) => {
          setRelations((prev) => ({
            ...prev,
            files: response.data.data.records,
          }));
        })
    );
  }

  /**
   * This method will fetch the employees.
   */
  async function fetchEmployees() {
    await callEvaApi(
      new EvaApiCall("employees")
        .setLoadingGroup("flow_page_fetch")
        .setRedirectOnError(true)
        .setOnSuccess((response) => {
          setRelations((prev) => ({
            ...prev,
            employees: response.data.data.records,
          }));
        })
    );
  }

  /**
   * This method will fetch the message templates.
   */
  async function fetchMessageTemplates() {
    await callEvaApi(
      new EvaApiCall("message_templates")
        .setLoadingGroup("flow_page_fetch")
        .setRedirectOnError(true)
        .setOnSuccess((response) => {
          setRelations((prev) => ({
            ...prev,
            message_templates: response.data.data.records,
          }));
        })
    );
  }

  async function fetchFlowData() {
    await callEvaApi(
      new EvaApiCall(`flows/${flow}`)
        .setLoadingGroup("flow_page_fetch")
        .setParams({
          with_flow_connections_data: true,
          with_flow_steps_data: true,
          with_flow_step_fields_data: true,
          with_flow_step_messages_data: true,
        })
        .setRedirectOnError(true)
        .setOnSuccess((response) => {
          setFlowData({
            name: response.data.data.name,
            steps: response.data.data.steps.map((step) => ({
              id: step.id,
              type: step.type,
              position: step.position,
              force_show: step.force_show,
              file_id: step.file,
              fields: step.fields.map((row) =>
                row.map((stepField) => ({
                  field_id: stepField.field,
                  required_backoffice: stepField.required_backoffice,
                  required_terminal: stepField.required_terminal,
                  hidden_outlook: stepField.hidden_outlook,
                }))
              ),
              messages: step.messages.map((message) => ({
                message_template_id: message.message_template,
                type: message.type,
                receiver: message.receiver,
                employee_id: message.employee,
                event: message.event,
              })),
            })),
            connections: response.data.data.connections.map((connection) => ({
              id: connection.id,
              from_step_id: connection.from_step,
              from_step_position: connection.from_step_position,
              to_step_id: connection.to_step,
              to_step_position: connection.to_step_position,
            })),
          });
        })
    );
  }

  return (
    <FlowPageView
      relations={relations}
      flowData={flowData}
      setFlowData={setFlowData}
      submitError={submitError}
      setSubmitError={setSubmitError}
      availableSteps={availableSteps}
    />
  );
}

export default FlowPage;
