import CompactError from "../../../../components/error/compact/CompactError";
import { useLanguageContext } from "../../../../context/LanguageContext";
import EVAApiHelper from "../../../../helpers/EVAApiHelper";
import RandomizeHelper from "../../../../helpers/RandomizeHelper";
import { StyledFlexRow } from "../../../../styles/LayoutStyles";
import ChartView from "./ChartView";
import { getNodeConfig } from "./nodes/NodeConfigs";

function Chart({
  flowData,
  setFlowData,
  availableSteps,
  submitError,
  setSubmitError,
}) {
  const { translate } = useLanguageContext();
  const flowChartData = {
    nodes: getChartNodes(flowData),
    connections: getChartConnections(flowData),
  };

  /**
   * This method will handle the nodes change event
   * @param {function(prev)} callback
   */
  function onChartNodesChange(callback) {
    setFlowData((prev) => {
      const newSteps = getFlowSteps(prev.steps, callback(getChartNodes(prev)));
      if (newSteps.length !== prev.steps.length) {
        setSubmitError(null);
      }

      return {
        ...prev,
        steps: newSteps,
      };
    });
  }

  /**
   * This method will handle the connections change event
   * @param {function(prev)} callback
   */
  function onChartConnectionsChange(callback) {
    setFlowData((prev) => {
      const newConnections = getFlowConnections(
        prev.connections,
        callback(getChartConnections(prev))
      );
      if (newConnections.length !== prev.connections.length) {
        setSubmitError(null);
      }

      return {
        ...prev,
        connections: newConnections,
      };
    });
  }

  /**
   * This method will handle the step add event
   * @param {string} type
   */
  function onStepAdd(type) {
    setSubmitError(null);
    setFlowData((prev) => ({
      ...prev,
      steps: [
        ...prev.steps,
        {
          id: RandomizeHelper.getUUID(),
          type: type,
          position: { x: 250, y: 0 },
        },
      ],
    }));
  }

  /**
   * This method will get the nodes data from the flow data
   * @param {object} flowData
   * @returns {object}
   */
  function getChartNodes(flowData) {
    return flowData.steps.map((step, stepIndex) => {
      const nodeConfig = getNodeConfig(step.type);
      return {
        id: step.id,
        title: (
          <StyledFlexRow style={{ flexWrap: "nowrap" }}>
            <p
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {translate(`eva.main.flow.step.types.${step.type}`)}
            </p>

            <CompactError
              error={EVAApiHelper.getResponseErrorsLike(
                `steps.${stepIndex}`,
                submitError,
                translate
              )}
            />
          </StyledFlexRow>
        ),
        position: step.position,
        active: step.active,
        ...nodeConfig,
      };
    });
  }

  /**
   * This method will get the flow steps from the chart nodes
   * @param {array} flowSteps
   * @param {array} chartNodes
   * @returns {array}
   */
  function getFlowSteps(flowSteps, chartNodes) {
    return chartNodes.map((node) => {
      const flowStep = flowSteps.find((step) => step.id === node.id);
      return {
        ...flowStep,
        id: node.id,
        position: node.position,
        active: node.active,
      };
    });
  }

  /**
   * This method will get the connections data from the flow data
   * @param {object} flowData
   * @returns {object}
   */
  function getChartConnections(flowData) {
    return flowData.connections.map((connection, connectionIndex) => {
      return {
        id: connection.id,
        fromConnectorId: `${connection.from_step_id}-[CONNECTOR]-${connection.from_step_position}`,
        toConnectorId: `${connection.to_step_id}-[CONNECTOR]-${connection.to_step_position}`,
        active: connection.active,
        children: (
          <CompactError
            error={EVAApiHelper.getResponseErrorsLike(
              `connections.${connectionIndex}`,
              submitError,
              translate
            )}
          />
        ),
      };
    });
  }

  /**
   * This method will get the flow connections from the chart connections
   * @param {array} flowConnections
   * @param {array} chartConnections
   * @returns {array}
   */
  function getFlowConnections(flowConnections, chartConnections) {
    return chartConnections.map((connection) => {
      const fromConnectorParts =
        connection.fromConnectorId.split("-[CONNECTOR]-");
      const toConnectorParts = connection.toConnectorId.split("-[CONNECTOR]-");
      const flowConnection = flowConnections.find(
        (c) => c.id === connection.id
      );

      return {
        ...flowConnection,
        id: connection.id,
        from_step_id: fromConnectorParts[0],
        from_step_position: fromConnectorParts[1],
        to_step_id: toConnectorParts[0],
        to_step_position: toConnectorParts[1],
        active: connection.active,
      };
    });
  }

  return (
    <ChartView
      flowChartData={flowChartData}
      availableSteps={availableSteps}
      onChartNodesChange={onChartNodesChange}
      onChartConnectionsChange={onChartConnectionsChange}
      onStepAdd={onStepAdd}
      submitError={submitError}
    />
  );
}

export default Chart;
