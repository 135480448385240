import React from "react";

function MoveableView({ position, children, disabled, moving, onMouseDown }) {
  return React.Children.map(children, (child) =>
    React.cloneElement(child, {
      onMouseDown: child.props.onMouseDown
        ? (e) => {
            onMouseDown(e);
            child.props.onMouseDown(e);
          }
        : onMouseDown,
      style: {
        ...child.props.style,
        position: "absolute",
        top: `${position.y}px`,
        left: `${position.x}px`,
        cursor: disabled ? "initial" : moving ? "grab" : "move",
        willChange: "top, left",
      },
    })
  );
}

export default MoveableView;
