import { useRef, useState } from "react";
import useZoomPanEditor from "../zoomPanEditor/useZoomPanEditor";
import FlowChartEditorView from "./FlowChartEditorView";
import useCustomEffect from "../../hooks/useCustomEffect";

function FlowChartEditor({
  nodes = [],
  onNodesChange = (nodes) => {},
  connections = [],
  onConnectionsChange = (connections) => {},
  children,
  customControls,
}) {
  const zoomPanController = useZoomPanEditor({
    onlyAllowBackgroundInteraction: true,
  });

  const [refs, setRefs] = useState(["mouse_position_ref"]);
  const [selectedConnector, setSelectedConnector] = useState(null);

  const cursorRef = useRef(null);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  /**
   * UseEffect for window events
   */
  useCustomEffect(() => {
    window.addEventListener("click", onWindowClick);
    window.addEventListener("keydown", onKeyPress);
    return () => {
      window.removeEventListener("click", onWindowClick);
      window.removeEventListener("keydown", onKeyPress);
    };
  }, [nodes, connections]);

  /**
   * UseEffect for the cursor
   */
  useCustomEffect(() => {
    window.addEventListener("mousemove", onMouseMove);
    setRefs((prevRefs) => {
      return {
        ...prevRefs,
        "[CURSOR]": cursorRef.current,
      };
    });

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      setRefs((prevRefs) => {
        const newRefs = { ...prevRefs };
        delete newRefs["[CURSOR]"];
        return newRefs;
      });
    };
  }, [zoomPanController.data.scale]);

  /**
   * UseEffect for refreshing on zoom change
   */
  useCustomEffect(() => {
    setCursorPosition((prev) => ({ ...prev }));
  }, [zoomPanController.data.scale]);

  /**
   * This method will handle the connector click event
   * @param {string} id
   */
  function onConnectorClick(id) {
    //If there is no selected connector, set the selected connector
    if (!selectedConnector || selectedConnector === id) {
      setSelectedConnector(id);
      return;
    }

    //Add the connection
    onConnectionAdd(selectedConnector, id);
    setSelectedConnector(null);
  }

  /**
   * This method will handle the connection add event
   * @param {string} fromId
   * @param {string} toId
   */
  function onConnectionAdd(fromId, toId) {
    onConnectionsChange((prev) => {
      const newConnections = [...prev];

      newConnections.push({
        id: `${fromId}-${toId}`,
        fromConnectorId: fromId,
        toConnectorId: toId,
      });

      return newConnections;
    });
  }

  /**
   * This method will handle the node position change event
   * @param {string} id
   * @param {object} position
   */
  function onNodePositionChange(id, position) {
    onNodesChange((prev) => {
      const newNodes = [...prev];
      const nodeIndex = newNodes.findIndex((node) => node.id === id);
      newNodes[nodeIndex] = { ...newNodes[nodeIndex], position };
      return newNodes;
    });
  }

  /**
   * This method will handle the connection click event
   * @param {string} id
   */
  function onConnectionClick(id) {
    onConnectionsChange((prev) => {
      const newConnections = [...prev];

      newConnections.forEach((connection, index) => {
        newConnections[index] = {
          ...newConnections[index],
          active: connection.id === id,
        };
      });

      return newConnections;
    });
  }

  /**
   * This method will handle the node click
   * @param {string} id
   */
  function onNodeClick(id) {
    onNodesChange((prev) => {
      const newNodes = [...prev];

      newNodes.forEach((node, index) => {
        newNodes[index] = {
          ...newNodes[index],
          active: node.id === id,
        };
      });

      return newNodes;
    });
  }

  /**
   * This method will handle the window click event
   * @param {event} e
   */
  function onWindowClick(e) {
    //Only handle the click if the editor contains the target
    if (!zoomPanController.editorRef.current?.contains(e.target)) {
      return;
    }

    //If the click is not on the connector, set the selected connector to null
    if (!e.target.id.includes("[CONNECTOR]")) {
      setSelectedConnector(null);
    }

    //If the click is not on the connection, set all connections to inactive
    if (!e.target.id.includes("[CONNECTION]")) {
      onConnectionsChange((prev) => {
        const newConnections = [...prev];
        newConnections.forEach((connection, index) => {
          newConnections[index] = { ...newConnections[index], active: false };
        });
        return newConnections;
      });
    }

    //If the click is not on the node, set all nodes to inactive
    if (!e.target.id.includes("[NODE]")) {
      onNodesChange((prev) => {
        const newNodes = [...prev];
        newNodes.forEach((node, index) => {
          newNodes[index] = { ...newNodes[index], active: false };
        });
        return newNodes;
      });
    }
  }

  /**
   * This method will handle the key press event
   * @param {event} e
   */
  function onKeyPress(e) {
    if (e.key === "Delete") {
      const activeNodes = nodes.filter((node) => node.active);

      onConnectionsChange((prev) => {
        return prev.filter((connection) => {
          return (
            !connection.active &&
            !activeNodes.some((node) => {
              return (
                connection.fromConnectorId.includes(node.id) ||
                connection.toConnectorId.includes(node.id)
              );
            })
          );
        });
      });

      onNodesChange((prev) => {
        return prev.filter(
          (node) => !node.active || !(node.removeable ?? true)
        );
      });
    }
  }

  /**
   * This method will handle the mouse move event
   * @param {event} e
   */
  function onMouseMove(e) {
    if (!zoomPanController.editorContentRef.current) {
      return;
    }

    const editorScale = zoomPanController.data.scale;
    const editorPos = zoomPanController.editorContentRef.current
      ? zoomPanController.editorContentRef.current.getBoundingClientRect()
      : { x: 0, y: 0 };

    setCursorPosition({
      x: (e.clientX - editorPos.x) / editorScale,
      y: (e.clientY - editorPos.y) / editorScale,
    });
  }

  return (
    <FlowChartEditorView
      nodes={nodes}
      connections={connections}
      selectedConnector={selectedConnector}
      zoomPanController={zoomPanController}
      refs={refs}
      setRefs={setRefs}
      onConnectorClick={onConnectorClick}
      onConnectionClick={onConnectionClick}
      onNodeClick={onNodeClick}
      onNodePositionChange={onNodePositionChange}
      cursorRef={cursorRef}
      cursorPosition={cursorPosition}
      customControls={customControls}
    >
      {children}
    </FlowChartEditorView>
  );
}

export default FlowChartEditor;
