import useCustomEffect from "../../../../../../hooks/useCustomEffect";
import useEvaApi, { EvaApiCall } from "../../../../../../hooks/useEvaApi";
import EmployeeStepView from "./EmployeeStepView";

function EmployeeStep({
  errors,
  visitorData,
  setVisitorData,
  fetchedEntities,
  setFetchedEntities,
}) {
  const callEvaApi = useEvaApi();

  /**
   * UseEffect for automatically fetching the employees if it's not set
   */
  useCustomEffect(() => {
    fetchEmployees();
  });

  /**
   * This method will fetch the employees from the API
   */
  async function fetchEmployees() {
    await callEvaApi(
      new EvaApiCall("employees")
        .setLoadingGroup("visitor_type_page_fetch")
        .setParams({
          where: [
            {
              column: "id",
              values: [
                {
                  operator: "not in",
                  value: fetchedEntities.employees.map(
                    (employee) => employee.id
                  ),
                },
              ],
            },
          ],
        })
        .setOnSuccess((response) => {
          setFetchedEntities((prev) => ({
            ...prev,
            employees: [...prev.employees, ...response.data.data.records],
          }));
        })
    );
  }

  /**
   * This method will handle the employee id change event
   * @param {any} value
   */
  function onEmployeeIdChange(value) {
    setVisitorData((prev) => ({ ...prev, employee_id: value }));
  }

  return (
    <EmployeeStepView
      errors={errors}
      visitorData={visitorData}
      employees={fetchedEntities.employees}
      onEmployeeIdChange={onEmployeeIdChange}
    />
  );
}

export default EmployeeStep;
