import FieldsForm from "./fields/FieldsForm";
import HouserulesForm from "./houserules/HouserulesForm";
import MessagesForm from "./messages/MessagesForm";

export function getSelectedFormConfig(selectedStep, selectedConnection) {
  if (selectedStep) {
    switch (selectedStep.type) {
      case "start":
        return { form: null, removeable: false };
      case "process_data":
        return { form: null, removeable: false };
      case "fields":
        return { form: FieldsForm, hasForceShowInput: true };
      case "houserules":
        return { form: HouserulesForm, hasForceShowInput: true };
      case "messages":
        return { form: MessagesForm, hasForceShowInput: true };
      case "employee":
        return { form: null, hasForceShowInput: true };
      default:
        return null;
    }
  }

  if (selectedConnection) {
    switch (selectedConnection.type) {
      default:
        return null;
    }
  }

  return null;
}
