import { useParams } from "react-router-dom";
import { useRouteContext } from "../../../../context/RouteContext";
import useEvaApi, { EvaApiCall } from "../../../../hooks/useEvaApi";
import FormView from "./FormView";

function Form({ flowData, setFlowData, submitError, setSubmitError }) {
  const routeContext = useRouteContext();
  const { flow } = useParams();
  const callEvaApi = useEvaApi();

  /**
   * This method will handle the name change
   * @param {event} e
   */
  function onNameChange(e) {
    setFlowData({
      ...flowData,
      name: e.target.value,
    });
  }

  /**
   * This method will handle the cancel event
   */
  function onCancel() {
    routeContext.back();
  }

  /**
   * This method will handle the submit event
   */
  async function onSubmit() {
    await callEvaApi(
      new EvaApiCall(flow ? `flows/${flow}` : "flows")
        .setMethod(flow ? "PUT" : "POST")
        .setLoadingGroup("flow_page_submit")
        .setData(flowData)
        .setErrorState(setSubmitError)
        .setAlertError(true)
        .setRedirectOnSuccess(true)
    );
  }

  return (
    <FormView
      flowData={flowData}
      onNameChange={onNameChange}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitError={submitError}
    />
  );
}

export default Form;
