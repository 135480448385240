import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLanguageContext } from "../../../context/LanguageContext";
import { useCalamityContext } from "../../../context/CalamityContext";
import {
  StyledCalamityIndicator,
  StyledCalamityIndicatorIcon,
} from "./CalamityIndicatorStyles";
import { Link } from "react-router-dom";

function CalamityIndicatorView() {
  const { calamities } = useCalamityContext();
  const { translate } = useLanguageContext();

  return (
    calamities.length > 0 && (
      <Link to="/dashboard/calamities">
        <StyledCalamityIndicator>
          <StyledCalamityIndicatorIcon>
            <FontAwesomeIcon icon={faExclamationTriangle} size="lg" />
          </StyledCalamityIndicatorIcon>

          <p>
            {translate("eva.main.messages.calamity.active")} (
            {calamities.map((calamity) => calamity.location.name).join(", ")})
          </p>
        </StyledCalamityIndicator>
      </Link>
    )
  );
}

export default CalamityIndicatorView;
