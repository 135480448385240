import { useState } from "react";
import useCustomEffect from "../../../hooks/useCustomEffect";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";
import VisitorPageView from "./VisitorPageView";
import { useRouteContext } from "../../../context/RouteContext";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../../../context/AuthContext";
import { useLanguageContext } from "../../../context/LanguageContext";

function VisitorPage() {
  const authContext = useAuthContext();
  const { defaultLanguage } = useLanguageContext();
  const routeContext = useRouteContext();
  const { visitor } = useParams();
  const [submitError, setSubmitError] = useState(null);
  const [visitorTypes, setVisitorTypes] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [visitorData, setVisitorData] = useState({
    location_id:
      authContext.auth.location ??
      authContext.auth.user.company.locations[0].id,
    visitor_type_id: null,
    language_id: defaultLanguage?.id,
    email: "",
    status: "expected",
    fetchedStatus: null,
    expected_date: null,
    expected_time: null,
    returning: false,
    expected_end_date: null,
  });
  const visitorType = visitorTypes.find(
    (type) => type.id === visitorData.visitor_type_id
  );
  const callEvaApi = useEvaApi();

  /**
   * UseEffect for initializing the page
   */
  useCustomEffect(() => {
    fetchVisitorTypes();
    fetchEmployees();

    if (visitor) {
      fetchVisitorData();
    }
  });

  /**
   * This method will fetch the visitor types from the API.
   */
  async function fetchVisitorTypes() {
    await callEvaApi(
      new EvaApiCall("visitor_types")
        .setParams({
          with_visitor_type_sign_in_flow_data: true,
          with_flow_steps_data: true,
          with_flow_step_fields_data: true,
          with_flow_step_field_field_data: true,
          with_field_options_data: true,
        })
        .setLoadingGroup("visitor_page_fetch")
        .setRedirectOnError(true)
        .setOnSuccess((response) => {
          setVisitorTypes(response.data.data.records);

          if (!visitor) {
            setVisitorData((prev) => ({
              ...prev,
              visitor_type_id: response.data.data.records[0]?.id,
            }));
          }
        })
    );
  }

  /**
   * This method will fetch the employees from the API
   */
  async function fetchEmployees() {
    await callEvaApi(
      new EvaApiCall("employees")
        .setLoadingGroup("visitor_page_fetch")
        .setRedirectOnError(true)
        .setOnSuccess((response) => {
          setEmployees(response.data.data.records);
        })
    );
  }

  /**
   * This method will fetch the visitor data from the API
   */
  async function fetchVisitorData() {
    await callEvaApi(
      new EvaApiCall(`visitors/${visitor}`)
        .setLoadingGroup("visitor_page_fetch")
        .setParams({
          with_visitor_fields_data: true,
        })
        .setRedirectOnError(true)
        .setOnSuccess((response) => {
          const newVisitorData = {
            location_id: response.data.data.location,
            visitor_type_id: response.data.data.visitor_type,
            employee_id: response.data.data.employee,
            language_id: response.data.data.language ?? defaultLanguage?.id,
            email: response.data.data.email,
            status: response.data.data.status,
            fetchedStatus: response.data.data.status,
            expected_date: response.data.data.expected_date,
            expected_time: response.data.data.expected_time,
            returning: response.data.data.returning,
            expected_end_date: response.data.data.expected_end_date,
          };
          response.data.data.fields.forEach((fieldItem) => {
            newVisitorData[`field_${fieldItem.field}`] =
              fieldItem.value ?? fieldItem.field_option;
          });
          setVisitorData(newVisitorData);
        })
    );
  }

  /**
   * This method will handle the location change event
   * @param {any} value
   */
  function onLocationChange(value) {
    setVisitorData({
      ...visitorData,
      location_id: value,
    });
  }

  /**
   * This method will handle the visitor type change event
   * @param {any} value
   */
  function onVisitorTypeChange(value) {
    setVisitorData({
      ...visitorData,
      visitor_type_id: value,
    });
  }

  /**
   * This method will handle the language change event
   * @param {int} value
   */
  function onLanguageChange(value) {
    setVisitorData({
      ...visitorData,
      language_id: value,
    });
  }

  /**
   * This method will handle the employee id change event
   * @param {any} value
   */
  function onEmployeeIdChange(value) {
    setVisitorData({
      ...visitorData,
      employee_id: value,
    });
  }

  /**
   * This method will handle the status change event
   * @param {any} value
   */
  function onStatusChange(value) {
    setVisitorData({
      ...visitorData,
      status: value,
    });
  }

  /**
   * This method will handle the expected date change event
   * @param {event} e
   */
  function onExpectedDateChange(e) {
    setVisitorData({
      ...visitorData,
      expected_date: e.target.value,
    });
  }

  /**
   * This method will handle the expected time change event
   * @param {event} e
   */
  function onExpectedTimeChange(e) {
    setVisitorData({
      ...visitorData,
      expected_time: e.target.value,
    });
  }

  /**
   * This method will handle the returning change event
   * @param {boolean} value
   */
  function onReturningChange(value) {
    setVisitorData({
      ...visitorData,
      returning: value,
    });
  }

  /**
   * This method will handle the email change event
   * @param {event} e
   */
  function onEmailChange(e) {
    setVisitorData({
      ...visitorData,
      email: e.target.value,
    });
  }

  /**
   * This method will handle the expected end date change event
   * @param {event} e
   */
  function onExpectedEndDateChange(e) {
    setVisitorData({
      ...visitorData,
      expected_end_date: e.target.value,
    });
  }

  /**
   * This method will handle the flow field change event
   * @param {object} stepField
   * @param {any} value
   */
  function onFlowFieldChange(stepField, value) {
    setVisitorData({
      ...visitorData,
      [`field_${stepField.field.id}`]: value,
    });
  }

  /**
   * This method will handle the cancel event
   */
  function onCancel() {
    routeContext.back();
  }

  /**
   * This method will handle the submit event
   */
  async function onSubmit() {
    await callEvaApi(
      new EvaApiCall(visitor ? `visitors/${visitor}` : "visitors")
        .setMethod(visitor ? "PUT" : "POST")
        .setLoadingGroup("visitor_page_submit")
        .setData(visitorData)
        .setErrorState(setSubmitError)
        .setRedirectOnSuccess(true)
    );
  }

  return (
    <VisitorPageView
      visitor={visitor}
      visitorData={visitorData}
      setVisitorData={setVisitorData}
      visitorType={visitorType}
      visitorTypes={visitorTypes}
      onVisitorTypeChange={onVisitorTypeChange}
      onLocationChange={onLocationChange}
      employees={employees}
      onEmployeeIdChange={onEmployeeIdChange}
      onLanguageChange={onLanguageChange}
      onStatusChange={onStatusChange}
      onReturningChange={onReturningChange}
      onEmailChange={onEmailChange}
      onExpectedDateChange={onExpectedDateChange}
      onExpectedTimeChange={onExpectedTimeChange}
      onExpectedEndDateChange={onExpectedEndDateChange}
      onFlowFieldChange={onFlowFieldChange}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitError={submitError}
    />
  );
}

export default VisitorPage;
