import { useState } from "react";
import VisitorTypePageView from "./VisitorTypePageView";
import useEvaApi, { EvaApiCall } from "../../../../hooks/useEvaApi";
import useCustomEffect from "../../../../hooks/useCustomEffect";
import { useFrontofficeContext } from "../../../../context/FrontofficeContext";

function VisitorTypePage() {
  const callEvaApi = useEvaApi();
  const frontofficeContext = useFrontofficeContext();

  const signIn = frontofficeContext.pageParams?.signIn ?? true;
  const simulated = frontofficeContext.pageParams?.simulated ?? false;

  const visitorType =
    frontofficeContext.pageParams?.visitorType ??
    frontofficeContext.pageParams?.visitor?.visitor_type;

  const [visitors, setVisitors] = useState([]);
  const [visitor, setVisitor] = useState(
    frontofficeContext.pageParams?.visitor
  );

  const [visitorData, setVisitorData] = useState({
    sign_in_terminal_id: frontofficeContext.terminal.id,
    visitor_type_id: visitorType?.id,
    visitor_id: visitor?.id,
    ...getVisitorDataFromVisitor(visitor),
  });

  const [step, setStep] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const [errors, setErrors] = useState({});

  const [disabledNavItems, setDisabledNavItems] = useState([]);

  const [fetchedEntities, setFetchedEntities] = useState({
    fields: [],
    employees: [],
  });

  /**
   * UseEffect for initializing
   */
  useCustomEffect(() => {
    fetchVisitors();

    if (!step) {
      onNextStep();
    }
  });

  /**
   * This method will fetch the possible visitors for the visitor type
   */
  async function fetchVisitors() {
    if (visitor) {
      return;
    }

    await callEvaApi(
      new EvaApiCall("visitors")
        .setLoadingGroup("visitor_type_page_fetch")
        .setParams({
          ...getVisitorFetchParams(),
          scheduled_only: true,
          where: [
            {
              column: "visitor_type_id",
              values: [visitorType?.id],
            },
          ],
        })
        .setOnSuccess((response) => {
          setVisitors(response.data.data.records);
        })
    );
  }

  /**
   * This method will return the visitor data from the visitor object
   * @param {object} visitor
   * @returns {object}
   */
  function getVisitorDataFromVisitor(visitor) {
    const data = {};
    if (!visitor) return data;

    // Set the visitor employee
    data.employee_id = visitor.employee;

    // Set the visitor fields
    visitor.fields.forEach((visField) => {
      data[`field_${visField.field}`] = visField.value ?? visField.field_option;
    });

    // Set the visitor houserules
    visitor.houserules.forEach((visHouserule) => {
      data[`houserules_${visHouserule.file}`] = true;
    });

    return data;
  }

  /**
   * This method will search for a matching visitor
   * @returns {object}
   */
  findMatchingVisitor();
  function findMatchingVisitor() {
    if (visitor) return;

    const possibleVisitors = frontofficeContext.filterVisitorsBySearch(
      frontofficeContext.getVisitorIdentifierString(visitorData, visitorType),
      visitors,
      true
    );

    if (possibleVisitors.length === 1) {
      const newVisitor = possibleVisitors[0];
      onVisitorChange(newVisitor);
    }
  }

  /**
   * This method will handle the visitor change event
   * @param {object} newVisitor
   */
  function onVisitorChange(newVisitor) {
    setVisitor(newVisitor);
    setVisitorData((prev) => ({
      ...prev,
      visitor_id: newVisitor.id,
      ...getVisitorDataFromVisitor(newVisitor),
    }));
  }

  /**
   * This method will try to get the next step
   */
  async function onNextStep(currentStep = null) {
    currentStep = currentStep ?? step;

    const flowId = signIn
      ? visitorType?.sign_in_flow
      : visitorType?.sign_out_flow;

    if (!flowId) {
      return;
    }

    await callEvaApi(
      new EvaApiCall(
        `flows/${flowId}/steps/next${currentStep ? `/${currentStep?.id}` : ""}`
      )
        .setLoadingGroup(
          currentStep ? "visitor_type_page_submit" : "visitor_type_page_fetch"
        )
        .setMethod("POST")
        .setParams(getStepFetchParams())
        .setErrorState(setErrors)
        .setData(visitorData)
        .setAlertError(false)
        .setAlertSuccess(false)
        .setOnSuccess((response) => {
          // If there is no new step, go back to the start page
          const newStep = response.data.data;
          if (!newStep) {
            frontofficeContext.updatePage("start");
            return;
          }

          //Update the step and reset the disabled nav items
          setStep(newStep);
          setDisabledNavItems([]);

          //If we reached the process data step, submit the form
          if (newStep.type === "process_data") {
            onSubmit(newStep);
            return;
          }

          // If the flow is simulated, fetch the next step
          if (simulated && !newStep.force_show) {
            onNextStep(newStep);
          }
        })
    );
  }

  /**
   * This method will try to go back to the previous step
   * @param {int|null} stepId
   */
  async function onPreviousStep() {
    await callEvaApi(
      new EvaApiCall(
        `flows/${
          signIn ? visitorType.sign_in_flow : visitorType.sign_out_flow
        }/steps/previous/${step?.id}`
      )
        .setLoadingGroup("visitor_type_page_submit")
        .setMethod("POST")
        .setParams(getStepFetchParams())
        .setAlertSuccess(false)
        .setOnSuccess((response) => {
          const newStep = response.data.data;
          if (!newStep) {
            frontofficeContext.updatePage("start");
            return;
          }

          setStep(newStep);
          setDisabledNavItems([]);
        })
    );
  }

  /**
   * This method will handle the flow completion event
   */
  async function onSubmit(currentStep = null) {
    currentStep = currentStep ?? step;

    await callEvaApi(
      new EvaApiCall(
        `visitors/${signIn ? "sign_in" : "sign_out"}${
          visitor?.id ? `/${visitor?.id}` : ""
        }`
      )
        .setLoadingGroup("visitor_type_page_submit")
        .setParams(getVisitorFetchParams())
        .setMethod("POST")
        .setAlertError(false)
        .setAlertSuccess(false)
        .setData(visitorData)
        .setOnSuccess((response) => {
          const newVisitor = response.data.data;
          onVisitorChange(newVisitor);
          onNextStep(currentStep);
          setSubmitted(true);
        })
    );
  }

  /**
   * This method will return the fetch params step
   * @returns {object}
   */
  function getStepFetchParams() {
    return {
      with_flow_step_fields_data: true,
      with_flow_step_field_field_data: true,
      with_flow_step_file_data: true,
      with_flow_step_messages_data: true,
      with_flow_step_message_message_template_data: true,
      with_field_options_data: true,
    };
  }

  /**
   * This method will return the fetch params for the visitor
   * @returns {object}
   */
  function getVisitorFetchParams() {
    return {
      with_visitor_fields_data: true,
      with_visitor_questions_data: true,
      with_visitor_houserules_data: true,
      with_visitor_visitor_type_data: true,
    };
  }

  return (
    <VisitorTypePageView
      step={step}
      errors={errors}
      visitor={visitor}
      visitorData={visitorData}
      visitorType={visitorType}
      submitted={submitted}
      disabledNavItems={disabledNavItems}
      fetchedEntities={fetchedEntities}
      setFetchedEntities={setFetchedEntities}
      setDisabledNavItems={setDisabledNavItems}
      setVisitorData={setVisitorData}
      onNextStep={onNextStep}
      onPreviousStep={onPreviousStep}
    />
  );
}

export default VisitorTypePage;
