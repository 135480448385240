import { useState } from "react";
import useCustomEffect from "../../../../../../hooks/useCustomEffect";
import HouserulesStepView from "./HouseruleStepView";

function HouserulesStep({
  step,
  visitorData,
  setVisitorData,
  setDisabledNavItems,
}) {
  const houseruleData = visitorData[`houserules_${step.file.id}`];
  const [read, setRead] = useState(houseruleData ?? false);

  /**
   * UseEffect for disabling the next button if the houserule is not accepted
   */
  useCustomEffect(() => {
    if (!houseruleData) {
      setDisabledNavItems(["next"]);
    } else {
      setDisabledNavItems([]);
    }
  }, [houseruleData]);

  /**
   * This method will handle the read event
   */
  function onRead() {
    setRead(true);
  }

  /**
   * This method will handle the accept change event
   * @param {bool} value
   */
  function onAcceptChange(value) {
    setVisitorData((prevData) => ({
      ...prevData,
      [`houserules_${step.file.id}`]: value,
    }));
  }

  return (
    <HouserulesStepView
      step={step}
      visitorData={visitorData}
      read={read}
      houseruleData={houseruleData}
      onRead={onRead}
      onAcceptChange={onAcceptChange}
    />
  );
}

export default HouserulesStep;
