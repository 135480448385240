import FormContainer from "../../../components/formContainer/FormContainer";
import FlowFieldInput from "../../../components/input/flow/field/FlowFieldInput";
import InputWrapper from "../../../components/input/wrapper/InputWrapper";
import OverlayLoader from "../../../components/loader/OverlayLoader/OverlayLoader";
import LocationFormSelect from "../../../components/select/custom/location/form/LocationFormSelect";
import Select from "../../../components/select/main/Select";
import { useLanguageContext } from "../../../context/LanguageContext";
import EVAApiHelper from "../../../helpers/EVAApiHelper";
import { StyledInput } from "../../../styles/InputStyles";
import {
  StyledFlexColumn,
  StyledFlexItem,
  StyledFlexRow,
} from "../../../styles/LayoutStyles";

function VisitorPageView({
  visitor,
  visitorData,
  visitorType,
  visitorTypes,
  onVisitorTypeChange,
  onLocationChange,
  employees,
  onEmployeeIdChange,
  onLanguageChange,
  onStatusChange,
  onReturningChange,
  onEmailChange,
  onExpectedDateChange,
  onExpectedEndDateChange,
  onExpectedTimeChange,
  onFlowFieldChange,
  onCancel,
  onSubmit,
  submitError,
}) {
  const { languages } = useLanguageContext();
  const { translate, translateEntity } = useLanguageContext();

  return (
    <OverlayLoader groups={"visitor_page_fetch"}>
      <FormContainer
        onCancel={onCancel}
        onSubmit={onSubmit}
        submitError={submitError}
        submitLoadingGroup={"visitor_page_submit"}
      >
        <StyledFlexColumn>
          {/* Location */}
          <InputWrapper
            label={translate("eva.main.validation.attributes.location_id")}
            required={true}
            error={EVAApiHelper.getResponseError("location_id", submitError)}
          >
            <LocationFormSelect
              value={visitorData.location_id}
              onChange={onLocationChange}
              useNullOption={false}
              disabled={visitor !== undefined}
            />
          </InputWrapper>

          {/* Visitor type */}
          <InputWrapper
            label={translate("eva.main.validation.attributes.visitor_type_id")}
            required={true}
            error={EVAApiHelper.getResponseError(
              "visitor_type_id",
              submitError
            )}
          >
            <Select
              value={visitorData.visitor_type_id}
              options={visitorTypes.map((type) => ({
                value: type.id,
                label: translateEntity(type, "title"),
              }))}
              onChange={onVisitorTypeChange}
            />
          </InputWrapper>

          {/* Language */}
          <InputWrapper
            label={translate("eva.main.validation.attributes.language_id")}
            required={true}
            error={EVAApiHelper.getResponseError("language_id", submitError)}
          >
            <Select
              value={visitorData.language_id}
              options={languages.map((lang) => ({
                value: lang.id,
                label: lang.label,
              }))}
              onChange={onLanguageChange}
            />
          </InputWrapper>

          {/* Status */}
          <InputWrapper
            label={translate("eva.main.validation.attributes.status")}
            required={true}
            error={EVAApiHelper.getResponseError("status", submitError)}
          >
            <Select
              value={visitorData.status}
              options={[
                {
                  label: translate("eva.main.visitor_statuses.expected"),
                  value: "expected",
                },
                {
                  label: translate("eva.main.visitor_statuses.signed_in"),
                  value: "signed_in",
                },
              ]}
              disabled={visitorData.fetchedStatus === "signed_in"}
              onChange={onStatusChange}
            />
          </InputWrapper>

          {/* Returning */}
          <InputWrapper
            label={translate("eva.main.validation.attributes.returning")}
            required
            error={EVAApiHelper.getResponseError("returning", submitError)}
          >
            <Select
              value={visitorData.returning}
              options={[
                { value: true, label: translate("eva.main.general.yes") },
                { value: false, label: translate("eva.main.general.no") },
              ]}
              onChange={onReturningChange}
            />
          </InputWrapper>

          {/* Expected date and expected end date */}
          {(visitorData.status !== "signed_in" || visitorData.returning) && (
            <StyledFlexRow>
              {visitorData.status !== "signed_in" && (
                <StyledFlexItem>
                  <InputWrapper
                    label={translate(
                      "eva.main.validation.attributes.expected_date"
                    )}
                    required={visitorData.status !== "signed_in"}
                    error={EVAApiHelper.getResponseError(
                      "expected_date",
                      submitError
                    )}
                  >
                    <StyledInput
                      type="date"
                      value={visitorData.expected_date}
                      onChange={onExpectedDateChange}
                      min={new Date().toISOString().split("T")[0]}
                    />
                  </InputWrapper>
                </StyledFlexItem>
              )}

              {visitorData.returning && (
                <StyledFlexItem>
                  <InputWrapper
                    label={translate(
                      "eva.main.validation.attributes.expected_end_date"
                    )}
                    required={visitorData.returning}
                    error={EVAApiHelper.getResponseError(
                      "expected_end_date",
                      submitError
                    )}
                  >
                    <StyledInput
                      type="date"
                      value={visitorData.expected_end_date}
                      onChange={onExpectedEndDateChange}
                      min={
                        new Date(visitorData.expected_date)
                          .toISOString()
                          .split("T")[0]
                      }
                    />
                  </InputWrapper>
                </StyledFlexItem>
              )}
            </StyledFlexRow>
          )}

          {/* Expected time */}
          {visitorData.status !== "signed_in" && (
            <InputWrapper
              label={translate("eva.main.validation.attributes.expected_time")}
              error={EVAApiHelper.getResponseError(
                "expected_time",
                submitError
              )}
            >
              <StyledInput
                type="time"
                value={visitorData.expected_time}
                onChange={onExpectedTimeChange}
              />
            </InputWrapper>
          )}

          {/* Email */}
          <InputWrapper
            label={translate("eva.main.validation.attributes.email")}
            error={EVAApiHelper.getResponseError("email", submitError)}
          >
            <StyledInput
              type="text"
              value={visitorData.email}
              onChange={onEmailChange}
              placeholder={translate("eva.main.validation.placeholders.email")}
            />
          </InputWrapper>

          {/* Flow steps */}
          {visitorType?.sign_in_flow?.steps.map((step) => {
            switch (step.type) {
              case "fields":
                return step.fields.map((row, index) => (
                  <StyledFlexRow key={index}>
                    {row.map((stepField) => (
                      <StyledFlexItem key={stepField.id}>
                        <InputWrapper
                          label={translateEntity(stepField.field, "label")}
                          error={EVAApiHelper.getResponseError(
                            `field_${stepField.field.id}`,
                            submitError
                          )}
                          required={stepField.required_backoffice}
                        >
                          <FlowFieldInput
                            flowField={stepField}
                            value={visitorData[`field_${stepField.field.id}`]}
                            onChange={onFlowFieldChange}
                          />
                        </InputWrapper>
                      </StyledFlexItem>
                    ))}
                  </StyledFlexRow>
                ));

              case "employee":
                return (
                  <InputWrapper
                    required={true}
                    label={translate(
                      "eva.main.validation.attributes.employee_id"
                    )}
                    error={EVAApiHelper.getResponseError(
                      "employee_id",
                      submitError
                    )}
                  >
                    <Select
                      value={visitorData.employee_id}
                      options={employees.map((employee) => ({
                        value: employee.id,
                        label: employee.name,
                      }))}
                      onChange={onEmployeeIdChange}
                    />
                  </InputWrapper>
                );

              default:
                return "";
            }
          })}
        </StyledFlexColumn>
      </FormContainer>
    </OverlayLoader>
  );
}

export default VisitorPageView;
