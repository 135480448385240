import { useTheme } from "styled-components";
import Layout from "../../../../components/layout/Layout";
import { useLanguageContext } from "../../../../context/LanguageContext";
import { StyledFlexColumn } from "../../../../styles/LayoutStyles";
import { StyledAltButton, StyledButton } from "../../../../styles/ButtonStyles";
import { useFrontofficeContext } from "../../../../context/FrontofficeContext";

function SignInPageView() {
  const theme = useTheme();
  const { updatePage, terminal } = useFrontofficeContext();
  const { translateEntity, translate } = useLanguageContext();

  return (
    <Layout
      layoutData={terminal?.layout}
      content={
        <StyledFlexColumn style={{ gap: theme.spacing.large }}>
          <StyledFlexColumn>
            <StyledButton onClick={() => updatePage("registration_code")}>
              {translate(
                "eva.frontoffice.pages.registration_code.label_sign_in"
              )}
            </StyledButton>

            {terminal?.visitor_types
              ?.sort((a, b) => a.order - b.order)
              ?.map((visitorType) => (
                <StyledButton
                  key={visitorType.id}
                  onClick={() =>
                    updatePage("visitor_type", {
                      visitorType: visitorType,
                    })
                  }
                >
                  {translateEntity(visitorType, "title")}
                </StyledButton>
              ))}
          </StyledFlexColumn>

          <StyledAltButton onClick={() => updatePage("start")}>
            {translate("eva.frontoffice.general.back")}
          </StyledAltButton>
        </StyledFlexColumn>
      }
    />
  );
}

export default SignInPageView;
