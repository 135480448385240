import { StyledSpecialNode } from "./NodeStyles";

export function getNodeConfig(type) {
  const nodes = {
    start: StartNode,
    process_data: ProcessDataNode,
  };
  return nodes[type] ?? DefaultNode;
}

export const DefaultNode = {
  maxInConnections: 1,
  maxOutConnections: 1,
  connectors: [{ position: "top" }, { position: "bottom" }],
};

export const StartNode = {
  maxInConnections: 0,
  maxOutConnections: 1,
  Element: StyledSpecialNode,
  moveable: false,
  removeable: false,
  connectors: [{ position: "bottom" }],
};

export const ProcessDataNode = {
  maxInConnections: 1,
  maxOutConnections: 1,
  Element: StyledSpecialNode,
  removeable: false,
  connectors: [{ position: "top" }, { position: "bottom" }],
};
