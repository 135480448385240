import { useAuthContext } from "../../context/AuthContext";
import { useCalamityContext } from "../../context/CalamityContext";
import { useLanguageContext } from "../../context/LanguageContext";
import { StyledButton } from "../../styles/ButtonStyles";
import { StyledRoundedOverflowXWrapper } from "../../styles/WrapperStyles";
import HiddenLoader from "../loader/HiddenLoader/HiddenLoader";

function CalamityTableView({ onCalamityStop }) {
  const { translate, translateEntity } = useLanguageContext();
  const authContext = useAuthContext();
  const calamityContext = useCalamityContext();

  return (
    <StyledRoundedOverflowXWrapper>
      <table>
        <thead>
          <tr>
            <th>{translate("eva.main.entities.location")}</th>
            <th>{translate("eva.main.validation.attributes.activated_by")}</th>
            <th>{translate("eva.main.entities.calamity_type")}</th>
            <th>{translate("eva.main.entities.calamity_tool")}</th>
            <th>{translate("eva.main.validation.attributes.comment")}</th>
            <th style={{ width: "0" }}></th>
          </tr>
        </thead>

        <tbody>
          {calamityContext.calamities.map((calamity) => {
            return (
              <tr key={calamity.id}>
                <td>{calamity.location.name}</td>
                <td>{calamity.user?.name}</td>
                <td>
                  {calamity.calamity_type
                    ? translateEntity(calamity.calamity_type, "label")
                    : ""}
                </td>
                <td>
                  {calamity.calamity_tool
                    ? translateEntity(calamity.calamity_type, "label")
                    : ""}
                </td>
                <td>{calamity?.comment}</td>
                <td>
                  <HiddenLoader groups={`calamities_stop_page_${calamity?.id}`}>
                    <StyledButton
                      onClick={() => onCalamityStop(calamity)}
                      disabled={!authContext.hasPermission("calamities.delete")}
                    >
                      {translate("eva.main.general.stop")}
                    </StyledButton>
                  </HiddenLoader>
                </td>
              </tr>
            );
          })}

          {calamityContext.calamities.length === 0 && (
            <tr>
              <td colSpan={"100%"} style={{ textAlign: "center" }}>
                {translate("eva.main.general.no_results")}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </StyledRoundedOverflowXWrapper>
  );
}

export default CalamityTableView;
