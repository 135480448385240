import { useRef } from "react";
import { useDraggableContext } from "../../../../../../context/DraggableContext";
import FieldsFormView from "./FieldsFormView";

function FieldsForm({
  relations,
  selectedStep,
  selectedStepIndex,
  setFlowData,
  submitError,
  setSubmitError,
}) {
  const draggableContext = useDraggableContext();
  const lastDragSwitchTime = useRef(0);
  const fieldOptions = [
    "required_backoffice",
    "required_terminal",
    "hidden_outlook",
  ];

  /**
   * This method will handle the add row event
   */
  function onAddRow() {
    setSubmitError(null);
    setFlowData((prev) => ({
      ...prev,
      steps: prev.steps.map((step) => {
        if (step.id === selectedStep.id) {
          return {
            ...step,
            fields: step.fields ? [...step.fields, []] : [[]],
          };
        }

        return step;
      }),
    }));
  }

  /**
   * This method will handle the delete row event.
   * @param {int} index
   */
  function onDeleteRow(index) {
    setSubmitError(null);
    setFlowData((prev) => ({
      ...prev,
      steps: prev.steps.map((step) => {
        if (step.id === selectedStep.id) {
          const fields = [...step.fields];
          fields.splice(index, 1);

          return {
            ...step,
            fields,
          };
        }

        return step;
      }),
    }));
  }

  /**
   *
   * @param {*} rowIndex
   * @param {*} fieldId
   */
  function onAddField(rowIndex, fieldId) {
    setSubmitError(null);
    setFlowData((prev) => ({
      ...prev,
      steps: prev.steps.map((step) => {
        if (step.id === selectedStep.id) {
          return {
            ...step,
            fields: step.fields.map((fields, index) => {
              if (index === rowIndex) {
                return [
                  ...fields,
                  {
                    field_id: fieldId,
                    required_terminal: false,
                    required_backoffice: false,
                    hidden_outlook: false,
                  },
                ];
              }

              return fields;
            }),
          };
        }

        return step;
      }),
    }));
  }

  /**
   * This method will handle the delete field event.
   * @param {int} rowIndex
   * @param {int} fieldIndex
   */
  function onDeleteField(rowIndex, fieldIndex) {
    setSubmitError(null);
    setFlowData((prev) => ({
      ...prev,
      steps: prev.steps.map((step) => {
        if (step.id === selectedStep.id) {
          return {
            ...step,
            fields: step.fields.map((fields, index) => {
              if (index === rowIndex) {
                const newFields = [...fields];
                newFields.splice(fieldIndex, 1);

                return newFields;
              }

              return fields;
            }),
          };
        }

        return step;
      }),
    }));
  }

  /**
   * This method will handle the field options change event.
   * @param {int} rowIndex
   * @param {int} fieldIndex
   * @param {array} options
   */
  function onFieldOptionsChange(rowIndex, fieldIndex, options) {
    setFlowData((prev) => ({
      ...prev,
      steps: prev.steps.map((step) => {
        if (step.id === selectedStep.id) {
          return {
            ...step,
            fields: step.fields.map((fields, index) => {
              if (index === rowIndex) {
                return fields.map((field, fIndex) => {
                  if (fIndex === fieldIndex) {
                    const newField = { ...field };
                    fieldOptions.forEach((option) => {
                      newField[option] = options.includes(option);
                    });

                    return newField;
                  }

                  return field;
                });
              }

              return fields;
            }),
          };
        }

        return step;
      }),
    }));
  }

  /**
   * This method will handle the drag enter event.
   * @param {string} dragged
   * @param {string} target
   */
  function onDragEnter(dragged, target) {
    //Dont do anything if the last drag switch was less than 100ms ago.
    if (Date.now() - lastDragSwitchTime.current < 100) {
      return;
    }

    setSubmitError(null);

    //Get the coords of the dragged and target elements.
    const draggedCoords = dragged.split("_")[2].split(",");
    const targetCoords = target.split("_")[2].split(",");

    //Re-insert the dragged element to the target position.
    setFlowData((prev) => ({
      ...prev,
      steps: prev.steps.map((step) => {
        if (step.id === selectedStep.id) {
          const fields = [...step.fields];

          //Row drag
          if (draggedCoords.length === 1) {
            const draggedRow = fields[draggedCoords[0]];
            fields.splice(draggedCoords[0], 1);
            fields.splice(targetCoords[0], 0, draggedRow);
          }

          //Field drag
          else {
            const draggedField = fields[draggedCoords[0]][draggedCoords[1]];
            fields[draggedCoords[0]].splice(draggedCoords[1], 1);

            const targetFieldIndex =
              targetCoords.length === 1 ? 0 : targetCoords[1];
            fields[targetCoords[0]].splice(targetFieldIndex, 0, draggedField);
          }

          return {
            ...step,
            fields,
          };
        }

        return step;
      }),
    }));

    //Make sure to set the dragged id to the target id.
    draggableContext.updateDragged(
      targetCoords.length < draggedCoords.length
        ? `${target},0`
        : targetCoords.length > draggedCoords.length
        ? target.split(",")[0]
        : target
    );

    //Update the last drag switch time.
    lastDragSwitchTime.current = Date.now();
  }

  /**
   * This method will prepare the given step for the view.
   * @param {object} step
   */
  function prepareStepView(step) {
    return {
      ...step,
      fields: step.fields?.map((row) =>
        row?.map((field) => {
          const options = [];
          fieldOptions.forEach((option) => {
            if (field[option]) {
              options.push(option);
            }
          });

          return {
            ...field,
            options,
          };
        })
      ),
    };
  }

  return (
    <FieldsFormView
      relations={relations}
      selectedStep={prepareStepView(selectedStep)}
      selectedStepIndex={selectedStepIndex}
      onAddRow={onAddRow}
      onDeleteRow={onDeleteRow}
      fieldOptions={fieldOptions}
      onAddField={onAddField}
      onDeleteField={onDeleteField}
      onFieldOptionsChange={onFieldOptionsChange}
      onDragEnter={onDragEnter}
      submitError={submitError}
    />
  );
}

export default FieldsForm;
