import { Document, Page, pdfjs } from "react-pdf";
import { StyledLoader, StyledPdfViewer } from "./PdfViewerStyles";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

pdfjs.GlobalWorkerOptions.workerSrc = "/dist/pdf.worker.mjs";

function PdfViewerView({
  url,
  onDocumentLoaded,
  numPages,
  containerRef,
  pageWidth,
}) {
  return (
    <StyledPdfViewer ref={containerRef}>
      {!numPages && (
        <StyledLoader>
          <FontAwesomeIcon icon={faSpinner} size="2xl" spin />
        </StyledLoader>
      )}

      <Document file={url} onLoadSuccess={onDocumentLoaded} loading={false}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            width={pageWidth || undefined}
          />
        ))}
      </Document>
    </StyledPdfViewer>
  );
}

export default PdfViewerView;
