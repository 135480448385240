class RandomizeHelper {
  /**
   * This method will return a random uuid
   * @returns {string} uuid
   */
  static getUUID() {
    const arr = new Uint8Array(16);
    crypto.getRandomValues(arr);

    arr[6] = (arr[6] & 0x0f) | 0x40;
    arr[8] = (arr[8] & 0x3f) | 0x80;

    return [...arr]
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("")
      .replace(/^(.{8})(.{4})(.{4})(.{4})(.{12})$/, "$1-$2-$3-$4-$5");
  }
}

export default RandomizeHelper;
