import { useLanguageContext } from "../../context/LanguageContext";
import AvailablePermissionsPage from "../../pages/available_permissions/AvailablePermissionsPage";
import useAvailablePermissionsRouteValidator from "../validators/AvailablePermissionsRouteValidator";
import { RouteConfig } from "./RouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useAvailablePermissionsRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/available_permissions")
    .setLabel(translate("eva.main.entities.available_permissions"))
    .setValidator(useAvailablePermissionsRouteValidator())
    .setPage(<AvailablePermissionsPage />);
}

export default useAvailablePermissionsRouteConfig;
