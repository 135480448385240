import { useMemo, useRef } from "react";
import { StyledConnector } from "./ConnectorStyles";
import ConnectorView from "./ConnectorView";
import useCustomEffect from "../../../hooks/useCustomEffect";

function Connector({
  id,
  position = "bottom",
  onClick,
  Element = StyledConnector,
  setRefs,
  connections,
  selectedConnector,
  node,
}) {
  const active = selectedConnector === id;
  const connectorRef = useRef(null);

  /**
   * UseEffect setting the ref
   */
  useCustomEffect(() => {
    setRefs((prev) => ({
      ...prev,
      [id]: connectorRef.current,
    }));

    return () => {
      setRefs((prev) => {
        const newRefs = { ...prev };
        delete newRefs[id];
        return newRefs;
      });
    };
  }, [setRefs, id, connectorRef.current]);

  /**
   * Check if the connector is disabled
   */
  const disabled = useMemo(() => {
    //Never disable the active connector
    if (active) {
      return false;
    }

    if (selectedConnector) {
      //Block connections on the same node
      const selectedConnectorNode = selectedConnector.split("-[CONNECTOR]-")[0];
      if (selectedConnectorNode === node.id) {
        return true;
      }

      //Block any more incoming connections if the maxInConnections is reached
      const inConnections = connections.filter((connection) =>
        connection.toConnectorId.startsWith(node.id)
      );
      if (inConnections.length >= (node.maxInConnections ?? Infinity)) {
        return true;
      }

      //Block connections from-to the same node

      const sameConnections = inConnections.filter(
        (connection) =>
          connection.fromConnectorId.startsWith(selectedConnectorNode) &&
          connection.toConnectorId.startsWith(node.id)
      );
      if (sameConnections.length > 0) {
        return true;
      }
    }

    if (!selectedConnector) {
      //Block any more outgoing connections if the maxOutConnections is reached
      const outConnections = connections.filter((connection) =>
        connection.fromConnectorId.startsWith(node.id)
      );
      if (outConnections.length >= (node.maxOutConnections ?? Infinity)) {
        return true;
      }
    }

    return false;
  }, [
    connections,
    node.id,
    node.maxInConnections,
    node.maxOutConnections,
    selectedConnector,
    active,
  ]);

  return (
    <ConnectorView
      id={id}
      connectorRef={connectorRef}
      position={position}
      active={active}
      disabled={disabled}
      onClick={onClick}
      Element={Element}
    />
  );
}

export default Connector;
