import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputWrapper from "../../../components/input/wrapper/InputWrapper";
import { useLanguageContext } from "../../../context/LanguageContext";
import EVAApiHelper from "../../../helpers/EVAApiHelper";
import { StyledIconButton } from "../../../styles/ButtonStyles";
import { StyledInput } from "../../../styles/InputStyles";
import { StyledFlexColumn, StyledFlexItem, StyledFlexRow } from "../../../styles/LayoutStyles";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import Select from "../../../components/select/main/Select";

function OutlookFieldsView({ step, data, errors, onFieldChange, onCopyField, showCopyButtons }) {
  const { translate, translateEntity } = useLanguageContext();

  /**
   * This method will get the input component based on the field type
   */
  function getInputComponent(stepField) {
    const field = stepField.field;
    const fieldValue = data[`field_${field.id}`];

    switch (field.type) {
      case "text":
      case "date":
      case "time":
        return (
          <StyledFlexRow style={{ flexWrap: "nowrap", alignItems: "center" }}>
            <StyledFlexItem>
              <InputWrapper>
                <StyledInput
                  value={fieldValue ?? ""}
                  onChange={(e) => onFieldChange(stepField, e.target.value)}
                  type={field.type}
                  placeholder={translateEntity(field, "label")}
                />
              </InputWrapper>
            </StyledFlexItem>
            {showCopyButtons && (
              <StyledIconButton onClick={() => onCopyField(stepField, fieldValue)}>
                <FontAwesomeIcon icon={faCopy} />
              </StyledIconButton>
            )}
          </StyledFlexRow>
        );
      case "select":
      case "boolean":
        return (
          <StyledFlexRow>
            <StyledFlexItem>
              <Select
                value={fieldValue}
                nullable={true}
                onChange={(value) => onFieldChange(stepField, value)}
                options={
                  field.type === "boolean"
                    ? [
                        { value: "1", label: translate("eva.main.general.yes") },
                        { value: "0", label: translate("eva.main.general.no") },
                      ]
                    : field.options.map((option) => ({
                        value: option.id,
                        label: translateEntity(option, "label"),
                      }))
                }
              />
            </StyledFlexItem>
            {showCopyButtons && (
              <StyledIconButton onClick={() => onCopyField(stepField, fieldValue)}>
                <FontAwesomeIcon icon={faCopy} />
              </StyledIconButton>
            )}
          </StyledFlexRow>
        );
      default:
        return null;
    }
  }

  return (
    <StyledFlexColumn>
      {step?.fields?.map((row, index) => (
        <StyledFlexRow key={index}>
          {row.map((stepField) => (
            !stepField.hidden_outlook && (
              <StyledFlexItem key={stepField.id}>
                <InputWrapper
                  label={translateEntity(stepField.field, "label")}
                  required={stepField[`required_backoffice`]}
                  error={EVAApiHelper.getResponseError(
                    `field_${stepField.field.id}`,
                    errors
                  )}
                >
                  {getInputComponent(stepField)}
                </InputWrapper>
              </StyledFlexItem>
            )
          ))}
        </StyledFlexRow>
      ))}
    </StyledFlexColumn>
  );
}

export default OutlookFieldsView;