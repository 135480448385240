import { createContext, useContext, useState } from "react";
import useEvaApi, { EvaApiCall } from "../hooks/useEvaApi";
import { useAppContext } from "./AppContext";
import useCustomEffect from "../hooks/useCustomEffect";
import useContextDefiner, {
  ContextDefinition,
} from "../hooks/useContextDefiner";

const FrontofficeContext = createContext();

export function FrontofficeProvider({ children }) {
  const callEvaApi = useEvaApi();
  const { contexts } = useAppContext();
  const [pageData, setPageData] = useState({
    pageName: "start",
    pageParams: {},
  });
  const [terminalData, setTerminalData] = useState({
    terminal: null,
    terminalId: null,
    terminals: [],
  });

  /**
   * UseEffect for fetching the terminals when the user role_type changes
   */
  useCustomEffect(() => {
    fetchTerminals();
  }, [contexts.authContext?.auth?.user?.role_type]);

  /**
   * UseEffect for fetching the terminal when the terminalId changes
   */
  useCustomEffect(() => {
    fetchTerminal();
  }, [terminalData.terminalId]);

  /**
   * This method will fetch the terminals from the EVA API
   */
  async function fetchTerminals() {
    if (
      !contexts.authContext?.auth?.user ||
      contexts.authContext?.auth?.user?.role_type !== "terminal"
    ) {
      setTerminalData((prev) => ({
        ...prev,
        terminals: [],
        terminal: null,
        terminalId: null,
      }));
      return;
    }

    await callEvaApi(
      new EvaApiCall("terminals")
        .setLoadingGroup("APP")
        .setBlockAppRender(true)
        .setOnSuccess((response) => {
          const newTerminals = response.data.data.records;
          const newTerminalId = newTerminals.find(
            (t) =>
              parseInt(t.id) ===
              parseInt(localStorage.getItem("frontoffice_terminal_id"))
          )?.id;

          setTerminalData((prev) => ({
            ...prev,
            terminals: newTerminals,
            terminalId: newTerminalId,
          }));
        })
        .setAlertError(false)
        .setAlertSuccess(false)
    );
  }

  /**
   * This method will fetch the terminal with full data from the EVA API based on the terminalId
   */
  async function fetchTerminal() {
    if (!terminalData.terminalId) {
      setTerminalData((prev) => ({ ...prev, terminal: null }));
      return;
    }

    await callEvaApi(
      new EvaApiCall(`terminals/${terminalData.terminalId}`)
        .setLoadingGroup("APP")
        .setBlockAppRender(true)
        .setParams({
          with_terminal_buttons_data: true,
          with_terminal_visitor_types_data: true,
          with_terminal_screensavers_data: true,
          with_terminal_layout_data: true,
          with_layout_layout_data: true,
          with_layout_item_children_data: true,
          with_layout_item_image_data: true,
          with_button_layout_data: true,
          with_visitor_type_layout_data: true,
        })
        .setOnSuccess((response) => {
          setTerminalData((prev) => ({
            ...prev,
            terminal: response.data.data,
          }));
        })
        .setAlertError(false)
        .setAlertSuccess(false)
    );
  }

  /**
   * This method will update the terminal based on the terminalId
   * @param {int} terminalId
   */
  async function updateTerminal(terminalId) {
    localStorage.setItem("frontoffice_terminal_id", terminalId);
    setTerminalData((prev) => ({
      ...prev,
      terminalId: terminalId,
    }));
  }

  /**
   * This method will update the page
   * @param {string} name
   * @param {object} params
   */
  function updatePage(name, params = {}) {
    setPageData((prev) => ({
      ...prev,
      pageName: name,
      pageParams: params,
    }));
  }

  /**
   * This method will return the visual identifier for the visitor
   * @param {object} visitor
   * @returns {string}
   */
  function getVisitorIdentifierString(visitor, visitor_type = null) {
    const identifierFields =
      visitor_type?.identifier_fields ||
      visitor?.visitor_type?.identifier_fields ||
      [];

    const identifierString = identifierFields
      .map(
        (field) =>
          visitor.fields?.find((visField) => visField.field === field)?.value ||
          visitor[`field_${field}`]
      )
      .filter((value) => value !== undefined && value !== null)
      .join(" ");

    return identifierString;
  }

  /**
   * This method will filter the visitors based on the search value
   * @param {string} search
   * @param {object[]} visitors
   * @returns {object[]}
   */
  function filterVisitorsBySearch(search, visitors, exact = false) {
    return visitors.filter((visitor) => {
      const identifierString =
        getVisitorIdentifierString(visitor).toLowerCase();

      if (exact) {
        return identifierString === search.toLowerCase();
      } else {
        return identifierString.includes(search.toLowerCase());
      }
    });
  }

  return useContextDefiner(
    new ContextDefinition("frontofficeContext", FrontofficeContext)
      .setData({
        ...pageData,
        ...terminalData,
        updateTerminal,
        updatePage,
        getVisitorIdentifierString,
        filterVisitorsBySearch,
      })
      .setChildren(children)
  );
}

export function useFrontofficeContext() {
  return useContext(FrontofficeContext);
}
