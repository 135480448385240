import FlowFieldInputView from "./FlowFieldInputView";

function FlowFieldInput({ flowField, value, onChange }) {
  return (
    <FlowFieldInputView
      flowField={flowField}
      value={value}
      onChange={onChange}
    />
  );
}

export default FlowFieldInput;
