import { useState } from "react";
import useEvaApi, { EvaApiCall } from "../../../../../hooks/useEvaApi";
import ManualSignOutPageView from "./ManualSignoutPageView";
import useCustomEffect from "../../../../../hooks/useCustomEffect";
import { useFrontofficeContext } from "../../../../../context/FrontofficeContext";

function ManualSignOutPage() {
  const callEvaApi = useEvaApi();
  const { filterVisitorsBySearch, terminal } = useFrontofficeContext();
  const [visitors, setVisitors] = useState([]);
  const [search, setSearch] = useState("");

  const filteredVisitors = filterVisitorsBySearch(search, visitors);
  const selectedVisitor =
    filteredVisitors.length === 1 && search.length > 0
      ? filteredVisitors[0]
      : null;

  /**
   * UseEffect for automatically fetching the visitors
   */
  useCustomEffect(() => {
    fetchVisitors();
  });

  /**
   * This method will fetch the visitors from the API
   */
  async function fetchVisitors() {
    await callEvaApi(
      new EvaApiCall("visitors")
        .setLoadingGroup("sign_out_fetch")
        .setParams({
          with_visitor_fields_data: true,
          with_visitor_questions_data: true,
          with_visitor_houserules_data: true,
          with_visitor_visitor_type_data: true,
          where: [
            {
              column: "status",
              values: ["signed_in"],
            },
            {
              column: "visitor_type_id",
              values: [
                {
                  value: terminal?.visitor_types?.map((vt) => vt.id),
                  operator: "in",
                },
              ],
            },
          ],
        })
        .setOnSuccess((response) => {
          setVisitors(response.data.data.records);
        })
    );
  }

  /**
   * This method will handle the search change event
   * @param {event} e
   */
  function onSearchChange(e) {
    setSearch(e.target.value);
  }

  return (
    <ManualSignOutPageView
      search={search}
      selectedVisitor={selectedVisitor}
      onSearchChange={onSearchChange}
    />
  );
}

export default ManualSignOutPage;
