import styled from "styled-components";

export const StyledArrow = styled.path`
  pointer-events: auto;
  fill: ${(props) =>
    props.active
      ? props.theme.colors.accent.normal
      : props.theme.colors.on.primary};
  stroke: ${(props) =>
    props.active
      ? props.theme.colors.accent.normal
      : props.theme.colors.on.primary};
  stroke-width: 3;
  pointer-events: ${(props) => (props.onClick ? "auto" : "none")};

  &:hover {
    fill: ${(props) => props.theme.colors.accent.dark};
    stroke: ${(props) => props.theme.colors.accent.dark};
    cursor: pointer;
  }
`;

export const StyledArrowCenter = styled.div`
  position: absolute;
  pointer-events: none;
  transform: translate(-50%, -50%);
  background-color: ${(props) => props.theme.colors.primary.dark};
`;
